import React from 'react'
import Navbar from '../../common/Navbar'
import { Link } from 'react-router-dom'
export default function VerifyPlumber() {
    return (
        <>
            <Navbar />
            <section className="page-content--bge55">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="card-title">Verify Plumber</strong>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3 col-xs-6 text-right">
                                            <div className="form-group">
                                                <input className="form-control" type="text" placeholder="Search Plumber" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-md-offset-6 col-xs-6 text-right">
                                            <div className="form-group">
                                                <Link className="btn btn-primary btn-green" to="/add_plumber">
                                                    <i className="fa fa-plus"></i> Add New Plumber
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="table-responsive table--no-card">
                                                <table className="table table-borderless table-striped table-earning">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Mobile</th>
                                                            <th>Name</th>
                                                            <th>State</th>
                                                            <th>City</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>8979869876</td>
                                                            <td>test</td>
                                                            <td>UP</td>
                                                            <td>Meerut</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mtop15">
                                        <div className="col-md-2 col-xs-6 text-left">
                                            <div className="form-group">
                                                <Link className="btn btn-primary" to="#">
                                                    Previous
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-md-offset-8 col-xs-6 text-right">
                                            <div className="form-group">
                                                <Link className="btn btn-primary" to="#">
                                                    Next
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
