import React, { useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from "../../../common/Navbar";
import YouTube from "react-youtube";

import VideoDetails from "./VideoDetails";

export default function Videos() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getVideos(1);
  }, []);

  const searchFormik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getVideos(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getVideos = async (pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      // let stDate;
      // let endDate;

      // var momentObj1 = moment(data.startDate);
      // var momentObj2 = moment(data.endDate);
      // if (data.startDate && data.endDate) {
      //   var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
      //   var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
      //   stDate = `&startDate=${startFormattedDate}`;
      //   endDate = `&enddate=${startFormattedDate4}`;
      // } else {
      //   stDate = "";
      //   endDate = "";
      // }

      await axios
        .get(
          Constants.baseUrl +
            `program/vdo?sortAsc=true&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program2,
            },
          }
        )
        .then((res) => {
          //console.log(res);
          const dt = res.data;
          if (dt.result === "success") {
            setVideos(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setVideos(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const areAllValuesBlank = () => {
    const { startDate, endDate } = searchFormik.values;
    return !startDate && !endDate;
  };

  //console.log('videos videos', videos);

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="head2">Videos</h3>
                </div>
              </div>
              <div className="row">
                {videos === null ? (
                  <div className="col-md-12 col-xs-12">
                    <p>No Recored found</p>
                  </div>
                ) : (
                  <div>
                    {videos &&
                      videos.length > 0 &&
                      videos.map((row, index) => {
                        return (
                          <>
                            <div className="col-md-3 col-xs-12" key={index}>
                              {/* <VideoDetails videoUrl={row?.url} /> */}
                              <div className="inner">
                                <YouTube
                                  videoId={row?.url.split("v=")[1]}
                                  opts={{
                                    width: "100%",
                                  }}
                                />
                                <h4 className="card-title mb-3 video-title">
                                  {row?.name}
                                </h4>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  {totalPages > 1 && (
                    <ResponsivePagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={(page) => {
                        setCurrentPage(page);
                        getVideos(page);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
