import React, { useState } from 'react'
import Navbar from '../../common/Navbar'
import { Link } from 'react-router-dom';
export default function PlumberReports() {
    const [activeTab, setActiveTab] = useState('1a');

    return (
        <>
            <Navbar />
            <section class="page-content--bge55">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tabbable-panel margin-tops4 ">
                                <div class="tabbable-line bggg33">
                                    <ul class="nav nav-tabs tabtop  tabsetting">
                                        <li className={activeTab === '1a' ? 'active' : ''}> <Link to="" onClick={() => setActiveTab('1a')} data-toggle="tab" aria-expanded={activeTab === '1a'}> Verified Plumbers </Link> </li>
                                        <li className={activeTab === '2a' ? 'active' : ''}> <Link to="" onClick={() => setActiveTab('2a')} data-toggle="tab" aria-expanded={activeTab === '2a'}> Non-Verified Plumbers</Link> </li>
                                    </ul>
                                    <div class="card card-body mtop15">
                                        <div class="tab-content margin-tops">
                                            <div className={`tab-pane ${activeTab === '1a' ? 'active' : ''}`} id="1a">
                                                <div class="row">
                                                    <div class="col-md-3 col-xs-6">
                                                        <div class="form-group">
                                                            <input class="form-control" type="text" placeholder="Search Plumbers" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-xs-12">
                                                        <div class="form-group">
                                                            <button type="button" class="btn btn-primary btn-sbmit" id="btnSearch">Search</button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-md-offset-3 col-xs-6 text-right">
                                                        <div class="form-group">
                                                            <a class="btn btn-primary" href="#">
                                                                Export
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive table--no-card">
                                                            <table class="table table-borderless table-striped table-earning">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Retailer DMS Id</th>
                                                                        <th>Retailer Name</th>
                                                                        <th>Active/Inactive</th>
                                                                        <th>Retailer Mobile No</th>
                                                                        <th>City</th>
                                                                        <th>State</th>
                                                                        <th>District</th>
                                                                        <th>Plumber Name</th>
                                                                        <th>Plumber Mobile No </th>
                                                                        <th>Verified FS </th>
                                                                        <th>KYC Status (Yes/No) </th>
                                                                        <th>Source </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>11</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mtop15">
                                                    <div class="col-md-2 col-xs-6 text-left">
                                                        <div class="form-group">
                                                            <a class="btn btn-primary" href="#">
                                                                Previous
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-md-offset-8 col-xs-6 text-right">
                                                        <div class="form-group">
                                                            <a class="btn btn-primary" href="#">
                                                                Next
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`tab-pane ${activeTab === '2a' ? 'active' : ''}`} id="2a">
                                                <div class="row">
                                                    <div class="col-md-3 col-xs-6 text-right">
                                                        <div class="form-group">
                                                            <input class="form-control" type="text" placeholder="Search Plumbers" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-xs-12">
                                                        <div class="form-group">
                                                            <button type="button" class="btn btn-primary btn-sbmit" id="btnSearch">Search</button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-md-offset-3 col-xs-6 text-right">
                                                        <div class="form-group">
                                                            <a class="btn btn-primary" href="#">
                                                                Export
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive table--no-card">
                                                            <table class="table table-borderless table-striped table-earning">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Retailer DMS Id</th>
                                                                        <th>Retailer Name</th>
                                                                        <th>Active/Inactive</th>
                                                                        <th>Retailer Mobile No</th>
                                                                        <th>City</th>
                                                                        <th>State</th>
                                                                        <th>District</th>
                                                                        <th>Plumber Name</th>
                                                                        <th> Plumber Mobile No </th>
                                                                        <th> Verified FS </th>
                                                                        <th> KYC Status (Yes/No) </th>
                                                                        <th> Source </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>11</td>
                                                                        <td>test</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mtop15">
                                                    <div class="col-md-2 col-xs-6 text-left">
                                                        <div class="form-group">
                                                            <a class="btn btn-primary" href="#">
                                                                Previous
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-md-offset-8 col-xs-6 text-right">
                                                        <div class="form-group">
                                                            <a class="btn btn-primary" href="#">
                                                                Next
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </section >
        </>
    )
}
