import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';

export default function English() {
    return (
        <>
            <section className="page-content--bge55">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card" style={{ padding: '14px' }}>
                                <div className="faq-content">
                                    <Accordion activeIndex={0}>
                                        <AccordionTab header="1. What is the “Sintex Pride” Plumber loyalty program">
                                            <p className="m-0 faq-ans">
                                                <span></span>The <strong>“Sintex Pride” Plumber loyalty program</strong> is a rewards system designed to show appreciation to our loyal Plumbers/ Plumbing Contractors who consistently choose Water storage Tanks. It offers various benefits and incentives to Plumber/Plumbing Contractors who frequently use Water Storage Tanks.
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="2. How can I enroll in the “Sintex Pride” Plumber loyalty program">
                                            <p className="m-0 faq-ans">
                                                <span></span>Enrolling in the<strong> “Sintex Pride” Plumber loyalty program</strong> is easy. You can sign up through self-signup in “Sintex Pride” App by providing your information or you can contact the Sintex officer to enroll in the program. Aadhaar Card is mandatory for registration in the program
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="3. When will my “Sintex Pride” Plumber loyalty program account get activated">
                                            <p className="m-0 faq-ans">
                                                <span></span>Your <strong>“Sintex Pride” Plumber loyalty program</strong> account gets activated on the day you are verified by Sintex officer into the program. You will receive a confirmation SMS on your registered mobile number. In case you are unable to login to your account please contact the program help desk at 1800 121 2764 or mail ID
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="4. What are the benefits of the “Sintex Pride” Plumber loyalty program?">
                                            <p className="m-0 faq-ans">
                                                <span></span><strong>“Sintex Pride” </strong>program will not only enhance our bond with the Plumber/Plumbing Contractors but also reward them for their contribution and hard work. This is an opportunity for the members to earn more and redeem more on their eligible Sintex Water Storage Tanks purchase.
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="5. How do I earn loyalty points or rewards">
                                            <p className="m-0 faq-ans">
                                                <span></span>It would be automatically reported once you scan the QR code on the Water Storage tank, with your mobile App. After successful scanning points get credited in your <strong>“Sintex Pride”</strong> Loyalty account as per eligibility.
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="6. What if the QR Code is damaged?">
                                            <p className="m-0 faq-ans">
                                                <span></span>You can call Customer Care no. 1800 121 2764 and share the Index number provided below the QR Code or you can raise complaint from the Sintex Pride app
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="7. How do I redeem my loyalty points or rewards?">
                                            <p className="m-0 faq-ans">
                                                <span></span>You can redeem your loyalty points by logging in to the App and clicking “Rewards catalogue” section or by calling the program help desk at 1800 1212764 from your registered mobile number. They will assist you in redeeming your points
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="7. Are there any fees to join the “Sintex Pride” Plumber loyalty program">
                                            <p className="m-0 faq-ans">
                                                <span></span>No, there are no enrollment fees or annual fees associated with our loyalty program. It's completely free for our valued Plumbers/Plumbing Contractors
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="8. Can I transfer my loyalty points to someone else?">
                                            <p className="m-0 faq-ans">
                                                <span></span>Loyalty points are non-transferable. They are intended for the account holder's personal use only
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="9. Do my loyalty points expire?">
                                            <p className="m-0 faq-ans">
                                                <span></span>The Loyalty points will expire in 1  Years from date of credit in your loyalty account. If points credit in Plumber “Sintex Pride” account on 1st April-23, then his points will remain valid till 31st March 2024
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="10. How can I check my loyalty points balance?">
                                            <p className="m-0 faq-ans">
                                                <span></span>You can check your loyalty points balance by logging into your <strong>“Sintex Pride”</strong> App or by contacting our customer service team on 1800 121 2764. They will provide you with your current point balance
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="11. What happens if I have a dispute or issue with my loyalty points or rewards?">
                                            <p className="m-0 faq-ans">
                                                <span></span>If you encounter any issues or disputes regarding your loyalty points or rewards, please contact our customer care team on 1800 121 2764. They will investigate and help resolve any problems
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="12. How do I leave the “Sintex Pride” Plumber loyalty program if I no longer wish to participate">
                                            <p className="m-0 faq-ans">
                                                <span></span>If you wish to leave the loyalty program, you can contact our customer care no. 1800 121 2764 to have your account deactivated. Keep in mind that you may lose any accumulated loyalty points or benefits upon leaving the program
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="13.Which products of “Sintex” are eligible to participate in the program?">
                                            <p className="m-0 faq-ans">
                                                <span></span>This program is applicable for all variants of Sintex TruPUF, Sintex Tatva, Sintex Pure, Sintex Ace, Sintex DW, Sintex Titus, Sintex Hero, Sintex Reno, Sintex Neo, Sintex Loft, Sintex ISI, Sintex Sump (UGWT).
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="14. How is my earning calculated?">
                                            <p className="m-0 faq-ans">
                                                <span></span>Earnings depends on: -<br />
                                                (a) Capacity of the tank and which group the product lies in,<br />
                                                (b) Number of tanks and Total Volume of tanks purchased in the month, and<br />
                                                (c) Number of tanks and Total Volume of tanks purchased in the calendar quarter.<br />
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="15. What are eligible products?">

                                            <table>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Products</th>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Sintex TruPUF</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Sintex Tatva</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Sintex Pure</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Sintex Ace</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Sintex DW</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Sintex Titus</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>Sintex UGWT</td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>Sintex ISI</td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>Sintex Hero</td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>Sintex Reno</td>
                                                </tr>
                                                <tr>
                                                    <td>11</td>
                                                    <td>Sintex Neo</td>
                                                </tr>
                                                <tr>
                                                    <td>12</td>
                                                    <td>Sintex Loft</td>
                                                </tr>



                                            </table>
                                        </AccordionTab>
                                        <AccordionTab header="16. How are my incentives calculated for Group 1, Group 2 & Total Litres">
                                            <p className="m-0 faq-ans">
                                                <span></span>Flat Scheme is calculated on group wise volume. The quantity scheme is calculated on the number of tanks and total volume. E.g.: 5000 Litres of Pure, 5000 Litres of Titus, 5000 Litres of Reno: Total 15K Litres,<br /><br />
                                                Flat Scheme: Group 1: Pure + Titus = 10,000 Litres * 20p = 2,000 points<br /><br />
                                                Group 2: Reno = 5,000 Litres * 10p = 500 points<br /><br />
                                                Quantity Purchase Group 1 + Group 2: 15,000 Litres, As well as 3no. Tanks * 10% Bonus on Flat Scheme = 250 Points<br /><br />
                                                Total points Earned = 2,000+500+250 = 2,750 points<br /><br />
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="17. Can my points be restored if I am not able to utilize the redeemed voucher?">
                                            <p className="m-0 faq-ans">
                                                <span></span>No, once points are redeemed, the same cannot be reversed in your <strong>“Sintex Pride”</strong> account
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="18. What are the minimum points required for redemption?">
                                            <p className="m-0 faq-ans">
                                                <span></span>A minimum balance of 500 points is required to do the first redemption
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="19. Can I redeem all the points at once?">
                                            <p className="m-0 faq-ans">
                                                <span></span>You can redeem up to 80% of total balance Points
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="20. Whom should I contact if I have any program-related queries?">
                                            <p className="m-0 faq-ans">
                                                <span></span>In case of any program-related queries or feedback, you can call the<strong> “Sintex Pride”</strong> Plumber Help desk number 1800 121 2746 from your registered mobile number or contact your nearest Distributor/Retailer & Sintex Officer.
                                            </p>
                                        </AccordionTab>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
