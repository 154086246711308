import React from 'react'
import Navbar from '../../../common/Navbar'
import sintexHamesha from '../../../../assets/img/sintex-hamesha.jpg'
export default function SintexInfluencer() {
    return (
        <>
            <Navbar />
            <section class="">
                <div class="container-fluid">
                    <div class="inner-sec">
                        <div class="row">
                            <div class="col-md-12">
                                <figure class="abbnt1">
                                    <img src={sintexHamesha} alt="" />
                                </figure>
                            </div>
                            <div class="col-md-12">
                                <p class="bdd text-center">Sintex Plumber</p>
                            </div>
                            <div class="col-md-12">
                                <p class="fnt16">
                                    <strong>Sintex Hamesha plumber Loyalty Program: -</strong><br /><br />
                                    To reinforce the brand supremacy in the domestic market and consolidate its leadership position, the company has introduced the Sintex Hamesha plumber Loyalty Program for its Plumbers/ Plumbing Contractors who purchase Sintex Water Storage Tanks through our authorized Distributors/Retailers. As a Sintex Hamesha plumber, you are entitled to loyalty points and rewards on all eligible product purchases only from authorized distributors/retailers.
                                    The loyalty points will be auto credited to your Sintex Hamesha Loyalty account only when you successfully scanned QR Code which paste on Water Tanks.<br /><br />

                                    <strong>The flow of the Program: -</strong><br />
                                    Becoming Sintex Hamesha plumber Loyalty Program is super easy and super rewarding.<br />
                                    Join Sintex Hamesha plumber Loyalty Program by simply downloading the app from the Google Play Store, registering yourself, scanning the products, and earning points & benefits.<br /><br />

                                    There are 4 stages:<br /><br />
                                    · Register & Login<br />
                                    · Scan QR Code<br />
                                    · Earn Points<br />
                                    · Redeem Points<br /><br />

                                    <strong>1. Register & Login:</strong><br />
                                    Download the Sintex Hamesha plumber Loyalty App from the Google Play Store and fill the basic details. Fill out your Aadhaar number and upload the front and back images of the Aadhaar card and it will be verified. Fill in your PAN card number, GST Number (In case of the Plumbing Contractor) and upload the images of the same. Post approval of your enrollment by the Sintex Sales team, then you will receive login details via SMS. Now you can start scanning the QR code and earn points and redeem them against exciting rewards.<br /><br />

                                    <strong>2. Scan QR Code:</strong><br />
                                    Once successfully logged in to the Sintex Hamesha plumber App, go to the ‘Scan QR code’ section in the app and scan the eligible QR code pasted on the product purchased from Sintex authorized Distributors/Retailers.
                                    You can also check the scanning history on the same page by clicking the 'Scan History' option.<br /><br />

                                    <strong>3. Earn Points:</strong><br />
                                    After every successful scanning, you need to select from where you have purchased the product (Retailer/ Distributor Name), and after the selection, your point is credited to your Sintex Hamesha account. There will be different points for different products.<br /><br />

                                    <strong>4. Redeem Points:</strong><br />
                                    Paytm Wallet Transfer: Cash can be transferred to your Aadhaar linked mobile number (*subject to Paytm KYC which is your responsibility).<br />
                                    UPI Transfer: Cash can be transferred to your valid UPI account.<br />
                                    Bank Transfer: Cash can get directly transferred into your bank account basis the correct bank account details provided along with a canceled cheque/ passbook copy.<br />
                                    E-Vouchers: You can also redeem points via E-vouchers. A successful E-voucher will be delivered to you in your app and also sent to your registered mobile number via SMS which will be visible only after submission of the OTP.<br /><br />

                                    <strong>5. Redemption:</strong><br />
                                    History is available on the app; where you can see your past redemptions and track the current redemption status.<br /><br />

                                    <strong>6. A minimum of 500 Points is a must for the first redemption.</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
