import React, { useState, useEffect } from "react";
import Navbar from "../../../common/Navbar";
import { useFormik } from "formik";
import axios from "axios";
import * as Constants from "../../../../Constants/index";
import swal from "sweetalert";
import OtpInput from "react-otp-input";
import Modal from "react-modal";
import Oval from "../../loader/CircleLoade";

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
export default function PlumberEnrollment() {
  const [loading, setLoading] = useState(false);
  const [stateFetch, setStateFetch] = useState([]);
  const [districtFetch, setDistrictFetch] = useState([]);
  const [cityFetch, setCityFetch] = useState([]);
  const [otp, setOtp] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [aadharClientId, setAadharClientId] = useState("");
  const [verifyAadharOtpStatus, setVerifyAadharOtpStatus] = useState("error");
  const [verifyPanStatus, setVerifyPanStatus] = useState("error");
  const [verifyGstStatus, setVerifyGstStatus] = useState("error");
  const [errors, setErrors] = useState({});
  const [timer, setTimer] = useState(0);

  const enrollFormik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      mobile: "",
      email: "",
      address1: "",
      address2: "",
      address3: "",
      state: "",
      stateId: "",
      district: "",
      districtId: "",
      city: "",
      cityId: "",
      pincode: "",
      profile: "plumber",
      mobileAadhar: "",
      aadhar: "",
      aadharFront: "",
      aadharBack: "",
      pan: "",
      panImg: "",
      gst: "",
      gstImg: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.firstName) {
        errors.firstName = "First name required";
      }
      // else if (data.firstName.length < 3) {
      //     errors.firstName = 'Name must be at least 3 characters';
      // }
      if (!data.lastName) {
        errors.lastName = "Last name required";
      }
      // else if (data.lastName.length < 3) {
      //     errors.lastName = 'Last name must be at least 3 characters';
      // }
      if (!data.mobile) {
        errors.mobile = "Mobile no. is required";
      } else if (data.mobile.length !== 10) {
        errors.mobile = "Mobile no. must be 10 digits";
      }
      if (data.email && !/\S+@\S+\.\S+/.test(data.email)) {
        errors.email = "Invalid email address";
      }
      if (!data.address1) {
        errors.address1 = "Address required";
      }
      if (!data.state) {
        errors.state = "State required";
      }
      if (!data.district) {
        errors.district = "District required";
      }
      if (!data.city) {
        errors.city = "City required";
      }
      if (!data.pincode) {
        errors.pincode = "Pin code required";
      } else if (data.pincode.length !== 6) {
        errors.pincode = "Pincode no. must be 6 digits";
      }
      if (!data.mobileAadhar) {
        errors.mobileAadhar = "Mobile no. is required";
      } else if (data.mobileAadhar.length !== 10) {
        errors.mobileAadhar = "Mobile no. must be 10 digits";
      }
      if (!data.aadhar) {
        errors.aadhar = "Aadhar no. is required";
      } else if (data.aadhar.length !== 12) {
        errors.aadhar = "Aadhar no. must be 12 digits";
      }
      if (!data.aadharFront) {
        errors.aadharFront = "Please select image";
      }
      if (!data.aadharBack) {
        errors.aadharBack = "Please select image";
      }
      if (!data.pan) {
        errors.pan = "Pan no. is required";
      } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(data.pan)) {
        errors.pan = "Invalid PAN format";
      }
      if (!data.panImg) {
        errors.panImg = "Please select image";
      }
      if (data.profile === "contractor") {
        if (!data.gst) {
          errors.gst = "GST no. is required";
        } else if (
          !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1}$/i.test(
            data.gst
          )
        ) {
          errors.gst = "Invalid GST format";
        }
        if (!data.gstImg) {
          errors.gstImg = "Please select image";
        }
      }
      return errors;
    },
    onSubmit: (data) => {
      // console.log('registration', data)
      if (verifyAadharOtpStatus === "error") {
        swal({
          text: "Please verify aadhar",
          icon: "error",
          timer: 2000,
          buttons: false,
        });
        return;
      }
      if (verifyPanStatus === "error") {
        swal({
          text: "Please verify pan no.",
          icon: "error",
          timer: 1000,
          buttons: false,
        });
        return;
      }
      if (data.profile === "Plumbing Contractor") {
        if (verifyGstStatus === "error") {
          swal({
            text: "Please verify gst no.",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
          return;
        }
      }
      enrollmentRequest(data);
    },
  });
  const isEnrollFormFieldValid = (name) =>
    !!(enrollFormik.touched[name] && enrollFormik.errors[name]);
  const getEnrollFormErrorMessage = (name) => {
    return (
      isEnrollFormFieldValid(name) && (
        <small className="p-error">{enrollFormik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    // call state api for get state name

    let token = localStorage.getItem("token");

    try {
      axios
        .get(`${Constants.baseUrl}master/geography/state?limit=1000`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 201 && res.data.code === 10001) {
            setStateFetch(res.data.output.results);
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // call state api by state ID for get district name
  const handleStateChange = async (event) => {
    const selectedId = event.target.value;
    setDistrictFetch([]);
    setCityFetch([]);
    enrollFormik.setFieldValue("district", "");
    enrollFormik.setFieldValue("city", "");
    enrollFormik.setFieldValue("pincode", "");
    if (selectedId === "0") {
      enrollFormik.setFieldValue("state", "");
      enrollFormik.setFieldValue("district", "");
      enrollFormik.setFieldValue("city", "");
    } else {
      districtData(selectedId);
    }
  };

  let token = localStorage.getItem("token");
  const districtData = async (stateid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/state/${stateid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              enrollFormik.setFieldValue("state", res.data.output.name);
              enrollFormik.setFieldValue("stateId", res.data.output.id);
              setDistrictFetch(res.data.output.districts);
              console.log("state", enrollFormik.values.state);
            } else {
              // swal({
              //     text: res.data.msg,
              //     timer: 2000,
              //     buttons: false,
              // });
            }
          } else {
            // swal({
            //     text: res.data.msg,
            //     timer: 2000,
            //     buttons: false,
            // });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // call district api by district id for get city name
  const handleDistrictChange = async (event) => {
    const selectedId = event.target.value;
    setCityFetch([]);
    enrollFormik.setFieldValue("city", "");
    enrollFormik.setFieldValue("pincode", "");
    if (selectedId === "0") {
      enrollFormik.setFieldValue("district", "");
      enrollFormik.setFieldValue("city", "");
    } else {
      cityData(selectedId);
    }
  };

  const cityData = async (cityid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/district/${cityid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              enrollFormik.setFieldValue("district", res.data.output.name);
              enrollFormik.setFieldValue("districtId", res.data.output.id);
              setCityFetch(res.data.output.cities);
            } else {
              // swal({
              //     text: res.data.msg,
              //     timer: 2000,
              //     buttons: false,
              // });
            }
          } else {
            // swal({
            //     text: res.data.msg,
            //     timer: 2000,
            //     buttons: false,
            // });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAadharFront = (event) => {
    updateAadharFront(event.target.files[0]);
  };
  const handleAadharBack = (event) => {
    updateAadharBack(event.target.files[0]);
  };
  const handlePanImg = (event) => {
    updatePanImg(event.target.files[0]);
  };
  const handleGstImg = (event) => {
    updateGstImg(event.target.files[0]);
  };
  const updateAadharFront = (file) => {
    let token = localStorage.getItem("token");
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "aadharFront");
      axios
        .post(Constants.baseUrl + "uploader/upload", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            enrollFormik.setFieldValue("aadharFront", res.data.output.url);
          } else {
            // swal('Something went wrong!');
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const updateAadharBack = (file) => {
    let token = localStorage.getItem("token");
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "aadharFront");
      axios
        .post(Constants.baseUrl + "uploader/upload", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            enrollFormik.setFieldValue("aadharBack", res.data.output.url);
          } else {
            // swal('Something went wrong!');
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const updatePanImg = (file) => {
    let token = localStorage.getItem("token");
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "aadharFront");
      axios
        .post(Constants.baseUrl + "uploader/upload", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            enrollFormik.setFieldValue("panImg", res.data.output.url);
          } else {
            // swal('Something went wrong!');
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const updateGstImg = (file) => {
    let token = localStorage.getItem("token");
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "aadharFront");
      axios
        .post(Constants.baseUrl + "uploader/upload", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            enrollFormik.setFieldValue("gstImg", res.data.output.url);
          } else {
            // swal('Something went wrong!');
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // call aadhar verify api
  const verifyAadhar = async (aadhar) => {
    if (enrollFormik.values.mobileAadhar === "") {
      swal({
        text: "Please enter Mobile No. Linked with Aadhar",
        // icon: 'success',
        timer: 1000,
        buttons: false,
      });
      return;
    }
    if (enrollFormik.values.aadhar === "") {
      swal({
        text: "Please enter aadhar no.",
        // icon: 'success',
        timer: 2000,
        buttons: false,
      });
      return;
    }
    setLoading(true);
    try {
      await axios
        .post(
          Constants.baseUrl + "verify/aadharGenOtp",
          {
            aadhar: aadhar,
            mobile: enrollFormik.values.mobileAadhar,
          },
          {
            headers: {
              appVersion: Constants.av,
              platform: Constants.pt,
              "Accept-Language": localStorage.getItem("langauge"),
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setOtp("");
              setTimer(120); // Reset the timer to 120 seconds
              setLoading(false);
              setAadharClientId(res.data.output.data.client_id);
              swal({
                text: res.data.msg,
                // icon: 'success',
                timer: 1000,
                buttons: false,
              });
              setTimeout(() => {
                setModalIsOpen(true);
                const interval = setInterval(() => {
                  setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
                }, 1000);

                // Clear the interval when the timer reaches 0
                return () => clearInterval(interval);
              }, 1000);
            } else if (res.data.code === 12002) {
              setLoading(false);
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            } else {
              setLoading(false);
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            setLoading(false);
            swal({
              text: res.data.msg,
              icon: "error",
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // otp validation
  const validateMobileOtp = () => {
    let errors = {};

    if (!otp) {
      errors.otp = "Please Enter OTP";
    } else if (otp.length !== 6) {
      errors.otp = "OTP must be 6 digit";
    }
    return errors;
  };

  // call aadhar verify otp api
  const verifyAadharOtp = async () => {
    setErrors({});

    const validationErrors = validateMobileOtp();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }
    setLoading(true);
    try {
      await axios
        .post(
          Constants.baseUrl + "verify/aadharSubOtp",
          {
            clientId: aadharClientId,
            otp: otp,
            mobile: enrollFormik.values.mobile,
          },
          {
            headers: {
              appVersion: Constants.av,
              platform: Constants.pt,
              "Accept-Language": localStorage.getItem("langauge"),
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setVerifyAadharOtpStatus(res.data.result);
              setModalIsOpen(false);
              setLoading(false);
              swal({
                text: res.data.msg,
                icon: "success",
                timer: 2000,
                buttons: false,
              });
            } else if (res.data.code === 12002) {
              setLoading(false);
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              icon: "error",
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // call pan verify api
  const verifyPan = async (panNumber) => {
    if (enrollFormik.values.pan === "") {
      swal({
        text: "Please enter pan no.",
        // icon: 'success',
        timer: 1000,
        buttons: false,
      });
      return;
    }
    setLoading(true);
    try {
      await axios
        .post(
          Constants.baseUrl + "verify/pan",
          {
            pan: panNumber,
          },
          {
            headers: {
              appVersion: Constants.av,
              platform: Constants.pt,
              "Accept-Language": localStorage.getItem("langauge"),
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setVerifyPanStatus(res.data.result);
              setLoading(false);
              swal({
                text: res.data.msg,
                icon: "success",
                timer: 2000,
                buttons: false,
              });
            } else if (res.data.code === 12002) {
              setLoading(false);
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            } else {
              setLoading(false);
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // call gst verify api
  const verifyGst = async (gstNumber) => {
    if (enrollFormik.values.pan === "") {
      swal({
        text: "Please enter gst no.",
        // icon: 'success',
        timer: 1000,
        buttons: false,
      });
      return;
    }
    setLoading(true);
    try {
      await axios
        .post(
          Constants.baseUrl + "verify/gstIn",
          {
            gstNumber: gstNumber,
            filing_status_get: true,
          },
          {
            headers: {
              appVersion: Constants.av,
              platform: Constants.pt,
              "Accept-Language": localStorage.getItem("langauge"),
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setVerifyGstStatus(res.data.result);
              setLoading(false);
              swal({
                text: res.data.msg,
                icon: "success",
                timer: 2000,
                buttons: false,
              });
            } else if (res.data.code === 12002) {
              setLoading(false);
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            } else {
              setLoading(false);
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  //call register api for plumber registration
  const enrollmentRequest = async (data) => {
    let token = localStorage.getItem("token");
    const postData = {
      name: data.firstName,
      midleName: data.middleName,
      lastName: data.lastName,
      gst: data.gst,
      gstImag: data.gstImg,
      email: data.email,
      mobile: data.mobile,
      password: "",
      status: "APPROVED",
      userModelKYC: {
        aadharMobile: data.mobileAadhar,
        aadharNumber: data.aadhar,
        aadharFrontUrl: data.aadharFront,
        aadharBackUrl: data.aadharBack,
        aadharVerify: "",
        aadharVerifiedBy: "",
        aadharVerifiedOn: "",
        panNumber: data.pan,
        panFrontUrl: data.panImg,
        panVerify: "",
        panVerifiedBy: "",
        panVerifiedOn: "",
        tanNumber: "",
        sinNumber: "",
      },
      addressWork: {
        workAddress1: data.address1,
        workAddress2: data.address2,
        workAddress3: data.address3,
        workCity: data.city,
        workCityId: data.cityId,
        workState: data.state,
        workStateId: data.stateId,
        workdistrict: data.district,
        workdistrictId: data.districtId,
        workPincode: data.pincode,
        workCountry: "",
        workArea: "",
      },
      bankDetail: {
        bankAccountNo: "",
        accountType: "",
        ifsc: "",
        beneficiary: "",
        bankName: "",
        holderName: "",
        branchName: "",
        cancelChk: "",
        upiId: "",
        upiIdScreen: "",
      },
      parentId: 0,
      parentType: "",
      userType: "MEMBER",
      identityType: "",
      identityNumber: "",
      identityCardBackUrl: "",
      identityCardFrontUrl: "",
      getsStateId: "",
      getsDistrictId: "",
      getsCityId: "",
      plumberProfile: data.profile,
    };

    // console.log('postData', postData)

    try {
      await axios
        .post(Constants.baseUrl + "user/manage", postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              swal({
                text: "Registration Successfull",
                // text: "Please Enter Otp Sent on Plumber's Mobile",
                icon: "success",
                timer: 2000,
                buttons: false,
              });
              setTimeout(() => {
                setVerifyAadharOtpStatus("error");
                setVerifyPanStatus("error");
                setVerifyGstStatus("error");
                enrollFormik.resetForm();
              }, 2000);
            } else if (res.data.code === 12001) {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleOtpChange = (otpValue) => {
    // Remove non-numeric characters from the entered OTP
    const numericOtp = otpValue.replace(/\D/g, "");

    setOtp(numericOtp);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setOtp("");
  };
  const handleKeyDown = (e) => {
    // Allow only numeric values (0-9)
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3 className="head1">Plumber Enrollment</h3>
            </div>
          </div>
          <div className="card">
            <form onSubmit={enrollFormik.handleSubmit}>
              <div className="card-body">
                {/* <div className="alert alert-info">
                                    <strong>Registration for Sintex Influencer Program was successfull </strong>
                                </div> */}
                <div className="row mt-20 enrollmentform">
                  <div className="col-md-12">
                    <h4 className="headdetails">
                      <strong className="card-title">Basic Details</strong>
                    </h4>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group ">
                      <label>First Name*</label>
                      <input
                        className="form-control"
                        placeholder="Enter First Name"
                        maxLength="32"
                        type="text"
                        id="firstName"
                        value={enrollFormik.values.firstName}
                        onChange={enrollFormik.handleChange}
                      />
                      {getEnrollFormErrorMessage("firstName")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>Middle Name</label>
                      <input
                        className="form-control"
                        placeholder="Enter Middle Name"
                        maxLength="32"
                        type="text"
                        id="middleName"
                        value={enrollFormik.values.middleName}
                        onChange={enrollFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>Last Name*</label>
                      <input
                        className="form-control"
                        placeholder="Enter Last Name"
                        maxLength="32"
                        type="text"
                        id="lastName"
                        value={enrollFormik.values.lastName}
                        onChange={enrollFormik.handleChange}
                      />
                      {getEnrollFormErrorMessage("lastName")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>Mobile Number*</label>
                      <input
                        className="form-control"
                        placeholder="Enter Mobile Number*"
                        maxLength="10"
                        type="text"
                        id="mobile"
                        value={enrollFormik.values.mobile}
                        onChange={enrollFormik.handleChange}
                        onKeyPress={(e) => {
                          const isNumeric = /^[0-9]*$/;
                          if (!isNumeric.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {getEnrollFormErrorMessage("mobile")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>Email ID</label>
                      <input
                        className="form-control"
                        placeholder="Enter Email ID"
                        type="text"
                        id="email"
                        value={enrollFormik.values.email}
                        onChange={enrollFormik.handleChange}
                      />
                      {getEnrollFormErrorMessage("email")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>Address 1*</label>
                      <input
                        className="form-control"
                        placeholder="Enter Address 1*"
                        type="text"
                        id="address1"
                        value={enrollFormik.values.address1}
                        onChange={enrollFormik.handleChange}
                      />
                      {getEnrollFormErrorMessage("address1")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>Address 2</label>
                      <input
                        className="form-control"
                        placeholder="Enter Address 2"
                        type="text"
                        id="address2"
                        value={enrollFormik.values.address2}
                        onChange={enrollFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>Address 3</label>
                      <input
                        className="form-control"
                        placeholder="Enter Address 3"
                        type="text"
                        id="address3"
                        value={enrollFormik.values.address3}
                        onChange={enrollFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>State*</label>
                      <select
                        className="form-control"
                        id="state"
                        onChange={handleStateChange}
                      >
                        <option value="0">Select State</option>
                        {stateFetch.map((item, index) => {
                          return (
                            <>
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {getEnrollFormErrorMessage("state")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>District*</label>
                      <select
                        className="form-control"
                        id="district"
                        onChange={handleDistrictChange}
                      >
                        <option value="0">Select District</option>
                        {districtFetch.map((item, index) => {
                          return (
                            <>
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {getEnrollFormErrorMessage("district")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>City*</label>
                      <select
                        className="form-control"
                        value={enrollFormik.values.city}
                        id="city"
                        onChange={(event) => {
                          enrollFormik.setFieldValue(
                            "city",
                            event.target.value
                          );
                          const selectedCity = cityFetch.find(
                            (item) => item.name === event.target.value
                          );
                          enrollFormik.setFieldValue(
                            "cityId",
                            selectedCity ? selectedCity.id : ""
                          );
                        }}
                      >
                        <option value="">Select City</option>
                        {cityFetch.map((item, index) => {
                          return (
                            <>
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {getEnrollFormErrorMessage("city")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>Pincode*</label>
                      <input
                        className="form-control"
                        placeholder="Pincode*"
                        maxLength="6"
                        id="pincode"
                        value={enrollFormik.values.pincode}
                        onChange={enrollFormik.handleChange}
                        onKeyPress={(e) => {
                          const isNumeric = /^[0-9]*$/;
                          if (!isNumeric.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {getEnrollFormErrorMessage("pincode")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>Please choose Your Profile</label>
                      <div className="profile">
                        <label className="radio-inline">
                          <input
                            type="radio"
                            id="profile"
                            checked={enrollFormik.values.profile === "plumber"}
                            onChange={() =>
                              enrollFormik.setFieldValue("profile", "plumber")
                            }
                          />{" "}
                          Plumber
                        </label>
                        <label className="radio-inline">
                          <input
                            type="radio"
                            id="profile"
                            checked={
                              enrollFormik.values.profile === "contractor"
                            }
                            onChange={() =>
                              enrollFormik.setFieldValue(
                                "profile",
                                "contractor"
                              )
                            }
                          />{" "}
                          Plumbing Contractor
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h4 className="headdetails">
                      <strong className="card-title">KYC Details</strong>
                    </h4>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label>Mobile No. Linked with Aadhar</label>
                      <input
                        className="form-control"
                        placeholder="Mobile No. Linked with Aadhar"
                        type="text"
                        maxLength="10"
                        id="mobileAadhar"
                        value={enrollFormik.values.mobileAadhar}
                        onChange={enrollFormik.handleChange}
                        onKeyPress={(e) => {
                          const isNumeric = /^[0-9]*$/;
                          if (!isNumeric.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        disabled={verifyAadharOtpStatus === "success"}
                      />
                      {getEnrollFormErrorMessage("mobileAadhar")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label> Aadhar No.</label>
                      {verifyAadharOtpStatus === "success" ? (
                        <span className="verified-pan">Verified</span>
                      ) : (
                        <span
                          className="verify-pan"
                          onClick={() =>
                            verifyAadhar(enrollFormik.values.aadhar)
                          }
                        >
                          Verify
                        </span>
                      )}
                      <input
                        className="form-control"
                        placeholder="Enter Aadhar No.*"
                        maxLength="12"
                        type="text"
                        id="aadhar"
                        value={enrollFormik.values.aadhar}
                        onChange={enrollFormik.handleChange}
                        onKeyPress={(e) => {
                          const isNumeric = /^[0-9]*$/;
                          if (!isNumeric.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        disabled={verifyAadharOtpStatus === "success"}
                      />
                      {getEnrollFormErrorMessage("aadhar")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label> Aadhar Front Image</label>
                      <input
                        className="form-control"
                        placeholder=""
                        type="file"
                        id="aadharFront"
                        accept=".jpg, .jpeg, .png, .svg"
                        onChange={handleAadharFront}
                      />
                      {getEnrollFormErrorMessage("aadharFront")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label> Aadhar Back Image</label>
                      <input
                        className="form-control"
                        placeholder=""
                        type="file"
                        id="aadharBack"
                        accept=".jpg, .jpeg, .png, .svg"
                        onChange={handleAadharBack}
                      />
                      {getEnrollFormErrorMessage("aadharBack")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group ">
                      <label>Pan No.</label>
                      {verifyPanStatus === "success" ? (
                        <span className="verified-pan">Verified</span>
                      ) : (
                        <span
                          className="verify-pan"
                          onClick={() =>
                            verifyPan(enrollFormik.values.pan.toUpperCase())
                          }
                        >
                          Verify
                        </span>
                      )}
                      <input
                        className="form-control"
                        placeholder="Enter  Pan No.*"
                        maxLength="10"
                        type="text"
                        id="pan"
                        value={enrollFormik.values.pan.toUpperCase()}
                        onChange={enrollFormik.handleChange}
                        disabled={verifyPanStatus === "success"}
                      />
                      {getEnrollFormErrorMessage("pan")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="form-group">
                      <label> Pan Image</label>
                      <input
                        className="form-control"
                        placeholder=""
                        type="file"
                        id="panImg"
                        accept=".jpg, .jpeg, .png, .svg"
                        onChange={handlePanImg}
                      />
                      {getEnrollFormErrorMessage("panImg")}
                    </div>
                  </div>
                  {enrollFormik.values.profile === "contractor" ? (
                    <div>
                      <div className="col-lg-3 col-md-3 col-xs-12">
                        <div className="form-group ">
                          <label>GST No.</label>
                          {verifyGstStatus === "success" ? (
                            <span className="verified-pan">Verified</span>
                          ) : (
                            <span
                              className="verify-pan"
                              onClick={() =>
                                verifyGst(enrollFormik.values.gst.toUpperCase())
                              }
                            >
                              Verify
                            </span>
                          )}
                          <input
                            className="form-control"
                            placeholder="Enter  GST  No."
                            type="text"
                            id="gst"
                            maxLength="15"
                            value={enrollFormik.values.gst.toUpperCase()}
                            onChange={enrollFormik.handleChange}
                            disabled={verifyGstStatus === "success"}
                          />
                          {getEnrollFormErrorMessage("gst")}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-xs-12">
                        <div className="form-group">
                          <label> GST Image</label>
                          <input
                            className="form-control"
                            placeholder=""
                            type="file"
                            id="gstImg"
                            accept=".jpg, .jpeg, .png, .svg"
                            onChange={handleGstImg}
                          />
                          {getEnrollFormErrorMessage("gstImg")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-sbmit"
                      value="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        className="pendingModal"
      >
        {/* <div className="otp-modal"> */}
        <div className="modal-dialog modal-sm modal-border mx-width">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={closeModal}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-details">
                <div className="row">
                  <div className="box-feild">
                    <OtpInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={6}
                      inputType="tel"
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                      onKeyDown={handleKeyDown}
                    />
                    <div className="resend-div">
                      <button
                        className={timer > 0 ? "resend-disable" : "resend-text"}
                        onClick={() => verifyAadhar(enrollFormik.values.aadhar)}
                        disabled={timer > 0} // Disable when the timer is active
                      >
                        Resend
                      </button>
                      {timer > 0 && (
                        <p className="resend-timmer">
                          <span className="time-count">
                            {`${Math.floor(timer / 60)}:${(
                              timer % 60
                            ).toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                              useGrouping: false,
                            })}`}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                  {errors.otp && (
                    <div className="error-message-modal">{errors.otp}</div>
                  )}
                  <div className="form-group col-lg-12 col-md-12 text-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-sbmit"
                      value="Submit"
                      onClick={verifyAadharOtp}
                    />
                    <input
                      type="submit"
                      className="btn btn-reject "
                      value="Cancel"
                      onClick={closeModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
