import React from 'react'
import Navbar from '../../common/Navbar'
import eligible_product from '../../../assets/img/eligible_product.png'
export default function EligibleProducts() {
    return (
        <>
            <Navbar />
            <section class="">
                <div class="container-fluid">
                    <div class="inner-sec mtop80px">
                        <div class="row">
                            <div class="col-md-8">
                                <figure class="abbnt2">
                                    <img src={eligible_product} style={{ width: '100%' }} alt="" />
                                </figure>
                            </div>

                            <div class="col-md-4">
                                <div class="elgt">
                                    <strong> Sintex Water Storage Tanks.</strong><br /><br />
                                    - Sintex TruPUF
                                    <br /> - Sintex Tatva
                                    <br /> - Sintex Pure
                                    <br /> - Sintex Ace
                                    <br /> - Sintex DW
                                    <br /> - Sintex Titus
                                    <br /> - Sintex UGWT
                                    <br /> - Sintex ISI
                                    <br /> - Sintex Hero
                                    <br /> - Sintex Reno
                                    <br /> - Sintex Neo
                                    <br /> - Sintex Loft
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}
