import React, { useEffect, useState } from "react";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../loader/CircleLoade";
import Navbar from "../../common/Navbar";
import OtpInput from "react-otp-input";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function PendingVerificationForm() {
  const location = useLocation();
  const rowData = location?.state?.rowData;

  console.log("rowData rowData", rowData);

  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErrors, setOtpErrors] = useState({});
  const [otpModalIsOpen, setOtpModalIsOpen] = useState(false);

  const [stateFetch, setStateFetch] = useState([]);
  const [districtFetch, setDistrictFetch] = useState([]);
  const [cityFetch, setCityFetch] = useState([]);

  const [state, setState] = useState("");
  const [stateId, setStateId] = useState(0);
  const [district, setDistrict] = useState("");
  const [districtId, setDistrictId] = useState(0);
  const [city, setCity] = useState("");
  const [cityId, setCityId] = useState(0);
  const [pincode, setPincode] = useState("");
  const [dob, setDob] = useState(rowData?.dob ? rowData?.dob : "");

  const formattedDob = moment(dob).format("DD-MM-YYYY");
  // Calculate the exact date 18 years ago from today
  const exactMinDate = new Date();
  exactMinDate.setFullYear(exactMinDate.getFullYear() - 18);
  exactMinDate.setHours(0, 0, 0, 0);

  useEffect(() => {
    if (!rowData) {
      navigate("/pending_verification");
    }

    let token = localStorage.getItem("token");
    try {
      axios
        .get(Constants.baseUrl + `master/geography/state?limit=1000`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 201 && res.data.code === 10001) {
            setStateFetch(res.data.output.results);
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // call state api by state ID for get district name
  const handleStateChange = async (event) => {
    const selectedId = event.target.value;
    setDistrictFetch([]);
    setCityFetch([]);
    setDistrict("");
    setCity("");
    setPincode("");
    if (selectedId === "0") {
      setState("");
      setDistrict("");
      setCity("");
    } else {
      districtData(selectedId);
    }
  };

  const districtData = async (stateid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/state/${stateid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              setState(res.data.output.name);
              setStateId(res.data.output.id);
              setDistrictFetch(res.data.output.districts);
            } else {
              // swal({
              //     text: res.data.msg,
              //     timer: 2000,
              //     buttons: false,
              // });
            }
          } else {
            // swal({
            //     text: res.data.msg,
            //     timer: 2000,
            //     buttons: false,
            // });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDistrictChange = async (event) => {
    const selectedId = event.target.value;
    setCityFetch([]);
    setCity("");
    setPincode("");
    if (selectedId === "0") {
      setDistrict("");
      setCity("");
    } else {
      cityData(selectedId);
    }
  };

  const cityData = async (cityid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/district/${cityid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              setDistrict(res.data.output.name);
              setDistrictId(res.data.output.id);
              setCityFetch(res.data.output.cities);
            } else {
              // swal({
              //     text: res.data.msg,
              //     timer: 2000,
              //     buttons: false,
              // });
            }
          } else {
            // swal({
            //     text: res.data.msg,
            //     timer: 2000,
            //     buttons: false,
            // });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: rowData?.id,
      name: rowData?.name,
      lastName: rowData?.lastName,
      firmName: rowData?.firmName,
      email: rowData?.email,
      mobile: rowData?.mobile,
      distrId: rowData?.distrId,
      distrName: rowData?.distrName,
      branch: rowData?.branch,
      dob: dob,
      alternateMobile: rowData?.alternateMobile,
      maritalStatus: rowData?.maritalStatus,
      qualification: rowData?.qualification,
      plumberName1: rowData?.plumberName1,
      plumberMobile1: rowData?.plumberMobile1,
      plumberName2: rowData?.plumberName2,
      plumberMobile2: rowData?.plumberMobile2,
      plumberName3: rowData?.plumberName3,
      plumberMobile3: rowData?.plumberMobile3,
      workAddress1: rowData?.addressWork?.workAddress1,
      workAddress2: rowData?.addressWork?.workAddress2,
      workAddress3: rowData?.addressWork?.workAddress3,
      workCity: rowData?.addressWork?.workCity,
      workCityId: rowData?.addressWork?.workCityId,
      workState: rowData?.addressWork?.workState,
      workStateId: rowData?.addressWork?.workStateId,
      workdistrict: rowData?.addressWork?.workdistrict,
      workdistrictId: rowData?.addressWork?.workdistrictId,
      workPincode: rowData?.addressWork?.workPincode,
    },
    validate: (data) => {
      console.log("data data data data", data);
      let errors = {};
      if (data.name == "") {
        errors.name = "Please enter first name";
      }
      if (data.firmName == "") {
        errors.firmName = "Please enter firm name";
      }

      if (data.distrName == "") {
        errors.distrName = "Please enter distributer name*";
      }

      if (data.distrId == "") {
        errors.distrId = "Please enter distributer id";
      }

      if (data.email == "") {
        errors.email = "Please enter email";
      }
      if (stateId == "" || stateId == "0") {
        errors.workStateId = "Please select state";
      }

      if (districtId == "" || districtId == "0") {
        errors.workdistrictId = "Please select district";
      }

      if (cityId == "" || cityId == "0") {
        errors.workCityId = "Please select city";
      }

      if (data.workAddress1 == "") {
        errors.workAddress1 = "Please enter Address1";
      }

      if (data.workPincode == "") {
        errors.workPincode = "Please enter Pincode";
      } else if (!/^\d+$/.test(data.workPincode)) {
        errors.workPincode = "workPincode must be numeric";
      } else if (data.workPincode.length !== 6) {
        errors.workPincode = "workPincode must be 6 digits";
      }

      if (!data.mobile) {
        errors.mobile = "Mobile no. is required";
      } else if (!/^\d+$/.test(data.mobile)) {
        errors.mobile = "Mobile no. must be numeric";
      } else if (data.mobile.length !== 10) {
        errors.mobile = "Mobile no. must be 10 digits";
      }

      if (data.alternateMobile && !/^\d+$/.test(data.alternateMobile)) {
        errors.alternateMobile = "Alternate Mobile no. must be numeric";
      } else if (data.alternateMobile && data.alternateMobile.length !== 10) {
        errors.alternateMobile = "Alternate Mobile no. must be 10 digits";
      }

      if (data.branch == "") {
        errors.branch = "Please enter branch name";
      }

      if (!dob) {
        errors.dob = "Please select dob";
      }

      return errors;
    },
    onSubmit: (data) => {
      console.log(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">{formik.errors[name]}</small>
      )
    );
  };

  const areAllValuesBlank = () => {
    const { search } = formik.values;
    return !search;
  };

  const [currentCard, setCurrentCard] = useState(1);
  // const handleNext = () => {
  //   formik.handleSubmit();
  //   if (Object.keys(formik.errors).length !== 0) {
  //     console.log("1");
  //   } else {
  //     setCurrentCard(currentCard + 1);
  //   }
  // };

  const handleNext = () => {
    formik.validateForm().then((errors) => {
      console.log("errors errors", errors);
      if (Object.keys(errors).length === 0) {
        setCurrentCard(currentCard + 1);
      } else {
        console.log("Validation errors:", errors);
        // Optionally handle errors here
      }
    });
  };

  const handleNextValidate = () => {
    if (Object.keys(formik.errors).length !== 0) {
      console.log("1");
    } else {
      setCurrentCard(currentCard + 1);
    }
  };

  const handlePrevious = () => {
    setCurrentCard(currentCard - 1);
  };

  const handleSendOtp = async () => {
    try {
      await axios
        .post(
          Constants.baseUrl + "temp/otp/send-otp",
          {
            mobile: formik.values.mobile,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("language"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program2,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success" && dt.code === 16001) {
            setOtpModalIsOpen(true);
          } else {
            swal({
              text: dt.msg,
              icon: "error",
              buttons: false,
              timer: 2000,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (e) => {
    // Allow only numeric values (0-9)
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleOtpChange = (otpValue) => {
    // Remove non-numeric characters from the entered OTP
    const numericOtp = otpValue.replace(/\D/g, "");

    setOtp(numericOtp);
  };

  const closeOtpModal = () => {
    setOtpModalIsOpen(false);
    setOtp("");
  };

  const validateMobileOtp = () => {
    let errors = {};

    if (!otp) {
      errors.otp = "Please Enter OTP";
    } else if (otp.length !== 6) {
      errors.otp = "OTP must be 6 digit";
    }
    return errors;
  };

  const verifyOtp = async () => {
    setOtpErrors({});

    const validationErrors = validateMobileOtp();

    if (Object.keys(validationErrors).length > 0) {
      setOtpErrors(validationErrors);
      return;
    }
    setLoading(true);
    try {
      await axios
        .post(
          Constants.baseUrl + "temp/otp/verify-otp",
          {
            mobile: formik.values.mobile,
            otp: otp,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("language"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program2,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          const dt = res.data;
          if (dt.result === "success" && dt.code === 10001) {
            updateRequest();
          }
          if (dt.result === "error" || dt.result === "dialog") {
            swal({
              text: dt.msg,
              icon: "error",
              buttons: false,
              timer: 2000,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const updateRequest = async () => {
    let token = localStorage.getItem("token");
    const postData = {
      name: formik.values.name,
      lastName: formik.values.lastName,
      email: formik.values.email,
      firmName: formik.values.firmName,
      distrId: formik.values.distrId,
      distrName: formik.values.distrName,
      branch: formik.values.branch,
      dob: formattedDob, //formik.values.dob,
      alternateMobile: formik.values.alternateMobile,
      maritalStatus: formik.values.maritalStatus,
      qualification: formik.values.qualification,
      plumberName1: formik.values.plumberName1,
      plumberMobile1: formik.values.plumberMobile1,
      plumberName2: formik.values.plumberName2,
      plumberMobile2: formik.values.plumberMobile2,
      plumberName3: formik.values.plumberName3,
      plumberMobile3: formik.values.plumberMobile3,
      // userModelKYC: {
      //   aadharMobile: data.mobileAadhar,
      //   aadharNumber: data.aadhar,
      //   aadharFrontUrl: data.aadharFront,
      //   aadharBackUrl: data.aadharBack,
      //   aadharVerify: "",
      //   aadharVerifiedBy: "",
      //   aadharVerifiedOn: "",
      //   panNumber: data.pan,
      //   panFrontUrl: data.panImg,
      //   panVerify: "",
      //   panVerifiedBy: "",
      //   panVerifiedOn: "",
      //   tanNumber: "",
      //   sinNumber: "",
      // },
      addressWork: {
        workAddress1: formik.values.workAddress1,
        workAddress2: formik.values.workAddress2,
        // workAddress3: data.address3,
        workCity: city,
        workCityId: cityId,
        workState: state,
        workStateId: stateId,
        workdistrict: district,
        workdistrictId: districtId,
        workPincode: formik.values.workPincode,
      },
      // bankDetail: {
      //   bankAccountNo: "",
      //   accountType: "",
      //   ifsc: "",
      //   beneficiary: "",
      //   bankName: "",
      //   holderName: "",
      //   branchName: "",
      //   cancelChk: "",
      //   upiId: "",
      //   upiIdScreen: "",
      // },
    };

    //console.log('postData', postData)

    try {
      await axios
        .post(
          `${Constants.baseUrl}user/manage/update/profile/${formik.values.id}`,
          postData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              // company: Constants.company,
              // program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              swal({
                text: "Updated Successfull",
                icon: "success",
                timer: 2000,
                buttons: false,
              });
              setTimeout(() => {
                formik.resetForm();
                navigate(`/pending_verification`);
              }, 500);
            } else if (res.data.code === 12001) {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <Modal
        isOpen={otpModalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setOtpModalIsOpen(false)}
        style={customStyles}
        contentLabel="OTP Modal"
        shouldCloseOnOverlayClick={false}
        className="pendingModal"
      >
        {/* <div className="otp-modal"> */}
        <div className="modal-dialog modal-sm modal-border mx-width">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={closeOtpModal}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-details">
                <div className="row">
                  <div className="box-feild">
                    <OtpInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={6}
                      inputType="tel"
                      renderInput={(props) => <input {...props} />}
                      onKeyDown={handleKeyDown}
                    />
                    {/* <div className="resend-div">
                      <button
                        className={timer > 0 ? "resend-disable" : "resend-text"}
                        onClick={() => verifyAadhar(enrollFormik.values.aadhar)}
                        disabled={timer > 0} // Disable when the timer is active
                      >
                        Resend
                      </button>
                      {timer > 0 && (
                        <p className="resend-timmer">
                          <span className="time-count">
                            {`${Math.floor(timer / 60)}:${(
                              timer % 60
                            ).toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                              useGrouping: false,
                            })}`}
                          </span>
                        </p>
                      )}
                    </div> */}
                  </div>
                  {otpErrors.otp && (
                    <div className="error-message-modal">{otpErrors.otp}</div>
                  )}
                  <div className="form-group col-lg-12 col-md-12 text-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-sbmit"
                      value="Submit"
                      onClick={verifyOtp}
                    />
                    <input
                      type="submit"
                      className="btn btn-reject "
                      value="Cancel"
                      onClick={closeOtpModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <section className="page-content--bge55">
        <div className="container-fluid">
          <form onSubmit={formik.handleSubmit}>
            <div class="row">
              <div class="col-md-12">
                <h3 class="head2">Pending for verification Form</h3>
              </div>
              {currentCard === 1 && (
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Select State*</label>
                            <select
                              className="form-control"
                              name="workStateId"
                              id="workStateId"
                              value={stateId}
                              onChange={handleStateChange}
                            >
                              <option value="0">Select State</option>
                              {stateFetch.map((item, index) => (
                                <option
                                  key={item.id} // Use a unique key for each option
                                  value={item.id}
                                  selected={stateId === item.id ? true : false}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {getSearchFormErrorMessage("workStateId")}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Select District*</label>
                            <select
                              className="form-control"
                              name="workdistrictId"
                              id="workdistrictId"
                              onChange={handleDistrictChange}
                              value={districtId}
                            >
                              <option value="0">Select District</option>
                              {districtFetch.map((item, index) => {
                                return (
                                  <>
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                            {getSearchFormErrorMessage("workdistrictId")}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>City*</label>
                            <select
                              className="form-control"
                              name="workCityId"
                              id="workCityId"
                              value={cityId}
                              onChange={(event) => {
                                setCityId(event.target.value);
                                const selectedCity = cityFetch.find(
                                  (item) => item.id == event.target.value
                                );
                                setCity(selectedCity ? selectedCity.name : "");
                              }}
                            >
                              <option value="">Select City</option>
                              {cityFetch.map((item, index) => {
                                return (
                                  <>
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                            {getSearchFormErrorMessage("workCityId")}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Pincode*</label>
                            <input
                              class="form-control"
                              name="workPincode"
                              id="workPincode"
                              maxLength='6'
                              value={formik.values.workPincode}
                              onChange={(e) => {
                                const input = e.target.value;
                                if (/^\d*$/.test(input)) {
                                  formik.handleChange({
                                    target: {
                                      name: "workPincode",
                                      value: e.target.value,
                                    },
                                  });
                                }
                              }}
                            />
                            {getSearchFormErrorMessage("workPincode")}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Address 1*</label>
                            <textarea
                              class="form-control"
                              rows="2"
                              name="workAddress1"
                              id="workAddress1"
                              value={formik.values.workAddress1}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "workAddress1",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            ></textarea>
                            {getSearchFormErrorMessage("workAddress1")}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Address 2</label>
                            <textarea
                              class="form-control"
                              rows="2"
                              name="workAddress2"
                              id="workAddress2"
                              value={formik.values.workAddress2}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "workAddress2",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Branch*</label>
                            {/* <select
                              name="branch_id"
                              id="branch_id"
                              class="form-control"
                            >
                              <option value="">Select Branch</option>
                            </select> */}
                            <input
                              class="form-control"
                              type="text"
                              name="branch"
                              id="branch"
                              value={formik.values.branch}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "branch",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("branch")}
                          </div>
                        </div>
                        {/* <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>FS Name*</label>
                            <input
                              name="fs_name"
                              id="fs_name"
                              class="form-control"
                              value=""
                              type="text"
                            />
                          </div>
                        </div> */}
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Distributer Name*</label>
                            <input
                              name="distrName"
                              id="distrName"
                              class="form-control"
                              value={formik.values.distrName}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "distrName",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("distrName")}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Distributer ID*</label>
                            <input
                              name="distrId"
                              id="distrId"
                              class="form-control"
                              value={formik.values.distrId}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "distrId",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("distrId")}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>First Name*</label>
                            <input
                              class="form-control"
                              type="text"
                              name="name"
                              id="name"
                              value={formik.values.name}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "name",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("name")}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Last Name</label>
                            <input
                              name="lastName"
                              id="lastName"
                              class="form-control"
                              type="text"
                              value={formik.values.lastName}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "lastName",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        {/* <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Gender</label>
                            <select
                              name="gender"
                              id="gender"
                              class="form-control"
                            >
                              <option value="">Select Gender</option>
                              <option value="MALE">MALE</option>
                              <option value="FEMALE">FEMALE</option>
                            </select>
                          </div>
                        </div> */}
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>DOB</label>
                            {/* <input
                              name="dob"
                              id="dob"
                              class="form-control"
                              value=""
                              type="text"
                            /> */}
                            <DatePicker
                              selected={dob}
                              onChange={(date) => setDob(date)}
                              dateFormat="dd-MM-yyyy "
                              className="form-control"
                              value={dob}
                              showYearDropdown
                              showMonthDropdown
                              placeholderText="dd-mm-yyyy"
                              maxDate={exactMinDate} // Up to the exact 18th birthday
                              onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                              onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                              onClick={(e) => e.preventDefault()} // Preven
                            />

                            {getSearchFormErrorMessage("dob")}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Marital Status</label>
                            <select
                              name="marital_status"
                              id="marital_status"
                              className="form-control"
                              value={formik.values.maritalStatus}
                              onChange={formik.handleChange} // Handle change using Formik's handleChange
                            >
                              <option value="">Select Marital Status</option>
                              <option value="MARRIED">MARRIED</option>
                              <option value="UNMARRIED">UNMARRIED</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Date Of Anniversary</label>
                            <input
                              name="anniversary_date"
                              id="anniversary_date"
                              class="form-control"
                              value=""
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Email*</label>
                            <input
                              class="form-control"
                              type="email"
                              name="email"
                              id="email"
                              value={formik.values.email}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "email",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("email")}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Mobile Number*</label>
                            <input
                              class="form-control"
                              type="text"
                              name="mobile"
                              id="mobile"
                              value={formik.values.mobile}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "mobile",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("mobile")}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Firm Name</label>
                            <input
                              class="form-control"
                              type="text"
                              name="firmName"
                              id="firmName"
                              value={formik.values.firmName}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "firmName",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Alternate Number</label>
                            <input
                              class="form-control"
                              type="text"
                              name="alternateMobile"
                              id="alternateMobile"
                              value={formik.values.alternateMobile}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "alternateMobile",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("alternateMobile")}
                          </div>
                        </div>
                      </div>

                      <div className="row mtop15">
                        {/* <div className="col-md-2 col-xs-6 text-left">
                        <div className="form-group">
                          <Link className="btn btn-primary" to="#">
                            Previous
                          </Link>
                        </div>
                      </div> */}
                        <div className="col-md-1 col-md-offset-11 col-xs-6 text-right">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={handleNext}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentCard === 2 && (
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Qualification</label>
                            <input
                              type="text"
                              class="form-control"
                              name="qualification"
                              id="qualification"
                              value={formik.values.qualification}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "qualification",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Favorite Cuisine</label>
                            <input
                              name="favorite_cuisine"
                              id="favorite_cuisine"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Favorite Restaurent (in your city)</label>
                            <input
                              name="favorite_restaurent"
                              id="favorite_restaurent"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Favorite Singer</label>
                            <input
                              name="favorite_singer"
                              id="favorite_singer"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Favorite Movie</label>
                            <input
                              name="favorite_movie"
                              id="favorite_movie"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Favorite Sport</label>
                            <input
                              name="favorite_sport"
                              id="favorite_sport"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Favorite Sport Person</label>
                            <input
                              name="favorite_sport_person"
                              id="favorite_sport_person"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Favorite Holiday Destination</label>
                            <input
                              name="favorite_holiday_destination"
                              id="favorite_holiday_destination"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>
                              Which international interior Fairs you would have
                              to attend?
                            </label>
                            <input
                              name="international_interior_attend"
                              id="international_interior_attend"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Favorite Interior Magazines</label>
                            <input
                              name="favorite_interior_magazines"
                              id="favorite_interior_magazines"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Hobbies</label>
                            <input
                              name="hobbies"
                              id="hobbies"
                              class="form-control"
                              value=""
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mtop15">
                        <div className="col-md-1 col-xs-6 text-left">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handlePrevious}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                        <div className="col-md-1 col-md-offset-10 col-xs-6 text-right">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={handleNext}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentCard === 3 && (
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-12 col-md-12">
                          <p>Document size limit 1 mb</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Shop Image</label>
                            <input
                              type="file"
                              name="shop_image"
                              id="shop_image"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Aadhaar Number</label>
                            <input
                              name="aadhaar_number"
                              id="aadhaar_number"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Aadhaar Image</label>
                            <input
                              type="file"
                              name="aadhaar_image"
                              id="aadhaar_image"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>GST Number</label>
                            <input
                              name="gst_number"
                              id="gst_number"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>GST Image</label>
                            <input
                              type="file"
                              name="gst_image"
                              id="gst_image"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>PAN Number</label>
                            <input
                              name="pan_number"
                              id="pan_number"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>PAN Image</label>
                            <input
                              type="file"
                              name="pan_image"
                              id="pan_image"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Select Anyone</label>
                            <select
                              name="anyone"
                              id="anyone"
                              class="form-control"
                            >
                              <option value="">Select Anyone</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Select Anyone Image</label>
                            <input
                              type="file"
                              name="Anyone_image"
                              id="Anyone_image"
                              class="form-control"
                              value=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mtop15">
                        <div className="col-md-1 col-xs-6 text-left">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handlePrevious}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                        <div className="col-md-1 col-md-offset-10 col-xs-6 text-right">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={handleNext}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentCard === 4 && (
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Plumber 1 Mobile</label>
                            <input
                              type="text"
                              class="form-control"
                              name="plumberMobile1"
                              id="plumberMobile1"
                              value={formik.values.plumberMobile1}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "plumberMobile1",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Plumber 1 Name</label>
                            <input
                              type="text"
                              class="form-control"
                              name="plumberName1"
                              id="plumberName1"
                              value={formik.values.plumberName1}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "plumberName1",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Plumber 2 Mobile</label>
                            <input
                              type="text"
                              class="form-control"
                              name="plumberMobile2"
                              id="plumberMobile2"
                              value={formik.values.plumberMobile2}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "plumberMobile2",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Plumber 2 Name</label>
                            <input
                              type="text"
                              class="form-control"
                              name="plumberName2"
                              id="plumberName2"
                              value={formik.values.plumberName2}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "plumberName2",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Plumber 3 Mobile</label>
                            <input
                              type="text"
                              class="form-control"
                              name="plumberMobile3"
                              id="plumberMobile3"
                              value={formik.values.plumberMobile3}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "plumberMobile3",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Plumber 3 Name</label>
                            <input
                              type="text"
                              class="form-control"
                              name="plumberName3"
                              id="plumberName3"
                              value={formik.values.plumberName3}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "plumberName3",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mtop15">
                        <div className="col-md-1 col-xs-6 text-left">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handlePrevious}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                        <div className="col-md-1 col-md-offset-10 col-xs-6 text-right">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleSendOtp}
                            >
                              Verify
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
