import React, { useState } from 'react'
import Navbar from '../../common/Navbar'
import { Link } from 'react-router-dom'
export default function FSReportData() {
    const [activeTab, setActiveTab] = useState('1a');
    return (
        <>
            <Navbar />
            <section class="page-content--bge55">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tabbable-panel margin-tops4 ">
                                <div class="tabbable-line bggg33">
                                    <ul class="nav nav-tabs tabtop  tabsetting">
                                        <li className={activeTab === '1a' ? 'active' : ''}> <Link to="" onClick={() => setActiveTab('1a')} data-toggle="tab" aria-expanded={activeTab === '1a'}>Retailers Wise Report </Link> </li>
                                        <li className={activeTab === '2a' ? 'active' : ''}> <Link to="" onClick={() => setActiveTab('2a')} data-toggle="tab" aria-expanded={activeTab === '2a'}> Branch Wise Report</Link> </li>
                                        {/* <li className={activeTab === '3a' ? 'active' : ''}> <Link to="" onClick={() => setActiveTab('3a')} data-toggle="tab" aria-expanded={activeTab === '3a'}> FS Wise Report </Link> </li> */}
                                    </ul>
                                    <div class="card card-body mtop15">
                                        <div class="tab-content">
                                            <div className={`tab-pane ${activeTab === '1a' ? 'active' : ''}`} id="1a">
                                                <br />
                                                <div class="row">
                                                    <div class="col-md-1 col-xs-6">
                                                        <div class="form-group">
                                                            <a class="btn btn-primary" href="#" id="btnExportAllretailer">
                                                                Export All Data
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive table--no-card">
                                                            <table class="table table-borderless table-striped table-earning" id="allRetailer">
                                                                <thead>
                                                                    <tr>
                                                                        <th> UserId </th>
                                                                        <th> Zone </th>
                                                                        <th> Branch </th>
                                                                        <th> RetailerCode </th>
                                                                        <th> FirmName </th>
                                                                        <th> RetailerName </th>
                                                                        <th> Retailer Mobile No </th>
                                                                        <th> Verification Status </th>
                                                                        <th>Opening 'Locked' Rewards on 30-Nov-22</th>
                                                                        <th>Earned 'Unlocked' Rewards  w.e.f. 01-Dec-22</th>
                                                                        <th>TDS w.e.f. 01-Dec-22</th>
                                                                        <th>Redeemed w.e.f. 01-Dec-22</th>
                                                                        <th>Total 'Locked + Unlocked' Rewards Balance</th>
                                                                        <th>Redeemable in current month</th>
                                                                        <th>Group 1 (Total Litterage)</th>
                                                                        <th>Group 2 (Total Litterage)</th>
                                                                        <th>Product Purchase Count</th>
                                                                        <th>Rewards Earned in Current Month (Including TDS)</th>
                                                                        <th>Month</th>
                                                                        <th> Distributor Code </th>
                                                                        <th> Distributor Name </th>
                                                                        <th> FS Code  </th>
                                                                        <th> FS Name </th>
                                                                        <th> TL Code </th>
                                                                        <th> TL Name </th>
                                                                        <th> ASM Code </th>
                                                                        <th> ASM Name </th>
                                                                        <th> ZM Code </th>
                                                                        <th> ZM Name </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`tab-pane ${activeTab === '2a' ? 'active' : ''}`} id="2a">
                                                <br />
                                                <div class="row">
                                                    <div class="col-md-1 col-xs-6">
                                                        <div class="form-group">
                                                            <a class="btn btn-primary" href="#" id="btnExportAllBranch">
                                                                Export All Data
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive table--no-card">
                                                            <table class="table table-borderless table-striped table-earning" id="allBranch">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Zone</th>
                                                                        <th>Branch</th>
                                                                        <th>No.of Retailers in DMS</th>
                                                                        <th>No.of Retailers in Hamesha App</th>
                                                                        <th>No.of Retailers Verified</th>
                                                                        <th>No.of Non-Verified Retailers</th>
                                                                        <th>No. of Retailers Who have earned Points(Verified & Non Verified Both)</th>
                                                                        <th> No.of Retailers Requested for Redemptions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`tab-pane ${activeTab === '3a' ? 'active' : ''}`} id="3a">
                                                <br />
                                                <div class="row">
                                                    <div class="col-md-1 col-xs-6">
                                                        <div class="form-group">
                                                            <a class="btn btn-primary" href="#" id="btnExportAllfsh">
                                                                Export All Data
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive table--no-card">
                                                            <table class="table table-borderless table-striped table-earning" id="allFS">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Zone</th>
                                                                        <th>Branch</th>
                                                                        <th>ZM Code</th>
                                                                        <th>ZM Name</th>
                                                                        <th>ASM Code</th>
                                                                        <th>ASM Name</th>
                                                                        <th>TL Code</th>
                                                                        <th>TL Name</th>
                                                                        <th>FS Code</th>
                                                                        <th>FS Name</th>
                                                                        <th>FS Mobile No</th>
                                                                        <th>No. of Retailers in DMS </th>
                                                                        <th>No. of Retailers in Hamesha Platform</th>
                                                                        <th>No. of retailers in FS App</th>
                                                                        <th>Retailers Verified in App</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
