import React from 'react'
import Navbar from '../../common/Navbar'
import rewardPointMatrix from '../../../assets/img/CreativeforAppAndWeb01Jul23.jpg'
export default function RewardPointMatrix() {
    return (
        <>
            <Navbar />
            <section className="page-content--bge55">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <img id="myImg" className="pntmtrix" src={rewardPointMatrix} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
