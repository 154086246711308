import React, { useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from "../../../common/Navbar";

export default function Faq() {

	const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [issueType, setIssueType] = useState([]);
  const [faqsData, setFaqsData] = useState([]);
  const [monthName, setMonthName] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getFaqsData();
  }, []);

  const getFaqsData = async () => {
    if (!token) {
      navigate("/");
    } else {
      await axios
        .get(
          `${Constants.baseUrl}program/child?id=2&sortBy=name&sortAsc=true&limit=100&page=1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setFaqsData(dt?.output?.results);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setFaqsData([]);
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <>
      <Navbar />
      <section class="page-content--bge55">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <h3 class="head2 text-center">
                Sintex Hamesha Retailer Loyalty Program
                <br />
                FAQ’s
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card" style={{ padding: "14px" }}>
                <div class="faq-content">
                  <div
                    class="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class=""
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq1"
                          >
                            <span>
                              Who is eligible to participate in this program?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq1"
                        class="panel-collapse collapse in"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                      >
                        <div class="panel-body">
                          The Sintex Hamesha Retailer Program is open for
                          enrolment of Retailers who buy directly from an
                          Authorized Distributor of Sintex Water Storage Tanks.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq2"
                          >
                            <span>
                              Who all are classified under the Retailer
                              category?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq2"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          The Retailer in the "Sintex Hamesha Retailer Program"
                          refers to retailers who buy directly from an
                          Authorized Distributor of Sintex Water Storage Tanks.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq3"
                          >
                            <span>
                              Products of SINTEX are eligible to participate in
                              the Program?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq3"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          Scheme is applicable for the entire range of Sintex
                          TruPUF, Sintex Tatva, Sintex Pure, Sintex Ace, Sintex
                          DW, Sintex Titus, Sintex Hero, Sintex Reno, Sintex
                          Neo, Sintex Loft, Sintex ISI, Sintex Sump (UGWT).
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq4"
                          >
                            <span>How to register for the first-time?</span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq4"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          <ul>
                            <li>
                              Registration is open and would be done only by
                              respective Sales Team assigned to the territory.
                            </li>
                            <li>
                              Aadhar Card, GST Registration Copy, Front counter
                              pic and Pan Card is mandatory for registration
                            </li>
                            <li>
                              Referral of 3 plumbers/plumbing contractors is
                              mandatory
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq5"
                          >
                            <span>
                              How do I report my purchases in the program?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq5"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          It would be automatically reported once you purchase
                          eligible products from the range of Sintex Water
                          Storage Tanks, from an authorized Distributor.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq6"
                          >
                            <span>
                              From where do I download Mobile Application?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq6"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          You can download the mobile application from either
                          Apple Store or Google Play Store (both Android and iOS
                          Platform) and search for ‘Sintex Hamesha Retailer’.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq7"
                          >
                            <span>
                              From when I will start earning points/value?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq7"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          Points/value would be allocated as soon as your
                          purchases get reflected at the Program centre.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq8"
                          >
                            <span>
                              How do I calculate my Reward Points/value?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq8"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          With every tank sold, you would get points/value
                          according to matrix in Sintex Hamesha Retailer Loyalty
                          Program
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq9"
                          >
                            <span>
                              Can I share/Club my Points with any other Sub
                              Dealer/Retailer?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq9"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          No, you cannot share/club your Points with anyone.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq10"
                          >
                            <span>How do I come to know my Point Status?</span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq10"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          Your Point Status will be informed to you by the
                          following manner:
                          <br />
                          a) By using your login credentials on the program
                          website i.e.{" "}
                          <a href="www.sintexhamesha.com">
                            www.sintexhamesha.com
                          </a>{" "}
                          OR on the Mobile Application
                          <br />
                          b) By calling us on the help line number
                          1800-121-2764, between 9.30 am – 5.30 pm from Monday
                          to Friday.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq11"
                          >
                            <span>
                              Till when can a Retailer register/join the Sintex
                              Retailer Loyalty Program?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq11"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          The Sintex Retailer Loyalty is an Open-Ended Program.
                          A Retailer can make a beginning anytime during the
                          course of the Program by Registering himself.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq12"
                          >
                            <span>
                              How do I Redeem Rewards against my accumulated
                              amount?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq12"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          You need to login to{" "}
                          <a href="https://www.sintexhamesha.com/">
                            www.sintexhamesha.com
                          </a>{" "}
                          or on Mobile App with the unique id and password
                          provided to you. Please visit the Redeem section to
                          view the catalogue online and follow the instructions
                          on the options to redeem.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq14"
                          >
                            <span>What is “Make A Wish”?</span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq14"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          “Make a Wish” is a section to ask for the product that
                          is not available in the catalogue. Your balance points
                          will be mapped against the requirement of your wish.
                          Any shortfall in the points has to be covered by
                          member only by purchasing more purchases.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq15"
                          >
                            <span>
                              Do I have to use website also, if I am already
                              using mobile app?{" "}
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq15"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          No, it is not mandatory to use only Mobile App or only
                          Website. You may use any of them through same log in
                          credentials. We have incorporated all the features on
                          both the interfaces.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq16"
                          >
                            <span>
                              Can my points be restored if I am not able to
                              utilise the redeemed voucher?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq16"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          No, once points / value are redeemed, the same cannot
                          be reversed.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#faq17"
                          >
                            <span>Can I redeem all the points at once?</span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq17"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          You can redeem up to 80% of total points.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h3 class="head2 text-center">
                FAQ’s on Sintex Hamesha Retailer Loyalty Program w.e.f. 01 July
                2023:
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card" style={{ padding: "14px" }}>
                <div class="faq-content">
                  <div
                    class="panel-group"
                    id="accordion_2"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion_2"
                            href="#faq01"
                          >
                            <span>How is my earning calculated?</span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq01"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          {/* Earnings depends on i) Which group the product lies in (1 or 2), ii) Qty Purchased per group  iii) Total Brands purchased */}
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion_2"
                            href="#faq02"
                          >
                            <span>What are Group 1 & Group 2?</span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq02"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          Group 1 contains premium priced brands: TruPUF, Tatva,
                          Pure, Ace, Titus, UGWT, Sintex DW Group 2 contains
                          mass market brands: Hero, Reno, Neo, Loft, ISI
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion_2"
                            href="#faq03"
                          >
                            <span>
                              How are my incentives calculated on Group 1, Group
                              2 & Total Litres?{" "}
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq03"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          Flat Scheme is calculated on group wise quantity,
                          Quantity scheme is also calculated on Group Wise Qty
                          and Range sales scheme is on total qty E.g.: 5000
                          Litres of Pure, 5000 Litres of Titus, 5000 Litres of
                          Reno: Total 15K Litres,
                          <br />
                          <b>Flat Scheme: </b>
                          <br />
                          &emsp;&emsp;&emsp;&emsp;Group 1: Pure + Titus = 10000
                          Litres * 10p = Rs.1000
                          <br />
                          &emsp;&emsp;&emsp;&emsp;Group 2: Reno = 5000 Litres *
                          5p = Rs. 250
                          <br />
                          <b>Quantity Purchase: </b>
                          <br />
                          &emsp;&emsp;&emsp;&emsp;Group 1: 10000 Litres*10p =
                          Rs. 1000
                          <br />
                          &emsp;&emsp;&emsp;&emsp;Group 2: 5000 Litres*4p = Rs.
                          200
                          <br />
                          <b>
                            Range- Since there are 3 brands then 3p slab on
                            total qty{" "}
                          </b>
                          <br />
                          &emsp;&emsp;&emsp;&emsp;Qty: 15000*(3p) = Rs. 450
                          <br />
                          <br />
                          &emsp;&emsp;<b>Total = </b>1000+250+1000+200+450 ={" "}
                          <b>Rs. 2900</b>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion_2"
                            href="#faq04"
                          >
                            <span>
                              What is the earning potential of a newly added
                              retailer?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq04"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          Newly added retailers who purchase more than 1K litres
                          will get flat loyalty payout + avg payout of 8 paise
                          for Qty purchasing + range sale payout as per
                          purchase. 6 months performance will define their new
                          classification
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion_2"
                            href="#faq05"
                          >
                            <span>How are different brands counted?</span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq05"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          TruPUF, Tatva, Pure, ISI, Ace, Sintex Double Wall(DW),
                          UGWT, Titus, Hero, Reno, Neo & Loft are considered
                          different brands for range sales payout – with earning
                          upto 5 paise/litre for billing 4 or more brands
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion_2"
                            href="#faq06"
                          >
                            <span>
                              I was earning 10paise/Ltr on earlier purchase of
                              1000 Litres of Reno & Neo, How much will I make
                              now?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq06"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          You will still earning minimum 10 paise/Ltr with the
                          following breakup: 5 Paise / Ltr flat scheme + 2 Paise
                          / Ltr Qty scheme for purchasing 1K Litres & 3
                          Paise/Ltr for buying 2 brands Further you can earn
                          more by increasing qty or adding more brands
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion_2"
                            href="#faq07"
                          >
                            <span>How can I win the Mega Loyalty Reward?</span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq07"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          {/* There are 4 slabs of Mega Loyalty rewards: >=10 Lac Litres, 7-9.9 Lac Litres, 4-6.9 Lac Litres, 2-4 Lac Litres with July’23 to Mar’24 as the delivery period. Retailers have to ensure to bill every month in this period. */}
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion_2"
                            href="#faq08"
                          >
                            <span>
                              How many people can go for the international trip
                              awards?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq08"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          Each reward covers 1 person for the trip, covering
                          from Onwards Flights to Return Flights, hotel stay,
                          all meals, planned excursions & sightseeing
                          activities.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion_2"
                            href="#faq09"
                          >
                            <span>
                              What about inclusion of new outlets in the Mega
                              Loyalty Program?
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="faq09"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div class="panel-body">
                          New outlets which are added and billed till September
                          2023 only will be considered in the Mega Loyalty
                          Program and they will have bill in all months till
                          March 2024; Any outlet addition after September 2023
                          will not be considered in this program.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
