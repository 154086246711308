import React from 'react'

export default function Footer() {
    return (
        <>
            <footer class="footer-3 footer-topbar light">
                <div class="container">
                    <div class="copyright">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-xs-12">
                                    <div class="cpt text-center">
                                        <p style={{ textAlign: 'center' }}>© Copyrights 2020. All Rights Reserved</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
