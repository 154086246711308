import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "react-datepicker/dist/react-datepicker.css";
// other pages
import Home from './components/pages/Home/Home'
import Login from './components/pages/Auth/Login'
import FogotPassword from './components/pages/Auth/FogotPassword'
import SintexHamesha from './components/pages/otherPages/SintexHamesha'
import EligibleProducts from './components/pages/otherPages/EligibleProducts'
import ContactUs from './components/pages/otherPages/ContactUs'
import About from './components/pages/otherPages/About'
import Profile from './components/pages/Profile/Profile'
import ChangePassword from './components/pages/Auth/ChangePassword'
import Protected from './components/pages/otherPages/Protected'


//app tnc start
import EnglishTnc from './components/pages/appTnc/English';
import EnglishRetailerTnc from './components/pages/appTnc/RetailerEnglish';
//app tnc end

//app faq start
import EnglishFaq from './components/pages/appFaq/English';
import EnglishRetailerFaq from './components/pages/appFaq/RetailerEnglish';
//app faq end


//sales influencer start
import InfluencerDashboard from './components/pages/SalesTeam/Sales/InfluencerDashboard'
import ApprovedPlumber from './components/pages/SalesTeam/Sales/ApprovedPlumber'
import ProgramPerformance from './components/pages/SalesTeam/Sales/ProgramPerformance'
import PlumberPerformance from './components/pages/SalesTeam/Sales/PlumberPerformance'
import PlumberEnrollment from './components/pages/SalesTeam/Sales/PlumberEnrollment'
import PendingApproval from './components/pages/SalesTeam/Sales/PendingApproval'
import RejectedPlumbers from './components/pages/SalesTeam/Sales/RejectedPlumbers'
import SchemeOffer from './components/pages/SalesTeam/Sales/SchemeOffer'
import PendingVerification from './components/pages/SalesTeam/Sales/PendingVerification'
import InfluencerTnc from './components/pages/SalesTeam/Sales/InfluencerTnc'
import InfluencerFaq from './components/pages/SalesTeam/Sales/InfluencerFaq'
import SintexInfluencer from './components/pages/SalesTeam/Sales/SintexInfluencer'
//influencer end

//retailer start
import RetailerDashboard from './components/pages/SalesTeam/Retailer/RetailerDashboard'
import PurchaseHistory from './components/pages/SalesTeam/Retailer/PurchaseHistory'
import RewardPoints from './components/pages/SalesTeam/Retailer/RewardPoints'
import VoucherRedemptionHistory from './components/pages/SalesTeam/Retailer/VoucherRedemptionHistory'
import RedemptionHistory from './components/pages/SalesTeam/Retailer/RedemptionHistory'
import OrderIndent from './components/pages/SalesTeam/Retailer/OrderIndent'
import Videos from './components/pages/SalesTeam/Retailer/Videos'
import PlumberList from './components/pages/SalesTeam/Retailer/PlumberList'
import FeedbackSupport from './components/pages/SalesTeam/Retailer/FeedbackSupport'
import InfoDesk from './components/pages/SalesTeam/Sales/InfoDesk';
import Faq from './components/pages/SalesTeam/Retailer/Faq'
import Tnc from './components/pages/SalesTeam/Retailer/Tnc'
import MakeWish from './components/pages/SalesTeam/Retailer/MakeWish'
import MakeWishHistory from './components/pages/SalesTeam/Retailer/MakeWishHistory'
import UploadShopImage from './components/pages/Sales Retailer/UploadShopImage'
//retailer end

import Category1 from './components/pages/SalesTeam/category/Category1'
import Category2 from './components/pages/SalesTeam/category/Category2'

//sales retailers start
import SalesRetailerDashboard from './components/pages/Sales Retailer/SalesRetailerDashboard'
import PendingVerificationSales from './components/pages/Sales Retailer/PendingVerification'
import PendingVerificationFormSales from './components/pages/Sales Retailer/PendingVerificationForm'
import VerifyPlumber from './components/pages/Sales Retailer/VerifyPlumber'
import AddPlumber from './components/pages/Sales Retailer/AddPlumber'
import DistributorReports from './components/pages/Sales Retailer/DistributorReports'
import RetailerReport from './components/pages/Sales Retailer/RetailerReport'
import RewardPointMatrix from './components/pages/Sales Retailer/RewardPointMatrix'
import ProductPurchaseRequest from './components/pages/Sales Retailer/ProductPurchaseRequest'
import SignageBoardStatus from './components/pages/Sales Retailer/SignageBoardStatus'
import FSNPSReport from './components/pages/Sales Retailer/FSNPSReport'
import FSReportData from './components/pages/Sales Retailer/FSReportData'
import RewardCalculator from './components/pages/Sales Retailer/RewardCalculator'
import PlumberReports from './components/pages/Sales Retailer/PlumberReports'
// import UploadShopImage from './components/pages/Sales Retailer/UploadShopImage'
import UploadShopImageRet from './components/pages/SalesTeam/Retailer/UploadShopImageRet'
import SalesMakeWishHistory from './components/pages/SalesTeam/Retailer/SalesMakeWishHistory'
import PageNotFound from './components/pages/otherPages/PageNotFound'
//sales retailers end
import EnrollmentReport from './components/pages/Reports/EnrollmentReport';
import QrTransReport from './components/pages/Reports/QrTransReport';
import RedemptionReport from './components/pages/Reports/RedemptionReport'
import LoginReport from './components/pages/Reports/LoginReport'
import TicketReport from './components/pages/Reports/TicketReport'
import SmsReport from './components/pages/Reports/SmsReport'
import HierarchyReport from './components/pages/Reports/HierarchyReport'
import TopPerformerReport from './components/pages/Reports/TopPerformerReport'

import VoucherRedeemptionReport from './components/pages/Reports/VoucherRedeemptionReport';
import RetailerWiseRedeemption from './components/pages/Reports/RetailerWiseRedeemption';
import ShopImageStatus from './components/pages/Reports/ShopImageStatus';
import RetailerPoints from './components/pages/Reports/RetailerPoints';
import VerifyRetailer from './components/pages/Reports/VerifyRetailer';
import ReatilerTransaction from './components/pages/Reports/ReatilerTransaction';
import ToptenRetailer from './components/pages/Reports/ToptenRetailer';
import ToptenRetailerBranchWise from './components/pages/Reports/ToptenRetailerBranchWise';
import LoginReportRetailer from './components/pages/Reports/LoginReportRetailer';
import RetailerRedemptionReport from './components/pages/Reports/RetailerRedemptionReport';


export default function App() {
  localStorage.setItem('language', 'en')
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* others routes */}
          <Route path='/' element={<Home />}></Route>
          <Route path='/login' element={<Login />}></Route>
          <Route path='/forgot_password' element={<FogotPassword />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/sintex_hamesha' element={<SintexHamesha />}></Route>
          <Route path='/eligible_products' element={<EligibleProducts />}></Route>
          <Route path='/contact' element={<ContactUs />}></Route>
          <Route path='/changepassword' element={<ChangePassword />}></Route>
          <Route path='/profile' element={<Profile />}></Route>
          <Route path='/*' element={<PageNotFound />}></Route>

          {/* app tnc routes */}
          <Route path='/app/tnc_en' element={<EnglishTnc />}></Route>
          <Route path='/app/retailer/tnc_en' element={<EnglishRetailerTnc />}></Route>
          <Route path='/app/tnc_hi' element={<EnglishTnc />}></Route>

          {/* app faq routes */}
          <Route path='/app/faq_en' element={<EnglishFaq />}></Route>
          <Route path='/app/retailer/faq_en' element={<EnglishRetailerFaq />}></Route>
          <Route path='/app/faq_hi' element={<EnglishFaq />}></Route>


          {/* sales influencer routes */}
          {/* <Route path='/influencer_dashboard' element={<Protected Cmp={InfluencerDashboard} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/influencer_dashboard' element={<InfluencerDashboard />}></Route>
          {/* <Route path='/approved_plumber' element={<Protected Cmp={ApprovedPlumber} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/approved_plumber' element={<ApprovedPlumber />}></Route>
          {/* <Route path='/sintex/category' element={<Protected Cmp={Category1} requiredRoles={['SALES']} />}></Route> */}
          <Route path='/sintex/category' element={<Category1 />}></Route>
          {/* <Route path='/category2' element={<Protected Cmp={Category2} requiredRoles={['SALES']} />}></Route> */}
          
          <Route path='/category2' element={<Category2 />}></Route>
          {/* <Route path='/program_performance' element={<Protected Cmp={ProgramPerformance} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/program_performance' element={<ProgramPerformance />}></Route>
          {/* <Route path='/plumber_performance' element={<Protected Cmp={PlumberPerformance} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/plumber_performance' element={<PlumberPerformance />}></Route>
          {/* <Route path='/plumber_enrollment' element={<Protected Cmp={PlumberEnrollment} requiredRoles={['SALES']} />}></Route> */}
          <Route path='/plumber_enrollment' element={<PlumberEnrollment />}></Route>
          {/* <Route path='/pending_for_verification' element={<Protected Cmp={PendingVerification} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/pending_for_verification' element={<PendingVerification />}></Route>
          {/* <Route path='/pending_for_approval' element={<Protected Cmp={PendingApproval} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/pending_for_approval' element={<PendingApproval />}></Route>
          {/* <Route path='/rejected_plumbers' element={<Protected Cmp={RejectedPlumbers} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/rejected_plumbers' element={<RejectedPlumbers />}></Route>
          {/* <Route path='/scheme_offer' element={<Protected Cmp={SchemeOffer} requiredRoles={['SALES']} />}></Route> */}
          <Route path='/scheme_offer' element={<SchemeOffer />}></Route>
          {/* <Route path='/influencer_infodesk' element={<Protected Cmp={InfoDesk} requiredRoles={['SALES']} />}></Route> */}
          <Route path='/influencer_infodesk' element={<InfoDesk />}></Route>
          {/* <Route path='/influencertnc' element={<Protected Cmp={InfluencerTnc} requiredRoles={['SALES']} />}></Route> */}
          <Route path='/influencertnc' element={<InfluencerTnc />}></Route>
          {/* <Route path='/influencerfaq' element={<Protected Cmp={InfluencerFaq} requiredRoles={['SALES']} />}></Route> */}
          <Route path='/influencerfaq' element={<InfluencerFaq />}></Route>
          {/* <Route path='/sintex_influencer' element={<Protected Cmp={SintexInfluencer} requiredRoles={['SALES']} />}></Route> */}
          <Route path='/sintex_influencer' element={<SintexInfluencer />}></Route>
          {/* retailer routes */}
          {/* <Route path='/retailer_Dashboard' element={<Protected Cmp={RetailerDashboard}  requiredRoles={['RETAILER']} />}></Route> */}
          <Route path='/retailer_Dashboard' element={<RetailerDashboard />}></Route>
          {/* <Route path='/Purchase_History' element={<Protected Cmp={PurchaseHistory} requiredRoles={['RETAILER']} />}></Route> */}
          <Route path='/Purchase_History' element={<PurchaseHistory />}></Route>
          {/* <Route path='/Rewardpoints' element={<Protected Cmp={RewardPoints} requiredRoles={['RETAILER']} />}></Route> */}
          <Route path='/Rewardpoints' element={<RewardPoints />}></Route>
          {/* <Route path='/VoucherRedemptionHistory' element={<Protected Cmp={VoucherRedemptionHistory}  />}></Route> */}
          <Route path='/VoucherRedemptionHistory' element={<VoucherRedemptionHistory />}></Route>
          {/* <Route path='/redemption_history' element={<Protected Cmp={RedemptionHistory} requiredRoles={['RETAILER']} />}></Route> */}
          <Route path='/redemption_history' element={<RedemptionHistory />}></Route>
          {/* <Route path='/order_indent' element={<Protected Cmp={OrderIndent} requiredRoles={['RETAILER']} />}></Route> */}
          <Route path='/order_indent' element={<OrderIndent />}></Route>
          {/* <Route path='/videos' element={<Protected Cmp={Videos} requiredRoles={['SALES', 'RETAILER']} />}></Route> */}
          <Route path='/videos' element={<Videos />}></Route>
          {/* <Route path='/PlumberList' element={<Protected Cmp={PlumberList} requiredRoles={['SALES']} />}></Route> */}
          <Route path='/PlumberList' element={<PlumberList />}></Route>
          {/* <Route path='/add_plumber' element={<Protected Cmp={AddPlumber} requiredRoles={['SALES']} />}></Route> */}
          <Route path='/add_plumber' element={<AddPlumber />}></Route>
          {/* <Route path='/feedback_support' element={<Protected Cmp={FeedbackSupport} requiredRoles={['RETAILER']}  />}></Route> */}
          <Route path='/feedback_support' element={<FeedbackSupport />}></Route>
          {/* <Route path='/faq' element={<Protected Cmp={Faq}  requiredRoles={['RETAILER']}  />}></Route> */}
          <Route path='/faq' element={<Faq />}></Route>
          {/* <Route path='/tnc' element={<Protected Cmp={Tnc}  requiredRoles={['RETAILER']}  />}></Route> */}
          <Route path='/tnc' element={<Tnc />}></Route>
          {/* <Route path='/makewish' element={<Protected Cmp={MakeWish}  />}></Route> */}
          <Route path='/makewish' element={<MakeWish />}></Route>
          {/* <Route path='/make_a_wish_history' element={<Protected Cmp={MakeWishHistory}  requiredRoles={['RETAILER']}  />}></Route> */}
          <Route path='/make_a_wish_history' element={<MakeWishHistory />}></Route>
          <Route path='/Retailer_Redemption_Report' element={<RetailerRedemptionReport/>}></Route>
          <Route path='/upload_shop_image_rate' element={<UploadShopImageRet />}></Route>

          {/* sales retailer routes */}
          <Route path='/sales_retailer_Dashboard' element={<SalesRetailerDashboard />}></Route>
          <Route path='/pending_verification' element={<PendingVerificationSales />}></Route>
          <Route path='/pending_verification_form' element={<PendingVerificationFormSales />}></Route>
          <Route path='/verify_plumber' element={<VerifyPlumber />}></Route>
          <Route path='/add_plumber' element={<AddPlumber />}></Route>
          <Route path='/distributor_reports' element={<DistributorReports />}></Route>
          <Route path='/retailer_report' element={<RetailerReport />}></Route>
          <Route path='/reward_point_matrix' element={<RewardPointMatrix />}></Route>
          <Route path='/product_purchase_request' element={<ProductPurchaseRequest />}></Route>
          
          <Route path='/signage_board_status' element={<SignageBoardStatus />}></Route>
          <Route path='/FSNPSReport' element={<FSNPSReport />}></Route>
          <Route path='/FSReportData' element={<FSReportData />}></Route>
          <Route path='/RewardCalculator' element={<RewardCalculator />}></Route>
          <Route path='/plumber_reports' element={<PlumberReports />}></Route>
          {/* <Route path='/UploadShopImage' element={<Protected Cmp={UploadShopImage}  requiredRoles={['SALES']}  />}></Route> */}
          <Route path='/UploadShopImage' element={<UploadShopImage />}></Route>
          {/* <Route path='/sales/make_a_wish_history' element={<Protected Cmp={SalesMakeWishHistory}  requiredRoles={['SALES']}  />}></Route> */}
          <Route path='/sales/make_a_wish_history' element={<SalesMakeWishHistory />}></Route>

          {/* reports */}
          {/* <Route path='/enrollment_report' element={<Protected Cmp={EnrollmentReport} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/enrollment_report' element={<EnrollmentReport />}></Route>
          {/* <Route path='/qrtrans_report' element={<Protected Cmp={QrTransReport} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/qrtrans_report' element={<QrTransReport />}></Route>
          {/* <Route path='/redemption_report' element={<Protected Cmp={RedemptionReport} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/redemption_report' element={<RedemptionReport />}></Route>
          {/* <Route path='/login_report' element={<Protected Cmp={LoginReport} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/login_report' element={<LoginReport />}></Route>

          {/* <Route path='/ticket_report' element={<Protected Cmp={TicketReport} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/ticket_report' element={<TicketReport />}></Route>
          {/* <Route path='/sms_report' element={<Protected Cmp={SmsReport} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/sms_report' element={<SmsReport />}></Route>

          {/* <Route path='/hierarchy_report' element={<Protected Cmp={HierarchyReport} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/hierarchy_report' element={<HierarchyReport />}></Route>
          {/* <Route path='/top_performer_report' element={<Protected Cmp={TopPerformerReport} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/top_performer_report' element={<TopPerformerReport />}></Route>

          {/* <Route path='/voucher_redeemption_report' element={<Protected Cmp={VoucherRedeemptionReport} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/voucher_redeemption_report' element={<VoucherRedeemptionReport />}></Route>
          
          {/* <Route path='/retailer_wise_redeemption' element={<Protected Cmp={RetailerWiseRedeemption} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/retailer_wise_redeemption' element={<RetailerWiseRedeemption />}></Route>

          {/* <Route path='/shop_image_status' element={<Protected Cmp={ShopImageStatus} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/shop_image_status' element={<ShopImageStatus />}></Route>

          {/* <Route path='/retailer_points' element={<Protected Cmp={RetailerPoints} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/retailer_points' element={<RetailerPoints />}></Route>

          {/* <Route path='/verify_retailer' element={<Protected Cmp={VerifyRetailer} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/verify_retailer' element={<VerifyRetailer />}></Route>

          {/* <Route path='/retailer_transaction' element={<Protected Cmp={ReatilerTransaction} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/retailer_transaction' element={<ReatilerTransaction />}></Route>
          {/* <Route path='/topten_retailer' element={<Protected Cmp={ToptenRetailer} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/topten_retailer' element={<ToptenRetailer />}></Route>
          {/* <Route path='/topten_retailer_branch_wise' element={<Protected Cmp={ToptenRetailerBranchWise} requiredRoles={['SALES', 'HO']} />}></Route> */}
          <Route path='/topten_retailer_branch_wise' element={<ToptenRetailerBranchWise />}></Route>
          <Route path='/login_report_retailer' element={<LoginReportRetailer/>}></Route>

        </Routes >
      </BrowserRouter >
    </>
  )
}
