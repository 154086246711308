import React, { useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from '../../../common/Navbar'
export default function MakeWishHistory() {

	const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [makeWishHistory, setMakeWishHistory] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(100);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getMakeWishHistory(1);
  }, []);

  const searchFormik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getMakeWishHistory(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getMakeWishHistory = async (pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      // let stDate;
      // let endDate;

      // var momentObj1 = moment(data.startDate);
      // var momentObj2 = moment(data.endDate);
      // if (data.startDate && data.endDate) {
      //   var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
      //   var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
      //   stDate = `&startDate=${startFormattedDate}`;
      //   endDate = `&enddate=${startFormattedDate4}`;
      // } else {
      //   stDate = "";
      //   endDate = "";
      // }

      await axios
        .get(
          Constants.baseUrl +
            `program/Wish/retailer?sortAsc=true&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          //console.log(res);
          const dt = res.data;
          if (dt.result === "success") {
            setMakeWishHistory(dt?.output);
            //setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setMakeWishHistory(null);
            //setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const areAllValuesBlank = () => {
    const { startDate, endDate } = searchFormik.values;
    return !startDate && !endDate;
  };


	return (
		<>
			{loading ? <Oval /> : null}
			<Navbar />
			<section className="page-content--bge55">
				<div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3 className="head2">Make a Wish History</h3>
            </div>
          </div>
					<div className="row">
						<div className="col-md-12">
							<div className="card">
								<div className="card-header">
									<strong className="card-title">Existing Retailers</strong>
								</div>
								<div className="card-body">
									{/* <div className="row">
										<div className="col-md-3 col-xs-6 text-right">
											<div className="form-group">
												<input className="form-control" type="text" placeholder="Search Retailers" />
											</div>
										</div>
									</div> */}
									<div className="row">
										<div className="col-md-12">
											<div className="table-responsive table--no-card">
												<table className="table table-borderless table-striped table-earning">
													<thead>
														<tr>
                              <th> Date </th>
															<th> Wish List </th>
															<th> Status </th>
															{/* <th> S No. </th>
															<th> Retailer Id </th>
															<th> Mobile No </th>
															<th> Name </th>
															<th> Firm Name </th>
															<th> Status </th> */}
															{/* <th> State </th>
															<th> City </th> */}
														</tr>
													</thead>
													{makeWishHistory === null ? (
                        <tbody>
                          <tr>
                            <td
                              colSpan="3"
                              style={{ textAlign: "center", fontSize: "16px" }}
                            >
                              No record found!
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {makeWishHistory &&
                            makeWishHistory.length > 0 &&
                            makeWishHistory.map((row, index) => {
                              const serialNumber =
                                (currentPage - 1) * limit + index + 1;

                              const createdDate = row.createdDateTime
                                ? moment(row.createdDateTime).format(
                                    "DD-MM-YYYY"
                                  )
                                : "";
                              return (
                                <>
                                  <tr key={index}>
                                    {/* <td>{serialNumber}</td> */}
                                    <td>{createdDate}</td>
                                    <td>{row?.decription}</td>
                                    <td>{row?.status}</td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      )}
												</table>
											</div>
										</div>
									</div>
									{/* <div className="row mtop15">
										<div className="col-md-2 col-xs-6 text-left">
											<div className="form-group">
												<Link className="btn btn-primary" to="#">
													Previous
												</Link>
											</div>
										</div>
										<div className="col-md-2 col-md-offset-8 col-xs-6 text-right">
											<div className="form-group">
												<Link className="btn btn-primary" to="#">
													Next
												</Link>
											</div>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
