import React, { useState, useEffect } from "react";
import Navbar from "../../../common/Navbar";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import Oval from "../../loader/CircleLoade";
import { useNavigate } from "react-router-dom";
import ResponsivePagination from "react-responsive-pagination";
import swal from "sweetalert";
export default function PlumberPerformance() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [disRetName, setDisRetName] = useState("");
  const [group, setGroup] = useState("");
  const [limit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [results, setResults] = useState([]);
  const [disRetGroupData, setDisRetGroupData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [searchRecord, setSearchRecord] = useState();
  const [showTable, setShowTable] = useState(true);

  useEffect(() => {
    disRetailerGroupReq("");
    approvedPlumberReq(searchUser);
  }, [currentPage]);

  useEffect(() => {}, [currentPage]);

  const approvedPlumberReq = async (data) => {
    let token = localStorage.getItem("token");
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      const searchUserVal = data === "" ? "" : `name=${data}&`;

      try {
        await axios
          .get(
            `${Constants.baseUrl}user/manage/userByparentId?${searchUserVal}userType=MEMBER&status=APPROVED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                // company: Constants.company,
                program: Constants.program,
              },
            }
          )
          .then((res) => {
            if (res.status === 201) {
              if (res.data.code === 10001) {
                setResults(res.data.output.results);
                setTotalPages(res.data.output.totalPages);
                setLoading(false);
                setShowTable(false);
              } else if (res.data.code === 12002) {
                setShowTable(false);
                setLoading(false);
                setSearchRecord(res.data.output.results);
                setTotalPages(res.data.output.totalPages);
              } else {
                swal({
                  text: res.data.msg,
                  // icon: 'error',
                  timer: 2000,
                  buttons: false,
                });
              }
            } else {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSearchButtonClick = () => {
    if (searchUser.trim() !== "") {
      // If searching by user name, call approvedPlumberReq
      approvedPlumberReq(searchUser);
    } else if (disRetName !== "") {
      // If typing in disRetName, call disRetailerReq with disRetName
      disRetailerGroupReq(disRetName);
    } else if (group !== "") {
      // If selecting a group, call disRetailerReq with group
      disRetailerGroupReq(group);
    }
  };

  const disRetailerGroupReq = async (data) => {
    let token = localStorage.getItem("token");
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      const searchUserVal = data === "" ? "" : `name=${data}&`;

      try {
        await axios
          .post(
            `${Constants.baseUrl}capture/qr/CaptureQrCodeHistory/Search/parentId?${searchUserVal}sortBy=retailer_name&sortAsc=true&limit=${limit}&page=${currentPage}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                // company: Constants.company,
                program: Constants.program,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 10001) {
                setDisRetGroupData(res.data.output.results);
                setTotalPages(res.data.output.totalPages);
                setLoading(false);
                setShowTable(true);
                setSearchRecord();
              } else if (res.data.code === 12001) {
                setShowTable(true);
                setLoading(false);
                setSearchRecord(res.data.output.results);
                setTotalPages(res.data.output.totalPages);
              } else {
                swal({
                  text: res.data.msg,
                  // icon: 'error',
                  timer: 2000,
                  buttons: false,
                });
              }
            } else {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSearch = (event) => {
    setSearchUser(event.target.value);
    if (event.target.value === "") {
      setShowTable(true);
      setSearchRecord();
      setResults([]);
      setSearchUser("");
      setDisRetName("");
      setGroup("");
    }
  };
  const handleDisRet = (event) => {
    setDisRetName(event.target.value);
    if (event.target.value === "") {
      // setShowTable(false)
      setSearchRecord();
      setResults([]);
      setSearchUser("");
      setDisRetName("");
      setGroup("");
    }
  };
  const handleGroup = (event) => {
    setGroup(event.target.value);
    if (event.target.value === "") {
      // setShowTable(false)
      setSearchRecord();
      setResults([]);
      setSearchUser("");
      setDisRetName("");
      setGroup("");
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
    }
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3 className="head1">Plumber Performance</h3>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-2 col-xs-6">
                            <div className="form-group">
                                <input className="form-control reset" type="text" placeholder="From Date" />
                            </div>
                        </div>
                        <div className="col-md-2 col-xs-6">
                            <div className="form-group">
                                <input className="form-control reset" type="text" placeholder="To Date" />
                            </div>
                        </div> */}
            <div className="col-md-3 col-xs-6">
              <div className="form-group">
                <input
                  className="form-control reset"
                  type="text"
                  placeholder="Plumber Name/Mobile"
                  value={searchUser}
                  onChange={handleSearch}
                  onKeyDown={handleKeyDown}
                  disabled={disRetName !== "" || group !== ""}
                />
              </div>
            </div>
            <div className="col-md-3 col-xs-6">
              <div className="form-group">
                <input
                  className="form-control reset"
                  type="text"
                  placeholder="Search by Distributor/Retailer"
                  value={disRetName}
                  onChange={handleDisRet}
                  disabled={searchUser !== "" || group !== ""}
                />
              </div>
            </div>
            <div className="col-md-3 col-xs-6">
              <div className="form-group">
                <select
                  className="form-control"
                  value={group}
                  onChange={handleGroup}
                  disabled={searchUser !== "" || disRetName !== ""}
                >
                  <option value="" selected>
                    Select group wise
                  </option>
                  <option value="GROUP 1">Group 1</option>
                  <option value="GROUP 2">Group 2</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary btn-search"
                  onClick={handleSearchButtonClick}
                  disabled={
                    searchUser === "" && disRetName === "" && group === ""
                  }
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          {searchUser !== "" && searchRecord === null ? (
            <div className="no-record">
              <p>No record found!</p>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            {results &&
              results.map((item, index) => {
                return (
                  <>
                    <div className="col-md-12" key={index}>
                      <div className="row custmerdetails">
                        <div className="col-md-4">
                          <div className="userbox">
                            <p>
                              <span>Name</span> : {item.name}
                            </p>
                            <p>
                              <span>Mobile No.</span> : {item.mobile}
                            </p>
                            <p>
                              <span>State</span> : {item.addressWork?.workState}
                            </p>
                            <p>
                              <span>City</span> : {item.addressWork?.workCity}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-2 col-xs-12">
                              <div className="kpibox">
                                <div className="upperkpi h134">
                                  <div className="namekpi">Total Points</div>
                                </div>
                                <div className="kpilink">
                                  {item.totalPoints}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-xs-12">
                              <div className="kpibox">
                                <div className="upperkpi h134">
                                  <div className="namekpi">Points</div>
                                </div>
                                <div className="kpilink">{item.points}</div>
                              </div>
                            </div>
                            <div className="col-md-3 col-xs-12">
                              <div className="kpibox">
                                <div className="upperkpi h134">
                                  <div className="namekpi">
                                    Redemption Points
                                  </div>
                                </div>
                                <div className="kpilink">
                                  {item.redeemPoints}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-xs-12">
                              <div className="kpibox">
                                <div className="upperkpi h134">
                                  <div className="namekpi">
                                    Hold Points
                                  </div>
                                </div>
                                <div className="kpilink">
                                  {item.deductPoints}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-xs-12">
                              <div className="kpibox">
                                <div className="upperkpi h134">
                                  <div className="namekpi">
                                    TDS Deducted
                                  </div>
                                </div>
                                <div className="kpilink">
                                  {item.tdsPct}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          {showTable === true ? (
            <div className="row mt-20">
              <div className="col-md-12">
                <div className="table-responsive table--no-card">
                  <table className="table table-borderless table-striped table-earning">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Plumber Name</th>
                        <th>Plumber Number </th>
                        <th>Distributor Name</th>
                        <th>Retailer Name</th>
                        <th>Points Earned </th>
                      </tr>
                    </thead>
                    {searchRecord === null ? (
                      <tbody>
                        <tr>
                          <td
                            colSpan="7"
                            style={{ textAlign: "center", fontSize: "16px" }}
                          >
                            No record found!
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {disRetGroupData &&
                          disRetGroupData.map((item, index) => {
                            const serialNumber =
                              (currentPage - 1) * limit + index + 1;
                            return (
                              <>
                                <tr key={index}>
                                  <td>{serialNumber}</td>
                                  <td>{item.user?.name}</td>
                                  <td>{item.user?.mobile}</td>
                                  <td>{item.distributerName}</td>
                                  <td>{item.retailerName}</td>
                                  <td>{item.pointsEarned}</td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    )}
                  </table>
                  {totalPages > 1 && (
                    <ResponsivePagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
}
