import React, { useState, useEffect } from "react";
import Navbar from "../../common/Navbar";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import Oval from "../loader/CircleLoade";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
export default function Profile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [profileDate, setProfileDate] = useState({});

  useEffect(() => {
    profileReq();
  }, []);

  const profileReq = async () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      try {
        await axios
          .post(
            Constants.baseUrl + `auth/profile/get`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 10001) {
                setProfileDate(res.data.output);
                setLoading(false);
              } else if (res.data.code === 12002) {
                setLoading(false);
              } else {
                swal({
                  text: res.data.msg,
                  // icon: 'error',
                  timer: 2000,
                  buttons: false,
                });
              }
            } else {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section class="page-content--bge55">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <h3 class="head2">Profile Details</h3>
            </div>
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>First Name*</label>
                        <input
                          class="form-control"
                          value={profileDate.name}
                          type="text"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Last Name</label>
                        <input
                          class="form-control"
                          value=""
                          type="text"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Mobile Number*</label>
                        <input
                          class="form-control"
                          type="text"
                          value={profileDate.mobile}
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Email</label>
                        <input
                          class="form-control"
                          type="text"
                          value={profileDate.email}
                          disabled
                        />
                      </div>
                    </div>
										{localStorage.getItem("user_type") == 'RETAILER' &&
										<>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>State*</label>
                        <input
                          class="form-control"
                          type="text"
                          value={profileDate.addressWork?.workState}
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Firm Name</label>
                        <input class="form-control" type="text" value={profileDate?.firmName} disabled />
                      </div>
                    </div>
										</>
										}
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>DMS Id</label>
                        <input class="form-control" type="text" value={profileDate?.merchantId} disabled />
                      </div>
                    </div>
										{localStorage.getItem("user_type") == 'RETAILER' &&
										<>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Address 1*</label>
                        <textarea
                          class="form-control"
                          rows="2"
                          value={profileDate.addressWork?.workAddress1}
                          disabled
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Address 2</label>
                        <textarea
                          class="form-control"
                          rows="2"
                          value={profileDate.addressWork?.workAddress1}
                          disabled
                        ></textarea>
                      </div>
                    </div>
										</>
										}
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>City*</label>
                        <input
                          class="form-control"
                          type="text"
                          value={profileDate.addressWork?.workCity}
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Pincode*</label>
                        <input
                          class="form-control"
                          value={profileDate.addressWork?.workPincode}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
