import React from "react";
import Navbar from "../../../common/Navbar";

export default function Tnc() {
  return (
    <>
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3 className="head2 text-center">Terms & Conditions</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card" style={{ padding: "14px" }}>
                <ul className="tnclist">
                  <li>
                    {" "}
                    This Retailer Loyalty Program belongs to Sintex BAPL
                    Limited, a part of the Welspun Group, for its Water Storage
                    Tanks business.
                  </li>
                  <li> This program will be effective from 01 July 2023.</li>
                  <li>
                    {" "}
                    The program is open for enrolment for Retailers who buy
                    directly from the assigned Distributor.
                  </li>
                  <li>
                    {" "}
                    To qualify for rewards accrual and redemption, the retailer
                    must be registered and verified in our system.
                  </li>
                  <li>
                    {" "}
                    Enrolling for this Program by submitting the duly filled and
                    signed verification documents to Sintex is mandatory and
                    will be deemed as acceptance of these terms and conditions
                  </li>
                  <li>
                    {" "}
                    This Retailer Loyalty Program is only applicable for Retail
                    sales made by the company to the distributor. Please contact
                    your Sintex representative for a detailed clarification.
                  </li>
                  <li>
                    {" "}
                    Only purchases made from the assigned distributor would be
                    considered for benefits under this program.
                  </li>
                  <li>
                    {" "}
                    Retailers have to ensure that billing should be done through
                    Sintex Shikhar DMS as rewards will be considered only for
                    sales done through Sintex Shikhar DMS from their associated
                    Distributor.
                  </li>
                  <li>
                    {" "}
                    Any related party or sister concern of a distributor, or own
                    firm of distributor cannot be part of this scheme. The
                    assessment of Sintex Management in this regard will be final
                    and binding to all channel partners.
                  </li>
                  <li>
                    {" "}
                    CWUG (FRP tanks), HD, LIDs or any other products which are
                    not mentioned will not be considered in the loyalty program.
                  </li>
                  <li>
                    {" "}
                    Sintex may assign targets from time to time for eligible
                    retailers to qualify and earn rewards.
                  </li>
                  <li>
                    {" "}
                    Misrepresentation and wrong information will result in
                    permanent disqualification, from the program and schemes run
                    by Sintex.{" "}
                  </li>
                  <li>
                    {" "}
                    The company shall deduct TDS on the equivalent value of all
                    rewards in accordance with Section 194R of the Income Tax
                    Act 1961. Retailer is responsible to provide Permanent
                    Account Number (PAN) details.
                  </li>
                  <li>
                    {" "}
                    Neither this program, nor its Terms and Conditions shall be
                    construed as distributorship, joint venture, agency
                    relationship or as granting of franchisee.
                  </li>
                  <li>
                    {" "}
                    Sintex reserves the right to disqualify any Retailer from
                    the program and any other Company or agency run program,
                    without assigning any reason / intimation / justification
                    thereof.{" "}
                  </li>
                  <li>
                    {" "}
                    Sintex reserves the right for the computation of the reward
                    that shall be final, conclusive and binding on the Retailer
                    and will not be liable to be disputed or challenged.
                  </li>
                  <li>
                    {" "}
                    Sintex or its agency is not responsible for delays in
                    courier / post for the claims / documentation to be received
                    as part of the KYC formalities.
                  </li>
                  <li> Benefits under the program are non-transferable.</li>
                  <li>
                    There is no upper limit on the accumulation of the reward
                    points and the accumulation is correlated to the purchase of
                    Sintex WST products.
                  </li>
                  <li>
                    {" "}
                    Validity of Rewards: Balance rewards available in retailer
                    account on 30 June 2023 would be valid for a period of three
                    years. All rewards accrued to retailer account w.e.f. 01
                    July 2023 would be valid for three years from the date of
                    accrual.
                  </li>
                  <li>
                    {" "}
                    The Reward Points can be redeemed in full or in part.
                    Retailer can redeem up to 80% of the accumulated reward
                    points at any stage of the program.
                  </li>
                  <li>
                    {" "}
                    Taxes and Insurance, along with documents and other
                    expenses, will have to be borne by the retailer.
                  </li>
                  <li>
                    {" "}
                    All issues, concerns and feedbacks pertaining to invoice
                    entries, rewards earned, rewards balance or delivery of
                    rewards should be raised to helpline.{" "}
                  </li>
                  <li>
                    {" "}
                    Trip eligibility would be considered based on qualified and
                    fully paid billing done during the scheme period. Switching
                    of destination or any other change will not be allowed.
                  </li>
                  <li>
                    {" "}
                    Images shown are only for representation. It is the
                    responsibility of the retailer to obtain visa. Company will
                    not be liable for any delay or rejection of visa
                    application.{" "}
                  </li>
                  <li>
                    {" "}
                    Sintex holds all rights to change and amend the scheme
                    without prior information.
                  </li>
                  <li>
                    {" "}
                    In case of any conflict, the company's decision will be
                    final and binding. All disputes related to the same will be
                    resolved as per the jurisdiction of Kalol Court (North
                    Gujarat).
                  </li>
                  <li>
                    {" "}
                    Only retailers which have been added, verified and billed in
                    system till 30th September 2023 will be valid for the mega
                    loyalty program with mandatory billing required in all
                    subsequent months till March 2024.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
