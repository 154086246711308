import React, { useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from "../../../common/Navbar";
export default function RetailerDashboard() {

	const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [faqsurl, setFaqsurl] = useState("");
	const [tncurl, setTncurl] = useState("");
  const [schemeurl, setSchemeurl] = useState("");
  const [rewardurl, setRewardurl] = useState("");
  const [evgurl, setEvgurl] = useState("");
  const [dashboardData, setDashboardData] = useState({});

	useEffect(() => {
    getDashboardData();
    getFaqsData();
    getEVGUrl();
  }, []);

  const getDashboardData = async () => {
    if (!token) {
      navigate("/");
    } else {
      await axios
        .get(
          `${Constants.baseUrl}purchase/purchaseHistory/retailer`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program2,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setDashboardData(dt?.output);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setDashboardData({});
          }
        })
        .catch((err) => {});
    }
  };

  const getFaqsData = async () => {
    if (!token) {
      navigate("/");
    } else {
      await axios
        .get(
          `${Constants.baseUrl}program/child/2`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              // appVersion: Constants.av,
              // platform: Constants.pt,
              // company: Constants.company,
              // program: Constants.program,
            },
          },
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setFaqsurl(dt?.output?.faqs);
						setTncurl(dt?.output?.tnc);
            setSchemeurl(dt?.output?.catelog);
            setRewardurl(dt?.output?.manual);
          }
          
        })
        .catch((err) => {});
    }
  };

  const getEVGUrl = async () => {
    if (!token) {
      navigate("/");
    } else {
      await axios
        .post(
          `${Constants.baseUrl}web/evg/view_url`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          },
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setEvgurl(dt?.output?.url);
          }
          
        })
        .catch((err) => {});
    }
  };

  return (
    <>
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 left-menu">
              <div className="item ">
                <p className="top-title">TOTAL EARNED INCENTIVE:</p>
                <ul>
                  <li>
                    <p> Opening &#39;Locked&#39; Rewards on 30 - Nov - 22</p>
                    <div className="row">
                      <div className="col-lg-6">&nbsp;</div>
                      <div className="col-lg-6">
                        <span className="button-style2">Rs. {dashboardData?.lockedPoint} </span>
                      </div>
                    </div>
                  </li>
                  <hr className="separatehr" />
                  <li>
                    <p>
                      Earned &#39;Unlocked&#39; Rewards w.e.f. 01 - Dec - 22
                    </p>
                    <div className="row">
                      <div className="col-lg-6">&nbsp;</div>
                      <div className="col-lg-6">
                        <span className="button-style2">Rs. {dashboardData?.earnedPointCurentMonth} </span>
                      </div>
                    </div>
                  </li>
                  <hr className="separatehr" />
                  <li>
                    <p>TDS u/ s 194R w.e.f. 01 - Dec - 22</p>
                    <div className="row">
                      <div className="col-lg-6">&nbsp;</div>
                      <div className="col-lg-6">
                        <span className="button-style2">Rs. {dashboardData?.onTdsPct} </span>
                      </div>
                    </div>
                  </li>
                  <hr className="separatehr" />
                  <li>
                    <p>Redeemed w.e.f. 01 - Dec - 22</p>
                    <div className="row">
                      <div className="col-lg-6">&nbsp;</div>
                      <div className="col-lg-6">
                        <span className="button-style2">Rs. {dashboardData?.redeemed} </span>
                      </div>
                    </div>
                  </li>
                  <hr className="separatehr" />
                  <li>
                    <p>Total &#39;Locked + Unlocked &#39; Rewards Balance </p>
                    <div className="row">
                      <div className="col-lg-6">&nbsp;</div>
                      <div className="col-lg-6">
                        <span className="button-style2">Rs. {dashboardData?.totalLockedULocked}</span>
                      </div>
                    </div>
                  </li>

                  <hr className="separatehr" />
                  <li>
                    <p>Redeemable in current month</p>
                    <div className="row">
                      <div className="col-lg-6">&nbsp;</div>
                      <div className="col-lg-6">
                        <span className="button-style2">Rs. {dashboardData?.reedeemableCurentMonth} </span>
                      </div>
                    </div>
                  </li>
                  <hr className="separatehr" />
                  <li>
                    <p>Rewards Earned in Current Month (Including TDS)</p>
                    <div className="row">
                      <div className="col-lg-6">&nbsp;</div>
                      <div className="col-lg-6">
                        <span className="button-style2">Rs. {dashboardData?.cuurentMonthTds} </span>
                      </div>
                    </div>
                  </li>
                </ul>
                {/* <Link
                  to="/changepassword"
                  className="btn btn-primary"
                  type="button"
                >
                  Change Password
                </Link> */}
              </div>
            </div>

            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="head1">Dashboard</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/Purchase_History">
                      <span>
                        <i className="fa fa-file-text-o"></i>
                      </span>
                      <p>Purchase History</p>
                    </Link>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/RewardPoints">
                      <span>
                        <i className="fa fa-star"></i>
                      </span>
                      <p>Reward Points</p>
                    </Link>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link
                      to={evgurl}
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-trophy"></i>
                      </span>
                      <p>Redeem Electronic Gift Voucher</p>
                    </Link>
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/VoucherRedemptionHistory">
                      <span>
                        <i className="fa fa-history"></i>
                      </span>
                      <p>Discount Voucher Redemption History</p>
                    </Link>
                  </div>
                </div> */}

                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/redemption_history">
                      <span>
                        <i className="fa fa-history"></i>
                      </span>
                      <p>Voucher Redemption History</p>
                    </Link>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/order_indent">
                      <span>
                        <i className="fa fa-shopping-cart"></i>
                      </span>
                      <p>Order Indent</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/videos">
                      <span>
                        <i className="fa fa-video-camera"></i>
                      </span>
                      <p>Videos</p>
                    </Link>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/PlumberList">
                      <span>
                        <i className="fa fa-user"></i>
                      </span>
                      <p>Manage Plumber</p>
                    </Link>
                  </div>
                </div> */}

                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link
                      to={schemeurl} target="_blank"
                      title="Schemes &amp; Promotions"
                    >
                      Schemes &amp; Promotions
                    </Link>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to={rewardurl} target="_blank">
                      <span>
                        <i className="fa fa-gift"></i>
                      </span>
                      <p>Reward Matrix</p>
                    </Link>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/feedback_support">
                      <span>
                        <i className="fa fa-phone-square"></i>
                      </span>
                      <p>Feedback / Support</p>
                    </Link>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
										{/* <Link to="/faq"> */}
                    <Link to={faqsurl} target="_blank">
                      <span>
                        <i className="fa fa-question-circle"></i>
                      </span>
                      <p>FAQs</p>
                    </Link>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
										{/* <Link to="/tnc"> */}
                    <Link to={tncurl} target="_blank">
                      <span>
                        <i className="fa fa-file"></i>
                      </span>
                      <p>Terms &amp; Conditions</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/makewish">
                      <span>
                        <i className="fa fa-magic"></i>
                      </span>
                      <p>Make a Wish</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/make_a_wish_history">
                      <span>
                        <i className="fa fa-circle-o-notch"></i>
                      </span>
                      <p>Make a Wish History</p>
                    </Link>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/upload_shop_image_rate">
                      <span>
                        <i className="fa fa-picture-o"></i>
                      </span>
                      <p>Display Incentive</p>
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
