import React, { useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../../loader/CircleLoade";
import { Link } from "react-router-dom";

import Navbar from "../../../common/Navbar";
export default function UploadShopImageRet() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [uploadShopImage, setUploadShopImage] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getUploadShopImage(1);
  }, []);

  const searchFormik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getUploadShopImage(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getUploadShopImage = async (pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      // let stDate;
      // let endDate;

      // var momentObj1 = moment(data.startDate);
      // var momentObj2 = moment(data.endDate);
      // if (data.startDate && data.endDate) {
      //   var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
      //   var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
      //   stDate = `&startDate=${startFormattedDate}`;
      //   endDate = `&enddate=${startFormattedDate4}`;
      // } else {
      //   stDate = "";
      //   endDate = "";
      // }

      await axios
        .get(
          Constants.baseUrl +
            `display/retailer/image?sortAsc=true&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          //console.log(res);
          const dt = res.data;
          if (dt.result === "success") {
            setUploadShopImage(dt?.output);
            //setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setUploadShopImage(null);
            //setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const areAllValuesBlank = () => {
    const { startDate, endDate } = searchFormik.values;
    return !startDate && !endDate;
  };

  return (
    <>
			{loading ? <Oval /> : null}
      <Navbar />
      <section class="page-content--bge55">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3 col-xs-6 text-left">
                  <h3 class="head2">Photo List</h3>
                </div>
                {/* <div class="col-md-3 col-md-offset-6 col-xs-6 text-right">
									<div class="form-group">
										<a class="btn btn-primary" href="javascript:void(0)" id="idRetailerExportExcel">
											Export
										</a>
									</div>
								</div> */}
              </div>
              {/* <div class="row">
								<div class="col-md-3 col-xs-6 text-right">
									<div class="form-group">
										<input class="form-control" type="text" placeholder="Search Retailer" id="txtSearch" />
									</div>

								</div>
								<div class="col-md-3">
									<div class="form-group">

										<button type="button" class="btn btn-primary btn-sbmit" id="btnSearch">Search</button>
									</div>
								</div>
								<div class="col-md-3 col-md-offset-3  text-right">
									<div class="form-group">
										<p> Total Count:0</p>
									</div>
								</div>
							</div> */}
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive table--no-card">
                    <table
                      class="table table-borderless table-striped table-earning"
                      id="datatable"
                    >
                      <thead>
                        <tr>
                          <th>S No.</th>
                          {/* <th>Retailer Code</th>
                          <th>Name</th>
                          <th>Mobile</th> */}
                          <th>Shop Image</th>
                          <th>Status</th>
                          <th>Date Of Upload</th>
													<th>Date Of Approval/Rejection</th>
                        </tr>
                      </thead>
                      {uploadShopImage === null ? (
                        <tbody>
                          <tr>
                            <td
                              colSpan="6"
                              style={{ textAlign: "center", fontSize: "16px" }}
                            >
                              No record found!
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {uploadShopImage &&
                            uploadShopImage.length > 0 &&
                            uploadShopImage.map((row, index) => {
                              const serialNumber =
                                (currentPage - 1) * limit + index + 1;

                              // const invoiceDate = purchase.invoiceDate
                              //   ? moment(purchase.invoiceDate).format(
                              //       "DD-MM-YYYY"
                              //     )
                              //   : "";
                              return (
                                <>
                                  <tr key={index}>
                                    <td>{serialNumber}</td>
                                    {/* <td>{row?.userId}</td>
                                    <td>{row?.name}</td>
                                    <td>{row?.mobile}</td> */}
                                    <td>
																			{row?.url && <img src={row.url} alt="Shop Image" height={'30px'}/>}
                                    </td>
                                    <td>{row?.status}</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
