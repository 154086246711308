import React, { useEffect, useState } from 'react';
import Navbar from '../../common/Navbar';
import * as Constants from "../../../Constants/index";
import axios from "axios";
import ResponsivePagination from "react-responsive-pagination";
import swal from "sweetalert";

const RetailerRedemptionReport = () => {
    const token = localStorage.getItem("token");
    const [getData, setGetData] = useState([]); // Initialize as empty array
    const [currentPage, setCurrentPage] = useState(1); // Page state for pagination
    const [totalPages, setTotalPages] = useState(1); // Total pages
    const [filterData, setFIlterData] = useState('');

    const fetchApi = async (page) => {
        const mobileFilter = filterData ? `&mobile=${filterData}` : ""
        try {
            let data = await axios.get(
                Constants.baseUrl +
                `reports/retailerTotalRedemption/list?sortBy=createDateTime&sortAsc=true&limit=20&page=${page}${mobileFilter}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    },
                }
            );
            console.log(data.data.output.results);
            setGetData(data.data.output.results || []); // Ensure results is an array
            setTotalPages(data.data.output.totalPages || 1); // Assuming totalPages is provided in response
        } catch (error) {
            console.log(error);
            setGetData([]); // In case of error, set empty array to avoid null issues
        }
    };

    useEffect(() => {
        fetchApi(currentPage);
    }, [currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <Navbar />
            <section className="page-content--bge55">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="head1">Retailer Redemption History</h3>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <input type='text' placeholder='Search By Mobile Number' value={filterData} onChange={(e) => setFIlterData(e.target.value)} />
                            <button style={{ marginLeft: "10px" }} onClick={() => fetchApi(currentPage)}>Search</button> 
                        </div>
                        <div className="table-responsive">
                            <table className="table table-borderless table-striped table-earning">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Redemption Id</th>
                                        <th>Retailer Code</th>
                                        <th>Retailer Name</th>
                                        <th>Mobile</th>
                                        <th>Zone</th>
                                        <th>State</th>
                                        <th>Work District</th>
                                        <th>Branch</th>
                                        <th>City</th>
                                        <th>Date of Request</th>
                                        <th>Date of Joining</th>
                                        <th>Sales Team Name</th>
                                        <th>Total Redeemed Stars</th>
                                        <th>Redemption Request Date</th>
                                        <th>Redemption Process Date</th>
                                        <th>Redemption Details</th>
                                        <th>EVG Product Code</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                        <th>GST No</th>
                                        <th>PAN Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        getData.length > 0 ? (
                                            getData.map((val, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{val[0]}</td>
                                                    <td>{val[1]}</td>
                                                    <td>{val[2]}</td>
                                                    <td>{val[3]}</td>
                                                    <td>{val[4]}</td>
                                                    <td>{val[5]}</td>
                                                    <td>{val[6]}</td>
                                                    <td>{val[7]}</td>
                                                    <td>{val[8]}</td>
                                                    <td>{val[9]}</td>
                                                    <td>{val[10]}</td>
                                                    <td>{val[11]}</td>
                                                    <td>{val[12]}</td>
                                                    <td>{val[13]}</td>
                                                    <td>{val[14]}</td>
                                                    <td>{val[15]}</td>
                                                    <td>{val[16]}</td>
                                                    <td>{val[17]}</td>
                                                    <td>{val[18]}</td>
                                                    <td>{val[19]}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="21">No data available</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="card-footer">
                            <ResponsivePagination
                                current={currentPage}
                                total={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RetailerRedemptionReport;
