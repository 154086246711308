import React, { useState, useEffect } from 'react'
import Navbar from '../../../common/Navbar'
import * as Constants from '../../../../Constants/index'
import axios from 'axios'
import Oval from '../../loader/CircleLoade';
import watertank from '../../../../assets/img/sin.png'
import videoicon from '../../../../assets/img/Group 3207.png'
import bookicon from '../../../../assets/img/bk.png'
import activeProduct from '../../../../assets/img/Group 3208.png'
import swal from 'sweetalert';
export default function InfoDesk() {
    const [loading, setLoading] = useState(false)
    const [programData, setProgramData] = useState({})

    useEffect(() => {
        programChildReq()
    }, [])


    const programChildReq = async () => {
        setLoading(true)
        let token = localStorage.getItem('token');

        try {
            await axios.get(Constants.baseUrl + `program/child/1`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                }
            }).then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 10001) {
                        setLoading(false)
                        setProgramData(res.data.output)
                    } else if (res.data.code === 12002) {
                        setLoading(false)
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                } else {
                    swal({
                        text: res.data.msg,
                        // icon: 'error',
                        timer: 2000,
                        buttons: false,
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }




    return (
        <>
            {loading ? <Oval /> : null}
            <Navbar />
            <section className="page-content--bge55">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="head2">Info Desk</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 col-xs-12">
                            <div className="kpibox">
                                <div className="upperkpi h134">
                                    <div className="namekpi">
                                        <img src={watertank} />
                                    </div>
                                </div>
                                <div className="kpilink" style={{ textTransform: 'none' }}>
                                    <a href={programData?.broucher} target="_blank" style={{ textTransform: 'none', color: '#1c9d9d' }}> Sintex<br />  Water Tank Brochure </a>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3 col-xs-12">
                            <div className="kpibox">
                                <div className="upperkpi h134">
                                    <div className="namekpi">
                                        <img src={videoicon} />
                                    </div>
                                </div>
                                <div className="kpilink" style={{ textTransform: 'none' }}>
                                    <a href="https://www.youtube.com/@SintexBAPLLimited" target='_blank' style={{ textTransform: 'none', color: '#1c9d9d' }}>Sintex <br /> Product Videos </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-12">
                            <div className="kpibox">
                                <div className="upperkpi h134">
                                    <div className="namekpi">
                                        <a href="#" style={{ textTransform: 'none', color: '#1c9d9d' }}> <img src={bookicon} /> </a>
                                    </div>
                                </div>
                                <div className="kpilink" style={{ textTransform: 'none' }}>
                                    <a href={programData.manual} target='_blank' style={{ textTransform: 'none', color: '#1c9d9d' }}>Program <br /> Manual</a>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-3 col-xs-12">
                            <div className="kpibox">
                                <div className="upperkpi h134">
                                    <div className="namekpi">
                                        <img src={activeProduct} />
                                    </div>
                                </div>
                                <div className="kpilink">
                                    <a href={programData.catelog} target='_blank' style={{ textTransform: 'none', color: '#1c9d9d' }}>List Of <br /> Eligible Products </a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
