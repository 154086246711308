import React from "react";
import logo from "../../assets/img/logo-new.png";
import { Link, useNavigate } from "react-router-dom";
export default function Navbar() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const category = localStorage.getItem("category");

  // const renderBackButton =
  //   ((userType != "SALES" || userType != "RETAILER") && category == "influencer") ||
  //   ((userType != "SALES" || userType != "RETAILER") && category == "retailer");

  const renderBackButton =
    (userType !== "RETAILER" &&
      category === "influencer") ||
    (userType !== "RETAILER" &&
      category === "retailer");

  const handleBackButtonClick = () => {
    //localStorage.setItem("category", "");
    //navigate("/category2");
    if(category === "influencer") {
      navigate("/influencer_dashboard");
    } else if(category === "retailer") {
      navigate("/sales_retailer_Dashboard");
    } else {
      navigate("/category2");
    }
  };
  const handleLogout = () => {
    localStorage.clear();
  };
  return (
    <>
      <nav class="navbar navbar-default">
        <div class="container">
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="true"
              aria-controls="navbar"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <Link
              class="navbar-brand"
              to={userType !== "RETAILER" ? "/category2" : userType === "RETAILER" ? "/retailer_Dashboard" : "/"}
            >
              <img src={logo} alt="" />
            </Link>
          </div>
          <div
            id="navbar"
            class="navbar-collapse collapse"
            aria-expanded="true"
          >
            <ul class="nav navbar-nav navbar-right">
              <li>
                <Link to={userType !== "RETAILER" ? "/category2" : userType === "RETAILER" ? "/retailer_Dashboard" : "/"}>
                  Home
                </Link>
              </li>

              {renderBackButton && (
                <li onClick={handleBackButtonClick}>
                  {" "}
                  <Link to="/category2">Back to Main Menu</Link>
                </li>
              )}
              <li>
                <Link to="/about">About</Link>
              </li>
              {(userType === "SALES" && !category) ||
              userType === "RETAILER" ? (
                <>
                  {userType === "SALES" && (
                    <li>
                      <Link to="/sintex_influencer">Sintex Plumber</Link>
                    </li>
                  )}
                  <li>
                    <Link to="/sintex_hamesha">Sintex Hamesha</Link>
                  </li>
                </>
              ) : userType === "SALES" && category === "influencer" ? (
                <li>
                  <Link to="/sintex_influencer">Sintex Plumber</Link>
                </li>
              ) : userType === "SALES" && category === "retailer" ? (
                <li>
                  <Link to="/sintex_hamesha">Sintex Hamesha</Link>
                </li>
              ) : (
                ""
              )}
              <li>
                <Link to="/eligible_products">Eligible Products</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              {userType === "SALES" || userType === "RETAILER" ? (
                <li>
                  <Link to="/profile">My Profile</Link>
                </li>
              ) : (
                ""
              )}

              {userType && (
                <li>
                  <p class="ussrnme">
                    {localStorage.getItem("user_name")}{" "}
                    {localStorage.getItem("user_mobile")}
                  </p>
                  <Link to="/" class="signout" onClick={handleLogout}>
                    <i _ngcontent-c1="" class="fa fa-sign-out text-danger"></i>
                    Log Out
                  </Link>
                </li>
              )}

              {/* {userType === "SALES" || userType === "RETAILER" ? (
								<li>
									<p class="ussrnme">
										{localStorage.getItem("user_name")}{" "}
										{localStorage.getItem("user_mobile")}
									</p>
									<Link to="/" class="signout" onClick={handleLogout}>
										<i
											_ngcontent-c1=""
											class="fa fa-sign-out text-danger"
										></i>
										Log Out
									</Link>
								</li>
							) : (
								""
							)} */}
            </ul>

            {/* {userType === "HO" ? (
              <ul class="nav navbar-nav navbar-right">
                <li>
                  <Link to="/influencer_dashboard">Home</Link>
                </li>
                <li>
                  <Link to="/eligible_products">Eligible Products</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                {userType === "SALES" ||
                userType === "RETAILER" ||
                userType === "HO" ? (
                  <li>
                    <p class="ussrnme">
                      {localStorage.getItem("user_name")}{" "}
                      {localStorage.getItem("user_mobile")}
                    </p>
                    <Link to="/" class="signout" onClick={handleLogout}>
                      <i
                        _ngcontent-c1=""
                        class="fa fa-sign-out text-danger"
                      ></i>
                      Log Out
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            ) : (
              <ul class="nav navbar-nav navbar-right">
                <li>
                  <Link
                    to={
                      userType === "SALES" && category === "influencer"
                        ? "/influencer_dashboard"
                        : userType === "SALES" && category === "retailer"
                        ? "/sales_retailer_Dashboard"
                        : userType === "SALES"
                        ? "/category2"
                        : "/"
                    }
                  >
                    Home
                  </Link>
                </li>

                {renderBackButton && (
                  <li onClick={handleBackButtonClick}>
                    {" "}
                    <Link to="/category2">Back to Main Menu</Link>
                  </li>
                )}
                <li>
                  <Link to="/about">About</Link>
                </li>
                {(userType === "SALES" && !category) ||
                userType === "RETAILER" ? (
                  <>
                    {userType === "SALES" && (
                      <li>
                        <Link to="/sintex_influencer">Sintex Plumber</Link>
                      </li>
                    )}
                    <li>
                      <Link to="/sintex_hamesha">Sintex Hamesha</Link>
                    </li>
                  </>
                ) : userType === "SALES" && category === "influencer" ? (
                  <li>
                    <Link to="/sintex_influencer">Sintex Plumber</Link>
                  </li>
                ) : userType === "SALES" && category === "retailer" ? (
                  <li>
                    <Link to="/sintex_hamesha">Sintex Hamesha</Link>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <Link to="/eligible_products">Eligible Products</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                {userType === "SALES" || userType === "RETAILER" ? (
                  <li>
                    <Link to="/profile">My Profile</Link>
                  </li>
                ) : (
                  ""
                )}
                {userType === "SALES" || userType === "RETAILER" ? (
                  <li>
                    <p class="ussrnme">
                      {localStorage.getItem("user_name")}{" "}
                      {localStorage.getItem("user_mobile")}
                    </p>
                    <Link to="/" class="signout" onClick={handleLogout}>
                      <i
                        _ngcontent-c1=""
                        class="fa fa-sign-out text-danger"
                      ></i>
                      Log Out
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            )} */}
          </div>
        </div>
      </nav>
    </>
  );
}
