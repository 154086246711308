import React from 'react'
import Navbar from '../../../common/Navbar'
import { Link } from 'react-router-dom'
export default function PlumberList() {
    return (
        <>
            <Navbar />
            <section className="page-content--bge55">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-3 col-xs-6 text-right">
                                    <div className="form-group">
                                        <input className="form-control" type="text" placeholder="Search Plumber" id="txtSearch" />
                                    </div>

                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">

                                        <button type="button" className="btn btn-primary btn-sbmit" id="btnSearch">Search</button>
                                    </div>
                                </div>

                                <div className="col-md-3 col-md-offset-3  text-right">
                                    <div className="form-group">
                                        <Link className="btn btn-primary btn-green" to="/add_plumber">
                                            <i className="fa fa-plus"></i> Add New Plumber
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive table--no-card">
                                        <table className="table table-borderless table-striped table-earning" id="datatable">
                                            <thead>
                                                <tr>
                                                    <th>Sr No</th>
                                                    <th>User Id</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                    <th>State</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
