import React from "react";
import Navbar from "../../common/Navbar";
import sintex_hamesha from "../../../assets/img/sintex-hamesha.jpg";
export default function SintexHamesha() {
  return (
    <>
      <Navbar />
      <section className="">
        <div className="container-fluid">
          <div className="inner-sec">
            <div className="row">
              <div className="col-md-12">
                <figure className="abbnt1">
                  <img src={sintex_hamesha} alt="" />
                </figure>
              </div>
              <div className="col-md-12">
                {/* <p className="bdd text-center">Sintex Hamesha</p> */}
                <p className="bdd text-center">
                  SintexHameshaRetailer Loyalty Program
                </p>
              </div>
              <div className="col-md-12">
                {/* <p className="fnt16">
                  To reinforce the brand supremacy in the domestic market and
                  consolidate its leadership position, the company has
                  introduced the Sintex Hamesha 2.0 Retailer Loyalty Program for
                  its trade partners who buy Sintex WST Products through its
                  authorized Distributors. As a Sintex Hamesha 2.0 Retailer
                  member, you are entitled for loyalty rewards on all the
                  eligible product purchases only from authorized distributors.
                  The loyalty points will be auto-credited to your account only
                  when your distributor is billing you these products using
                  Sintex DMS (Retailer Management System) and the billing
                  information seamlessly flows into our IT Systems.
                </p> */}

                <p className="fnt16">
                  To reinforce the brand supremacy in the domestic market and
                  consolidate its leadership position, the company has
                  introduced the SintexHameshaRetailer Loyalty Program for its
                  trade partners (sub dealers and dealers) who buySintex
                  Products through its authorized distributors. As a
                  SintexHamesha Retailer member, you are entitled for loyalty
                  points and rewards on all the eligible product purchases only
                  from authorized distributors. The loyalty points will be
                  auto-credited to your account only when your distributor is
                  billing you the products using Sintex DMS(Retailer Management
                  System) and the billing information seamlessly flows into our
                  IT Systems.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
