import React, { useEffect, useState } from "react";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from "../../common/Navbar";
import Modal from "react-modal";
import { height, width } from "@fortawesome/free-solid-svg-icons/fa0";


export default function ProductPurchaseRequest() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [productPurchase, setProductPurchase] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataID , setDataID] = useState(0)
  const [status, setStatus] = useState(""); 
  const [reason , setReason] = useState('')
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: "400px",
      width: "400px",
      display: "flex",
      justifyContent: "center", // Align items horizontally
      alignItems: "center", // Align items vertically
      flexDirection: "column", // Set direction to column (vertical layout)
    },
  };
  
  

  useEffect(() => {
    getProductPurchase(
      {
        search: "",
      },
      1
    );
  }, []);

  
  function openModal(id) {
    setIsOpen(true);
    console.log("id....",id)
    setDataID(id)
  }
  function closeModal() {
    setIsOpen(false);
  }

  const updateStatus = async (id) => {
    const payload = {
      status: status,
      reason:reason
    };
    try {
      if (!status) {
        swal("Please Select Status");
        return;
      }
              
      if (status === 'REJECT' && !reason) {
        swal("Reason is required when status is REJECT.");
        return;
    }
  
      // API call to update the status
      await axios.patch(
        Constants.baseUrl + `reports/intent/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
        }
      );
  
      // Status updated successfully, now fetch the updated data
      getProductPurchase(filterData, currentPage);
  
      // Close the modal
      closeModal();
      
      // Optionally, show a success message using sweetalert
      swal("Success!", "Status updated successfully!", "success");
  
    } catch (error) {
      console.log(error);
      swal("Error!", "Something went wrong, please try again later.", "error");
    }
  };
  

  const searchFormik = useFormik({
    initialValues: {
      search: "",
    },
    // validate: (data) => {
    //   let errors = {};
    //   if (data.search === "") {
    //     errors.search = "Please enter name";
    //   }
    //   return errors;
    // },
    onSubmit: (data) => {
      setCurrentPage(1);
      getProductPurchase(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getProductPurchase = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      let url =
        Constants.baseUrl +
        `program/child/product/purchase/sales?sortBy=product&sortAsc=true&limit=${limit}&page=${pageNum}`;

      if (data.search !== "" && data?.search !== undefined) {
        url =
          Constants.baseUrl +
          `program/child/product/purchase/sales?name=${data.search}&sortBy=product&sortAsc=true&limit=${limit}&page=${pageNum}`;
      }

      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          //console.log(res);
          const dt = res.data;
          if (dt.result === "success") {
            setProductPurchase(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setProductPurchase(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const areAllValuesBlank = () => {
    const { startDate, endDate } = searchFormik.values;
    return !startDate && !endDate;
  };

  return (
    <>
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3 className="head2">Order Indent Request</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Purchase Requests</strong>
                </div>
                <div className="card-body">
                  <form onSubmit={searchFormik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-3 col-xs-12 text-right">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search Retailer"
                            id="search"
                            name="search"
                            value={searchFormik.values.search}
                            onChange={(e) => {
                              searchFormik.handleChange({
                                target: {
                                  name: "search",
                                  value: e.target.value,
                                },
                              });
                            }}
                          />
                          {/* {getSearchFormErrorMessage("search")} */}
                        </div>
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary report-submit-button"
                            id="btnSearch"
                            // disabled={areAllValuesBlank()}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive table--no-card">
                        <table className="table table-borderless table-striped table-earning">
                          <thead>
                            <tr>
                              <th> Request Date </th>
                              <th> Retailer Name </th>
                              <th> Retailer Contact No. </th>
                              <th> Retailer ID</th>
                              <th> State </th>
                              <th> City </th>
                              <th> Associated Distributor </th>
                              <th> Product Name </th>
                              <th> Qty </th>
                              <th> Current Status </th>
                              <th>Reason</th>
                              <th> Action </th>
                            </tr>
                          </thead>
                          {productPurchase === null ? (
                            <tbody>
                              <tr>
                                <td
                                  colSpan="9"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  No record found!
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {productPurchase &&
                                productPurchase.length > 0 &&
                                productPurchase.map((row, index) => {
                                  const serialNumber =
                                    (currentPage - 1) * limit + index + 1;

                                  const createdDate = row.createdDateTime
                                    ? moment(row.createdDateTime).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "";
                                  return (
                                    <>
                                      <tr key={index}>
                                        {/* <td>{serialNumber}</td> */}
                                        <td>{createdDate}</td>
                                        <td>{row?.retailerName}</td>
                                        <td>{row?.mobile}</td>
                                        <td>{row?.merchantId}</td>
                                        <td>{row?.workState}</td>
                                        <td>{row?.workCity}</td>
                                        <td>{row?.distributor}</td>
                                        <td>{row?.product}</td>
                                        <td>{row?.qnty}</td>
                                        <td>{row?.status}</td>
                                        <td>{row?.reason}</td>
                                        <td><button onClick={()=>openModal(row?.id)}>Action</button></td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          )}
                        </table> 
                        <Modal
                        style={customStyles}
                          isOpen={modalIsOpen}
                          onRequestClose={closeModal}
                          contentLabel="Example Modal"
                        >
                          <div>
                          <div style={{paddingBottom:"4rem"}}>Select And Change Status</div>
                          <div style={{paddingBottom:"5rem"}}>
                          <select
                                name="status"
                                className="form-control"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="">Select</option>
                                <option value="APPROVED">APPROVED</option>
                                <option value="PENDING">PENDING</option>
                                <option value="REJECT">REJECT</option>
                              </select>
                          </div>
                          {status === 'REJECT' && (
                                <div className="row"
                                style={{paddingBottom:"5rem"}}
                                >
                                    <div className="col-sm-12">
                                        <div className="mx-4 input-group-outline my-2">
                                            Reason
                                        </div>
                                        <div className="mx-4 input-group-outline my-2">
                                            <input className="form-control" type='text' value={reason} onChange={(e) => setReason(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            )}
                          <div style={{paddingBottom:"5rem", display:"flex", justifyContent:"space-between"}}>
                          <button onClick={closeModal}>close</button>
                          <button onClick={()=>updateStatus(dataID)} >Save</button>
                          </div>
                          </div>
                        </Modal>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => {
                              setCurrentPage(page);
                              getProductPurchase(filterData, page);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
