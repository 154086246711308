import React from 'react'
import Navbar from '../../common/Navbar'
import TableInfographic from '../../../assets/img/TableInfographic.jpg'
export default function RewardCalculator() {
    return (
        <>
            <Navbar />
            <section class="page-content--bge55">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="head2">Reward Calculator</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="figure">
                                    <img src={TableInfographic} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
