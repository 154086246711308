import React, { useState, useEffect } from "react";
import Navbar from "../../../common/Navbar";
import Loader from "../../../pages/loader/CircleLoade";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import { useFormik } from "formik";

export default function ProgramPerformance() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const token = localStorage.getItem("token");

  useEffect(() => {
    programPerformance({
			branch: ""
		});
  }, []);

  const programPerformance = async (data) => {
    try {
      setLoading(true);
			const searchBranch =
        data?.branch !== undefined && data?.branch !== ""
          ? `?name=${data?.branch}`
          : "";

      await axios
        .get(Constants.baseUrl + `program/dashboard/sales${searchBranch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            platform: Constants.pt,
            appVersion: Constants.av,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201 && res.data.code === 10001) {
            setData(res.data.output);
            setLoading(false);
          } else {
            setLoading(true);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

	const searchFormik = useFormik({
    initialValues: {
      branch: ""
    },
    validate: (data) => {
      let errors = {};
			if (data.branch === "") {
        errors.branch = "Please Search by Zone and Branch";
      }

      // if (data.mobile) {
      //   if (data.mobile.length < 10) {
      //     errors.mobile = "Mobile must be in 10 digits.";
      //   }
      // }
      // if (data.startDate === "" && data.endDate !== "") {
      //   errors.startDate = "Please select date";
      // }
      // if (data.startDate !== "" && data.endDate === "") {
      //   errors.endDate = "Please select date";
      // }
      // if (data.startDate && data.endDate && data.startDate > data.endDate) {
      //   errors.endDate = "Please select date";
      // }
      // if (data.startDate && data.endDate && data.startDate > data.endDate) {
      //   errors.endDate = "End date should be greater than start date";
      // }
      // if (data.startDate && data.endDate) {
      //     const maxEndDate = new Date(data.startDate)
      //     maxEndDate.setDate(maxEndDate.getDate() + 30) // Adjust the range as needed (e.g., 31 days)

      //     if (data.endDate > maxEndDate) {
      //         errors.endDate = 'End date should be within 31 days from the start date.'
      //     }
      // }
      return errors;
    },
    onSubmit: (data) => {
      programPerformance(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3 className="head1">Program Performance</h3>
            </div>
          </div>
					<form onSubmit={searchFormik.handleSubmit}>
						<div class="row">
							<div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
								<div class="row">
									<div class="col-md-6 col-sm-12 col-xs-12">
										<div class="form-group">
											<input
												class="form-control reset"
												type="text"
												placeholder="Search by Zone and Branch"
												id="branch"
												value={searchFormik.values.branch}
												onChange={(e) => {
													// const numericValue = e.target.value
													// 	.replace(/\D/g, "")
													// 	.slice(0, 10);
													searchFormik.handleChange({
														target: {
															name: "branch",
															value: e.target.value,
														},
													});
												}}
											/>
											{getSearchFormErrorMessage("branch")}
										</div>
									</div>
									<div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
										<div class="d-flex justify-content-between">
											<button
												type="submit"
												class="btn btn-primary report-submit-button"
												//disabled={areAllValuesBlank()}
											>
												Search
											</button>
											
											<button
												type="button"
												className="btn btn-primary report-submit-button"
												onClick={() => {
													searchFormik.resetForm();
													programPerformance(
														{
															branch: ""
														}
													);
												}}
											>
												Reset
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>


          {/* <div className="row">
						<div className="col-md-2 col-xs-6">
							<div className="form-group">
								<input className="form-control reset" type="text" placeholder="From Date" />
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="form-group">
								<input className="form-control reset" type="text" placeholder="To Date" />
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="form-group">
								<input className="form-control reset" type="text" placeholder="Plumber Name/Mobile" />
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="form-group">
								<input className="form-control reset" type="text" placeholder="Search by Groupwise" />
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="form-group">
								<input className="form-control reset" type="text" placeholder="Search by Retaierwise" />
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<button type="button" className="btn btn-primary btn-sbmit">Search</button>
							</div>
						</div>
					</div> */}
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h3 className="head2">Registration Summary</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xs-12">
              <div className="kpibox">
                <div className="upperkpi h134">
                  <div className="namekpi">Total Registration</div>
                </div>
                <div className="kpilink">
                  {Number(data.memberApproved) +
                    Number(data.memberVerified) +
                    Number(data.pendingverification)}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="kpibox">
                <div className="upperkpi h134">
                  <div className="namekpi">Registered by Sales Team</div>
                </div>
                <div className="kpilink">{data.newSales}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h3 className="head2">Verfication Summary</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xs-12">
              <div className="kpibox">
                <div className="upperkpi h134">
                  <div className="namekpi">Pending Verification</div>
                </div>
                <div className="kpilink">{data.pendingverification}</div>
              </div>
            </div>
            {/* <div className="col-md-3 col-xs-12">
							<div className="kpibox">
								<div className="upperkpi h134">
									<div className="namekpi">
										Pending Approvals
									</div>
								</div>
								<div className="kpilink">
									{data.memberVerified}
								</div>
							</div>
						</div>  */}
            <div className="col-md-3 col-xs-12">
              <div className="kpibox">
                <div className="upperkpi h134">
                  <div className="namekpi">Verifed Plumbers</div>
                </div>
                <div className="kpilink">
                  {Number(data.memberApproved) + Number(data.memberVerified)}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="kpibox">
                <div className="upperkpi h134">
                  <div className="namekpi">Rejected Plumbers</div>
                </div>
                <div className="kpilink">{data.memberRejected}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h3 className="head2">Point Summary</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xs-12">
              <div className="kpibox">
                <div className="upperkpi h134">
                  <div className="namekpi">Earned Points</div>
                </div>
                <div className="kpilink">{data.pointsGiven}</div>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="kpibox">
                <div className="upperkpi h134">
                  <div className="namekpi">Redeemed Points</div>
                </div>
                <div className="kpilink">{data.pointsRedeemed}</div>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="kpibox">
                <div className="upperkpi h134">
                  <div className="namekpi">Balance Points</div>
                </div>
                <div className="kpilink">{data.balancePoints}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
