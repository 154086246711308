import React, { useEffect, useState } from "react";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../loader/CircleLoade";
import Navbar from "../../common/Navbar";

export default function PendingVerification() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [pendingVerification, setPendingVerification] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productError, setProductError] = useState(false);
  const [displayProductTable, setDisplayProductTable] = useState(false);

  useEffect(() => {
    getPendingVerification(
      {
        search: "",
      },
      1
    );
  }, []);

  const searchFormik = useFormik({
    initialValues: {
      search: "",
    },
    // validate: (data) => {
    //   let errors = {};
    //   if (data.search === "") {
    //     errors.search = "Please enter name";
    //   }
    //   return errors;
    // },
    onSubmit: (data) => {
      setCurrentPage(1);
      getPendingVerification(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getPendingVerification = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      let url =
        Constants.baseUrl +
        `user/manage/userByparentId?userType=RETAILER&status=NEW&sortBy=name&sortAsc=true&limit=${limit}&page=${pageNum}`;

      if (data.search !== "" && data?.search !== undefined) {
        url =
          Constants.baseUrl +
          `user/manage/userByparentId?name=${data.search}&userType=RETAILER&status=NEW&sortBy=name&sortAsc=true&limit=${limit}&page=${pageNum}`;
      }

      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program2,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setPendingVerification(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setTotalResults(dt?.output?.totalResults);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setPendingVerification(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const areAllValuesBlank = () => {
    const { search } = searchFormik.values;
    return !search;
  };

  const sendRowData = (row) => {
    navigate(`/pending_verification_form`, { state: { rowData: row } });
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">
                    Pending for verification
                  </strong>
                </div>
                <div className="card-body">
                  <form onSubmit={searchFormik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-3 col-xs-12 text-right">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search Retailer"
                            id="search"
                            name="search"
                            value={searchFormik.values.search}
                            onChange={(e) => {
                              searchFormik.handleChange({
                                target: {
                                  name: "search",
                                  value: e.target.value,
                                },
                              });
                            }}
                          />
                          {/* {getSearchFormErrorMessage("search")} */}
                        </div>
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary report-submit-button"
                            id="btnSearch"
                            // disabled={areAllValuesBlank()}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="col-md-3 col-md-offset-3 col-xs-12 text-right">
                        <div className="form-group">
                          Total Verification Count : {totalResults}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive table--no-card">
                        <table className="table table-borderless table-striped table-earning">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Retailer Id</th>
                              <th>Mobile No</th>
                              <th>Name</th>
                              <th>Firm Name</th>
                              <th>State</th>
                              <th>City</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {pendingVerification === null ? (
                            <tbody>
                              <tr>
                                <td
                                  colSpan="7"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  No record found!
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {pendingVerification &&
                                pendingVerification.length > 0 &&
                                pendingVerification.map((row, index) => {
                                  const serialNumber =
                                    (currentPage - 1) * limit + index + 1;

                                  // const invoiceDate = row?.invoiceDate
                                  //   ? moment(row?.invoiceDate).format(
                                  //       "DD-MM-YYYY"
                                  //     )
                                  //   : "";
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>{serialNumber}</td>
                                        <td>{row?.merchantId}</td>
                                        <td>{row?.mobile}</td>
                                        <td>{row?.name}</td>
                                        <td>{row?.firmName}</td>
                                        <td>{row?.addressWork?.workState}</td>
                                        <td>{row?.addressWork?.workCity}</td>
                                        <td>
                                          {/* <button
                                            type="button"
                                            className="btn btn-primary report-submit-button"
                                            id="btnSearch"
                                            onClick={() =>
                                              sendRowData(row)
                                            }
                                          >
                                            <i className="fa fa-eye"> View</i>
                                          </button> */}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => {
                              setCurrentPage(page);
                              getPendingVerification(filterData, page);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
