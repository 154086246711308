import React from "react";
import Navbar from "../../../common/Navbar";

export default function VoucherRedemptionHistory() {
  return (
    <>
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="head2">Discount Voucher Redemption History</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive table--no-card">
                    <table
                      className="table table-borderless table-striped table-earning"
                      id="redmhistory"
                    >
                      <thead>
                        <tr>
                          <th>Order Date</th>
                          <th>Amount</th>
                          <th>Voucher Code</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
