import React, { useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from "../../../common/Navbar";

export default function OrderIndent() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [orderIndent, setOrderIndent] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(500);
  const [filterData, setFilterData] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productError, setProductError] = useState(false);
  const [displayProductTable, setDisplayProductTable] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // useEffect(() => {
  //   getOrderIndent(1);
  //   setQuantitiesErr("");
  // }, []);

  useEffect(() => {
    setQuantitiesErr("");
    const delayDebounceFn = setTimeout(() => {
      getOrderIndent(currentPage);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, currentPage]);

  const searchFormik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getOrderIndent(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getOrderIndent = async (pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {

      await axios
        .get(
          Constants.baseUrl +
            `program/child/product?sortBy=name&sortAsc=true&limit=${limit}&page=${pageNum}&name=${searchTerm}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: 1, //Constants.program,
            },
          }
        )
        .then((res) => {
          //console.log(res);
          const dt = res.data;
          if (dt.result === "success") {
            setOrderIndent(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setOrderIndent(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const areAllValuesBlank = () => {
    const { startDate, endDate } = searchFormik.values;
    return !startDate && !endDate;
  };

  const toggleOption = (option) => {
    const index = selectedOptions.findIndex((opt) => opt.id === option.id);
    if (index !== -1) {
      setSelectedOptions(selectedOptions.filter((opt) => opt.id !== option.id));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedOptions.length === 0) {
      setProductError(true);
      setDisplayProductTable(false);
    } else {
      setProductError(false);
      setDisplayProductTable(true);
      // Your submit logic here
    }
  };

  const handleBack = (e) => {
    setProductError(false);
    setSelectedOptions([]);
    setDisplayProductTable(false);
    setQuantitiesErr("");
  };

  // console.log(selectedOptions);
  // console.log(productError);

  const [quantities, setQuantities] = useState({});
  const [quantitiesErr, setQuantitiesErr] = useState("");

  const handleQuantityChange = (productId, quantity) => {
    setQuantities({ ...quantities, [productId]: quantity });
  };

  const handleQuantitySubmit = async () => {
    let hasError = false;
    if (!quantities || Object.keys(quantities).length === 0) {
      hasError = true;
    }

    selectedOptions.forEach((product) => {
      if (!quantities[product.id]) {
        hasError = true;
      }
    });

    const orderReq = [];
    for (const productId in quantities) {
      const quantity = quantities[productId];
      if (!isNaN(quantity) && parseInt(quantity) > 0) {
        const product = selectedOptions.find(
          (item) => item.id === parseInt(productId)
        );
        if (product) {
          orderReq.push({
            id: parseInt(productId),
            name: product?.name,
            quantity,
          });
        }
      } else {
        hasError = true;
      }
    }

    if (hasError) {
      setQuantitiesErr("Please enter valid quantity.");
    } else {
      let token = localStorage.getItem("token");
      setLoading(true);
      if (!token) {
        navigate("/");
      } else {
        const payload = {
          orderReq: orderReq.map((item) => ({
            id: item.id,
            name: item.name,
            quantity: item.quantity,
          })),
        };

        try {
          await axios
            .post(
              `${Constants.baseUrl}program/child/product/order/place`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Accept-Language": localStorage.getItem("langauge"),
                  appVersion: Constants.av,
                  platform: Constants.pt,
                  company: Constants.company,
                  program: Constants.program,
                },
              }
            )
            .then((res) => {
              setLoading(false);
              if (res.data.code === 10001) {
                swal({
                  text: res.data.msg,
                  // icon: 'error',
                  timer: 2000,
                  buttons: false,
                });

                setProductError(false);
                setSelectedOptions([]);
                setDisplayProductTable(false);
                setQuantitiesErr("");
                setQuantities({});
              } else if (res.data.code === 12001) {
                swal({
                  text: res.data.msg,
                  // icon: 'error',
                  timer: 2000,
                  buttons: false,
                });
              } else {
                swal({
                  text: res.data.msg,
                  // icon: 'error',
                  timer: 2000,
                  buttons: false,
                });
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // If "Select All" checkbox is checked, select all options
      const allOptionIds = orderIndent.map((row) => row.id);
      const newSelectedOptions = orderIndent.filter((row) => !selectedOptions.some((opt) => opt.id === row.id));
      setSelectedOptions([...selectedOptions, ...newSelectedOptions]);
    } else {
      // If "Select All" checkbox is unchecked, deselect all options
      setSelectedOptions([]);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3 className="head2">Order Indent</h3>
            </div>
          </div>
          {!displayProductTable ? (
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Select Product</strong>
                  </div>
                  <div className="card-body">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Product"
                      id="getproduct"
                      autoComplete="off"
                      value={searchTerm}
                      onChange={handleInputChange}
                      onKeyUp={handleInputChange}
                      onKeyDown={handleInputChange}
                    />
                    <div style={{ height: "250px" }}>
                      {/* <select className="form-control Listproduct" id="ProductId" multiple="multiple" name="ProductId" style={{ height: 'inherit' }}>
										{orderIndent &&
											orderIndent.length > 0 &&
											orderIndent.map((row, index) => {
												return (
													<>
													<option value="{row?.id}" key={index}>{row?.category}</option>
													</>
												);
											})}
										</select> */}
                      <div>
                        <input
                          type="checkbox"
                          id="select-all"
                          checked={
                            selectedOptions?.length === orderIndent?.length
                          }
                          onChange={handleSelectAll}
                        />
                        <label htmlFor="select-all">Select All</label>
                      </div>
                      <div className="scrollable-container">
                        {orderIndent &&
                          orderIndent.length > 0 &&
                          orderIndent.map((row, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                id={`option-${row.id}`}
                                value={row.id}
                                checked={selectedOptions.some(
                                  (opt) => opt.id === row.id
                                )}
                                onChange={() => toggleOption(row)}
                              />
                              <label htmlFor={`option-${row.id}`}>
                                {row.sapCode}
                              </label>
                            </div>
                          ))}
                      </div>
                      {productError && (
                        <small className="report-error-field">
                          Please select at least one product
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <input
                      type="submit"
                      className="btn btn-primary btn-width"
                      value="Submit"
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive table--no-card">
                  <table className="table table-borderless table-striped table-earning">
                    <thead>
                      <tr>
                        <th> S No. </th>
                        <th> Product Id </th>
                        <th> Product Name </th>
                        <th> Product Type </th>
                        <th> Product Description </th>
                        <th> Product Quantity </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedOptions &&
                        selectedOptions.length > 0 &&
                        selectedOptions.map((row, index) => {
                          const serialNumber =
                            (currentPage - 1) * limit + index + 1;
                          return (
                            <>
                              <tr key={index}>
                                <td>{serialNumber}</td>
                                <td>{row?.id}</td>
                                <td>{row?.name}</td>
                                <td>{row?.groupDetails}</td>
                                <td>{row?.category}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Quantity"
                                    id={`input-quantity-${row?.id}`}
                                    value={quantities[row.id] || ""}
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        row.id,
                                        e.target.value
                                      )
                                    }
                                    autocomplete="off"
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                    {quantitiesErr != "" && (
                      <tfoot>
                        <tr>
                          <td colSpan={5}></td>
                          <td>
                            <small className="report-error-field">
                              {quantitiesErr}
                            </small>
                          </td>
                        </tr>
                      </tfoot>
                    )}
                  </table>
                </div>
              </div>
              <div className="col-md-12 mt-20">
                <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-2">
                    <input
                      type="submit"
                      className="btn btn-primary btn-width"
                      value="Back"
                      onClick={handleBack}
                    />
                  </div>
                  <div className="col-md-2">
                    <input
                      type="submit"
                      className="btn btn-primary btn-width"
                      value="Submit"
                      onClick={handleQuantitySubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
