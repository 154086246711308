import React from "react";
import Navbar from "../../../common/Navbar";
import waterTank from "../../../../assets/img/water-tank_red.png";
import interior from "../../../../assets/img/interiorsr.png";
import { Link } from "react-router-dom";
import swal from "sweetalert";
export default function Category1() {
  const handleClick = () => {
    swal({
      text: "Coming Soon",
      // icon: 'error',
      timer: 1000,
      buttons: false,
    });
  };
  return (
    <>
      <Navbar />
      <section class="page-content--bge55 ctbg">
        <div class="container">
          <div class="rolearea">
            <div class="row">
              <div class="col-md-12">
                <div class="heading-banner-title">
                  <h1>Choose a Category</h1>
                </div>
              </div>
            </div>
            <div class="row mt-30">
              <div class="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-12 col-23">
                <div class="card crddd">
                  <div class="card-body categoryboard">
                    <div class="row">
                      <div class="col-md-6 ctclm">
                        <div class="info-box_1 nflink wd80">
                          <Link to="/category2">
                            <div class="info-box-content">
                              <img src={waterTank} alt="" />
                              <span class="info-box-text">
                                Water Storage Tanks
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div class="col-md-6 ctclm">
                        <div class="info-box_1 nflink wd80">
                          <Link to="#">
                            <div class="info-box-content" onClick={handleClick}>
                              <img src={interior} alt="" />
                              <span class="info-box-text">Interiors</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
