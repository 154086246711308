import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import "../../../../src/assets/css/style.css";
import add from "../../../assets/img/plus.png";
import subtract from "../../../assets/img/minus.png";

export default function English() {
  return (
    <>
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-12'>
              <h3 class="head2 text-center">
                Sintex Hamesha Retailer Loyalty Program
                <br />
                FAQ’s
              </h3>
            </div>
            <div className="col-md-12">
              <div className="card" style={{ padding: '14px' }}>
                <div className="faq-content">
                  <Accordion
                   expandIcon={
                    <img src={add} alt="" className="img-fluid icon-add" />
                  }
                  collapseIcon={
                    <img
                      src={subtract}
                      alt=""
                      className="img-fluid icon-subtract"
                    />
                  } 
                   activeIndex={0}>
                    <AccordionTab header=" Who is eligible to participate in this program?">
                      <p className="m-0 faq-ans">
                        <span></span>The Sintex Hamesha Retailer Program is open for enrolment of Retailers who buy directly from an Authorized Distributor of Sintex Water Storage Tanks.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Who all are classified under the Retailer category?">
                      <p className="m-0 faq-ans">
                        <span></span>The Retailer in the "Sintex Hamesha Retailer Program" refers to retailers who buy directly from an Authorized Distributor of Sintex Water Storage Tanks
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Products of SINTEX are eligible to participate in the Program?">
                      <p className="m-0 faq-ans">
                        <span></span>Scheme is applicable for the entire range of Sintex TruPUF, Sintex Tatva, Sintex Pure, Sintex Ace, Sintex DW, Sintex Titus, Sintex Hero, Sintex Reno, Sintex Neo, Sintex Loft, Sintex ISI, Sintex Sump (UGWT).
                      </p>
                    </AccordionTab>
                    <AccordionTab header="How to register for the first-time?">
                      <p className="m-0 faq-ans">
                        <span></span>
                        <ul>
                          <li>Registration is open and would be done only by respective Sales Team assigned to the territory.</li>
                          <li>Aadhar Card, GST Registration Copy, Front counter pic and Pan Card is mandatory for registration</li>
                          <li>Referral of 3 plumbers/plumbing contractors is mandatory</li>
                        </ul>
                      </p>
                    </AccordionTab>
                    <AccordionTab header="How do I report my purchases in the program?">
                      <p className="m-0 faq-ans">
                        <span></span>It would be automatically reported once you purchase eligible products from the range of Sintex Water Storage Tanks, from an authorized Distributor.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="From where do I download Mobile Application?">
                      <p className="m-0 faq-ans">
                        <span></span>You can download the mobile application from either Apple Store or Google Play Store (both Android and iOS Platform) and search for ‘Sintex Hamesha Retailer’.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="From when I will start earning points/value?">
                      <p className="m-0 faq-ans">
                        <span></span>Points/value would be allocated as soon as your purchases get reflected at the Program centre.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="How do I calculate my Reward Points/value?">
                      <p className="m-0 faq-ans">
                        <span></span>With every tank sold, you would get points/value according to matrix in Sintex Hamesha Retailer Loyalty Program
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Can I share/Club my Points with any other Sub Dealer/Retailer?">
                      <p className="m-0 faq-ans">
                        <span></span>No, you cannot share/club your Points with anyone.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="How do I come to know my Point Status?">
                      <p className="m-0 faq-ans">
                        <span></span> Your Point Status will be informed to you by the following manner:
                        <br></br>
                        a) By using your login credentials on the program website i.e. <a href="www.sintexhamesha.com">www.sintexhamesha.com</a> OR on the Mobile Application
                        <br></br>
                        b) By calling us on the help line number 1800-121-2764, between 9.30 am – 5.30 pm from Monday to Friday.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Till when can a Retailer register/join the Sintex Retailer Loyalty Program?">
                      <p className="m-0 faq-ans">
                        <span></span>The Sintex Retailer Loyalty is an Open-Ended Program. A Retailer can make a beginning anytime during the course of the Program by Registering himself.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="How do I Redeem Rewards against my accumulated amount?">
                      <p className="m-0 faq-ans">
                        <span></span> You need to login to <a href="https://www.sintexhamesha.com/">www.sintexhamesha.com</a> or on Mobile App with the unique id and password provided to you. Please visit the Redeem section to view the catalogue online and follow the instructions on the options to redeem.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="What is “Make A Wish”?">
                      <p className="m-0 faq-ans">
                        <span></span>“Make a Wish” is a section to ask for the product that is not available in the catalogue. Your balance points will be mapped against the requirement of your wish. Any shortfall in the points has to be covered by member only by purchasing more purchases.m
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Do I have to use website also, if I am already using mobile app?">
                      <p className="m-0 faq-ans">
                        <span></span>No, it is not mandatory to use only Mobile App or only Website. You may use any of them through same log in credentials. We have incorporated all the features on both the interfaces.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Can my points be restored if I am not able to utilise the redeemed voucher?">
                      <p className="m-0 faq-ans">
                        <span></span>No, once points / value are redeemed, the same cannot be reversed.
                      </p>
                    </AccordionTab>
                    <AccordionTab header="Can I redeem all the points at once?">

                      <p className="m-0 faq-ans">
                        <span></span>You can redeem up to 80% of total points.
                      </p>
                    </AccordionTab>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <h3 class="head2 text-center">
                FAQ’s on Sintex Hamesha Retailer Loyalty Program w.e.f. 01 July 2023:
              </h3>
            </div>
            <div className='card' style={{ padding: '14px' }}>
            <div className="faq-content">
              <Accordion
                expandIcon={
                  <img src={add} alt="" className="img-fluid icon-add" />
                }
                collapseIcon={
                  <img
                    src={subtract}
                    alt=""
                    className="img-fluid icon-subtract"
                  />
                } 
               activeIndex={1}>
                <AccordionTab header="How is my earning calculated?">
                  <p className="m-0 faq-ans">
                    <span></span>Earnings depends on i) Which group the product lies in (1 or 2), ii) Qty Purchased per group iii) Total Brands purchased
                  </p>
                </AccordionTab>
                <AccordionTab header="What are Group 1 & Group 2?">
                  <p className="m-0 faq-ans">
                    <span></span>Group 1 contains premium priced brands: TruPUF, Tatva, Pure, Ace, Titus, UGWT, Sintex DW Group 2 contains mass market brands: Hero, Reno, Neo, Loft, ISI
                  </p>
                </AccordionTab>
                <AccordionTab header="How are my incentives calculated on Group 1, Group 2 & Total Litres?">
                  <p className="m-0 faq-ans">
                    <span></span>
                    Flat Scheme is calculated on group wise quantity,
                    Quantity scheme is also calculated on Group Wise Qty
                    and Range sales scheme is on total qty E.g.: 5000
                    Litres of Pure, 5000 Litres of Titus, 5000 Litres of
                    Reno: Total 15K Litres,
                    <br />
                    <b>Flat Scheme: </b>
                    <br />
                    &emsp;&emsp;&emsp;&emsp;Group 1: Pure + Titus = 10000
                    Litres * 10p = Rs.1000
                    <br />
                    &emsp;&emsp;&emsp;&emsp;Group 2: Reno = 5000 Litres *
                    5p = Rs. 250
                    <br />
                    <b>Quantity Purchase: </b>
                    <br />
                    &emsp;&emsp;&emsp;&emsp;Group 1: 10000 Litres*10p =
                    Rs. 1000
                    <br />
                    &emsp;&emsp;&emsp;&emsp;Group 2: 5000 Litres*4p = Rs.
                    200
                    <br />
                    <b>
                      Range- Since there are 3 brands then 3p slab on
                      total qty{" "}
                    </b>
                    <br />
                    &emsp;&emsp;&emsp;&emsp;Qty: 15000*(3p) = Rs. 450
                    <br />
                    <br />
                    &emsp;&emsp;<b>Total = </b>1000+250+1000+200+450 ={" "}
                    <b>Rs. 2900</b>
                  </p>
                </AccordionTab>
                <AccordionTab header="What is the earning potential of a newly added retailer?">
                  <p className="m-0 faq-ans">
                    <span></span>Newly added retailers who purchase more than 1K litres will get flat loyalty payout + avg payout of 8 paise for Qty purchasing + range sale payout as per purchase. 6 months performance will define their new classification
                  </p>
                </AccordionTab>
                <AccordionTab header="How are different brands counted?">
                  <p className="m-0 faq-ans">
                    <span></span>I was earning 10paise/Ltr on earlier purchase of 1000 Litres of Reno & Neo, How much will I make now?
                  </p>
                </AccordionTab>
                <AccordionTab header="How can I win the Mega Loyalty Reward?">
                  <p className="m-0 faq-ans">
                    <span></span>There are 4 slabs of Mega Loyalty rewards:=10 Lac Litres, 7-9.9 Lac Litres, 4-6.9 Lac Litres, 2-4 Lac Litres with July’23 to Mar’24 as the delivery period. Retailers have to ensure to bill every month in this period.
                  </p>
                </AccordionTab>
                <AccordionTab header="How many people can go for the international trip awards?">
                  <p className="m-0 faq-ans">
                    <span></span>Each reward covers 1 person for the trip, covering from Onwards Flights to Return Flights, hotel stay, all meals, planned excursions & sightseeing activities.
                  </p>
                </AccordionTab>
                <AccordionTab header="What about inclusion of new outlets in the Mega Loyalty Program?">
                  <p className="m-0 faq-ans">
                    <span></span>New outlets which are added and billed till September 2023 only will be considered in the Mega Loyalty Program and they will have bill in all months till March 2024; Any outlet addition after September 2023 will not be considered in this program.
                  </p>
                </AccordionTab>
              </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  )
}
