import React, { useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from "../../../common/Navbar";

export default function PurchaseHistory() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [purchaseHistory, setPurchaseHistory] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    getPurchaseHistory({},1)

  }, []);
  const searchFormik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getPurchaseHistory(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getPurchaseHistory = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&startDate=${startFormattedDate}`;
        endDate = `&enddate=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      await axios
        .get(
          Constants.baseUrl +
            `purchase/purchaseHistory?name=${stDate}${endDate}&sortBy=name&sortAsc=true&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          //console.log(res);
          const dt = res.data;
          if (dt.result === "success") {
            setPurchaseHistory(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setPurchaseHistory(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const areAllValuesBlank = () => {
    const { startDate, endDate } = searchFormik.values;
    return !startDate && !endDate;
  };

  const downloadReport = () => {
    setDownloadLoading(true);
    try {
      axios
        .post(
          `${Constants.baseUrl}purchase/csv/download/purchsae_history`,{},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program2,
            },
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setDownloadLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            const sixDigitRandomNo = Math.floor(
              100000 + Math.random() * 900000
            );
            saveAs(fileData, `Purchase_history_${sixDigitRandomNo}.csv`);
          } else {
            setDownloadLoading(false);
            swal({
              text: res.data.msg,
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="head2">Purchase History</h3>
                </div>
                <div className="col-md-6">
                  <ul className="listtt111">
                    <li>
                      <label>Distributor</label>
                      GANESH SANITARY STORE
                    </li>
                    <li>
                      <label>Zone</label>
                      NORTH
                    </li>
                  </ul>
                </div>
              </div>
              <form onSubmit={searchFormik.handleSubmit}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      {/* <label>Start Date</label> */}
                      <DatePicker
                        id="startDate"
                        selected={searchFormik.values.startDate}
                        onChange={(date) =>
                          searchFormik.setFieldValue("startDate", date)
                        }
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                        placeholderText="Start date"
                      />
                      {getSearchFormErrorMessage("startDate")}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      {/* <label>End Date</label> */}
                      <DatePicker
                        id="endDate"
                        selected={searchFormik.values.endDate}
                        onChange={(date) =>
                          searchFormik.setFieldValue("endDate", date)
                        }
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                        placeholderText="End date"
                      />
                      {getSearchFormErrorMessage("endDate")}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary report-submit-button"
                        id="btnSearch"
                        disabled={areAllValuesBlank()}
                      >
                        Search
                      </button>

                      {purchaseHistory === null ? (
                        ""
                      ) : (
                        <Link
                          to="#"
                          class={`btn btn-primary report-submit-button mx-5 ${
                            downloadLoading ? "btn-opacity" : ""
                          }`}
                          onClick={() => downloadReport()}
                        >
                          {downloadLoading ? (
                            <span>Exporting ...</span>
                          ) : (
                            " Export"
                          )}
                        </Link>
                      )}
                    </div>
                    
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive table--no-card">
                    <table className="table table-borderless table-striped table-earning">
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Product Name</th>
                          <th>Invoice Number</th>
                          <th>Net Litre</th>
                          <th>Net Quantity</th>
                          <th>Date</th>
                          <th>Branch</th>
                          <th>Distributor Name</th>
                          <th>Category</th>
                        </tr>
                      </thead>
											{purchaseHistory === null ? (
                        <tbody>
                          <tr>
                            <td
                              colSpan="9"
                              style={{ textAlign: "center", fontSize: "16px" }}
                            >
                              No record found!
                            </td>
                          </tr>
                        </tbody>
                      ) : (
												<tbody>
												{purchaseHistory && purchaseHistory.length > 0 && purchaseHistory.map((purchase, index) => {
														const serialNumber =
															(currentPage - 1) * limit + index + 1;

															const invoiceDate = purchase?.invoiceDate
															? moment(purchase?.invoiceDate).format("DD-MM-YYYY")
															: "";
														return (
															<>
															<tr key={index}>
																<td>{serialNumber}</td>
																<td>{purchase?.productName}</td>
																<td>{purchase?.invoiceNumber}</td>
																<td>{purchase?.litRange}</td>
																<td>{purchase?.netQuantity}</td>
																<td>{invoiceDate}</td>
																<td>{purchase?.rtBranch}</td>
																<td>{purchase?.distributorName}</td>
																<td>{purchase?.itemSubcatName}</td>
															</tr>
															</>
														);
													})}
											</tbody>
											)}
                    </table>
										{totalPages > 1 && (
                      <ResponsivePagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={(page) => {
                          setCurrentPage(page);
                          getPurchaseHistory(filterData, page);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
