import React from "react";
import Navbar from "../../common/Navbar";
import eligible_product from "../../../assets/img/eligible_product.png";
export default function About() {
  return (
    <>
      <Navbar />
      <section className="">
        <div className="container-fluid">
          <div className="inner-sec">
            <div className="row">
              <div className="col-md-12">
                <figure className="abbnt">
                  <img src={eligible_product} alt="" />
                </figure>
              </div>
              <div className="col-md-12">
                {/* <p className="bdd text-center">About Sintex BAPL Limited</p> */}
                <p className="bdd text-center">
                  About Sintex Plastic Technology
                </p>
              </div>
              <div className="col-md-12">
                {/* <p className="fnt16">
                  Sintex BAPL Limited are the pioneers in water storage
                  solutions. The company manufactures a wide range of building
                  products and solutions. In addition to water storage
                  solutions, the company manufactures Electrical solutions and
                  SMC products, Interiors solutions, Water Treatment solutions,
                  Industrial solutions as well as Environmental and green
                  solutions. Water storage solutions include Sintex Pure Anti
                  Microbial, Sintex Ace Anti Bacterial, Sintex DW, Sintex Titus,
                  Sintex Reno, Sintex Neo, Sintex Loft water tanks, Sintex
                  Underground Water Tanks (including FRP) and SMC panel tanks.
                </p> */}
                <p className="fnt16">
                  Sintex BAPL Limited, a part of the Welspun World, is the
                  pioneer in water storage solutions. We at Sintex believe in
                  thinking innovatively, to make life simple for you. This is
                  what you experience with our comprehensive range of products,
                  designed with your needs in mind. The Company's solutions
                  include structural solutions, electrical solutions, water
                  management solutions, environmental solution, energy
                  solutions, interior solutions, material handling, telecom
                  solutions, and industrial solutions. The Company's product
                  offering includes water storage solutions, underground water
                  tanks (FRP), SMC panel water tanks, Electrical products,
                  Interiors and Industrial products. Our water storage tanks are
                  entirely crafted from 100% virgin, food grade material. Our
                  tanks maintain the highest standards of hygiene, and are built
                  strong to last a long, long time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
