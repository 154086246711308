import React, { useState, useEffect } from "react";
import Navbar from "../../../common/Navbar";
import influencer from "../../../../assets/img/influencer.png";
import retailer from "../../../../assets/img/retailer.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Oval from "../../loader/CircleLoade";
import swal from "sweetalert";
export default function Category2() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const userType = localStorage.getItem("user_type");

  useEffect(() => {
    const userType = localStorage.getItem("user_type");
    const category = localStorage.getItem("category");
    // if (userType === "SALES") {
    //   if (category === "influencer") {
    //     navigate("/influencer_dashboard");
    //   } else if (category === "retailer") {
    //     navigate("/sales_retailer_Dashboard");
    //   } else {
    //     //navigate("/sintex/category");
		// 		navigate("/category2");
    //   }
    // } else if (userType === "RETAILER") {
    //   navigate("/retailer_Dashboard");
    // } else if (userType === "HO") {
    //   navigate("/influencer_dashboard");
    // }

    if (!userType || userType === "") {
      navigate("/");
    }
  }, []);

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section class="page-content--bge55 ctbg">
        <div class="container-fluid">
          <div class="rolearea">
            <div class="row">
              <div class="col-md-12">
                <div class="heading-banner-title">
                  <h1>Choose a Category</h1>
                </div>
              </div>
            </div>
            <div class="row mt-30">
              <div class="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-12 col-23">
                <div class="card crddd">
                  <div class="card-body categoryboard">
                    <div class="row">
                      <div class="col-md-6 ctclm">
                        <div class="info-box_1 nflink wd80">
                          <div
                            class="info-box-content"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              {
                                localStorage.setItem("category", "influencer");
                              }
                              navigate("/influencer_dashboard");
                            }}
                          >
                            <img src={influencer} alt="" />
                            <span class="info-box-text">Plumber</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 ctclm">
                        <div class="info-box_1 nflink wd80">
                          <div
                            class="info-box-content"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              {
                                localStorage.setItem("category", "retailer");
                              }
                              navigate("/sales_retailer_Dashboard");
                              // swal({
                              //     text: 'Coming Soon',
                              //     // icon: 'error',
                              //     timer: 1000,
                              //     buttons: false,
                              // })
                            }}
                          >
                            <img src={retailer} alt="" />
                            <span class="info-box-text">Retailer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
