import React from 'react'
import Navbar from '../../common/Navbar'
import login_left from '../../../assets/img/login-left.png'
import { useFormik } from 'formik';
import swal from 'sweetalert';
import Swal from 'sweetalert2'
import axios from 'axios';
import * as Constants from "../../../Constants/index"
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
export default function FogotPassword() {
    const navigate = useNavigate();
    const forgotFormik = useFormik({
        initialValues: {
            mobile: "",
        },
        validate: (data) => {
            let errors = {};
            if (!data.mobile) {
                errors.mobile = 'Mobile no. is required';
            } else if (!/^\d+$/.test(data.mobile)) {
                errors.mobile = 'Mobile no. must be numeric';
            } else if (data.mobile.length !== 10) {
                errors.mobile = 'Mobile must be 10 digits';
            }
            return errors;
        },
        onSubmit: (data) => {
            Swal.fire({
                title: "Are you sure?",
                // text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, change it!",
                allowOutsideClick: false,
                customClass: {
                    popup: 'custom-swal-popup',
                    icon: 'custom-swal-icon',
                    confirmButton: 'custom-swal-confirmButton',
                    cancelButton: 'custom-swal-cancelButton',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    handleForgotRequest(data);
                }
            });
        },
    });
    const isForgotFormFieldValid = (name) =>
        !!(forgotFormik.touched[name] && forgotFormik.errors[name]);
    const getForgotFormErrorMessage = (name) => {
        return (
            isForgotFormFieldValid(name) && (
                <small className="p-error">{forgotFormik.errors[name]}</small>
            )
        );
    };
    const handleForgotRequest = async (data) => {
        try {
            await axios.post(Constants.baseUrl + 'auth/forget-password', {
                mobile: data.mobile,
                password: ''
            },
                {
                    headers: {
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    },
                }).then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                                // icon: 'success'
                            })
                            setTimeout(() => {
                                navigate('/')
                            }, 2000);
                        } else if (res.data.code === 12002) {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                                // icon: 'error'
                            });
                        } else {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            });
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                });

        } catch (error) {
            console.log(error);
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleForgotRequest();
        }
    };
    return (
        <>
            <Navbar />
            <section class="page-content--bge5">
                <div class="container">
                    <div class="login-wrap">
                        <div class="row bg-whitee">
                            <div class="col-md-5 left-container">
                                <img src={login_left} class="mtop" alt='' />
                            </div>
                            <div class="col-md-7">
                                <form onSubmit={forgotFormik.handleSubmit}>
                                    <div class="login-content">
                                        <div class="form-group form-group-mb">
                                            <label>Mobile</label>
                                            <input type="text" id='mobile' value={forgotFormik.values.mobile} maxLength="10" onChange={forgotFormik.handleChange}
                                                className="form-control" placeholder="Mobile" onKeyDown={handleKeyDown} onKeyPress={(e) => {
                                                    const isNumeric = /^[0-9]*$/;
                                                    if (!isNumeric.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }} />
                                            {getForgotFormErrorMessage('mobile')}
                                        </div>
                                        {/* <div className="form-group text-right">
                                            <Link className="text-primary cursor-anchor" to="/login">Back to login</Link>
                                        </div> */}
                                        <button class="btn btn-primary btn-block form-group-mt" type="submit"> Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
