import React, { useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from "../../../common/Navbar";

import starGraphic from "../../../../assets/img/star_graphic.png";
import textBox from "../../../../assets/img/text_box.png";
import men from "../../../../assets/img/men.png";
export default function MakeWish() {

	const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
	const [descriptionErr, setDescriptionErr] = useState("");
	const [description, setDescription] = useState("");

  const handleWishSubmit = async () => {
    let hasError = false;
    if (description === "") {
      hasError = true;
    }

    if (hasError) {
      setDescriptionErr("The Make a wish field is required.");
    } else {
      let token = localStorage.getItem("token");
      setLoading(true);
      if (!token) {
        navigate("/");
      } else {
        const payload = {
          description:description,
					active:true
        };

        try {
          await axios
            .post(
              `${Constants.baseUrl}program/Wish`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Accept-Language": localStorage.getItem("langauge"),
                  appVersion: Constants.av,
                  platform: Constants.pt,
                  company: Constants.company,
                  program: Constants.program,
                },
              }
            )
            .then((res) => {
							console.log('res res', res);
              setLoading(false);
              if (res.data.code === 10001) {
                swal({
                  text: res.data.msg,
                  // icon: 'error',
                  timer: 2000,
                  buttons: false,
                });
                setDescriptionErr("");
								setDescription("");
								navigate("/retailer_Dashboard");
              } else if (res.data.code === 12001) {
                swal({
                  text: res.data.msg,
                  // icon: 'error',
                  timer: 2000,
                  buttons: false,
                });
              } else {
                swal({
                  text: res.data.msg,
                  // icon: 'error',
                  timer: 2000,
                  buttons: false,
                });
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section class="page-content--bge55 makewishbg">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6 ssr">
                  <img src={starGraphic} alt="" />
                </div>

                <div class="col-md-6">
                  <div class="wishtext">
                    You make a wish and
                    <br />
                    <b>SINTEX HAMESHA</b>
                    <br /> will make it happen.
                  </div>
                </div>
              </div>
              <div class="row">
								{descriptionErr != "" && (
								<div class="col-md-12">
									<small className="report-error-field">
										{descriptionErr}
									</small>
                </div>
								)}
                <div class="col-md-12">
                  <textarea
                    class="wishinput"
                    maxlength="200"
                    rows="1"
                    type="text"
										value={description}
										onChange={(e) =>
											setDescription(
												e.target.value
											)
										}
                  ></textarea>
                  <img src={textBox} class="percent100" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-right">
                  <button type="submit" class="btn btn-primary btn-sbmit" onClick={handleWishSubmit}> Submit</button>
                </div>
              </div>

              <div class="row mtopp40">
                <div class="col-md-12">
                  <span>
                    <b>
                      Sintex Hamesha introduces an open reward option for you to
                      <span style={{ color: "#ff6666" }}>"MAKE A WISH"</span>.
                      This option allows you to submit the wish you and your
                      family hold.
                    </b>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <img src={men} class="width100" />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <span style={{ color: "white" }}>
                <br />
                <br />
                <b>You have to follow the below steps:</b>
                <br />
                <br />
                <i class="fa fa-check-circle"></i> Submit your wish in the space
                provided.
                <br />
                <i class="fa fa-check-circle"></i> Sintex Hamesha will review
                your request for points availability and will call you for
                confirmation.
                <br />
                <i class="fa fa-check-circle"></i> Post product availability,
                the dispatch process will be initiated.
                <br />
                <i class="fa fa-check-circle"></i> Product will be delivered to
                you within 6-8 weeks.{" "}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
