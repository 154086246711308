import React from 'react'
import Navbar from '../../common/Navbar'
import loginLeft from '../../../assets/img/login-left.png'
import { Link } from 'react-router-dom'
import axios from 'axios';
import * as Constants from "../../../Constants/index"
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
export default function ChangePassword() {
    const navigate = useNavigate();
    const loginFormik = useFormik({
        initialValues: {
            currPass: "",
            newPass: "",
            conNewPass: ''
        },
        validate: (data) => {
            let errors = {};
            if (!data.currPass) {
                errors.currPass = 'Current password is required';
            }
            if (!data.newPass) {
                errors.newPass = 'New password is required';
            } else if (data.newPass.length !== 6) {
                errors.newPass = 'Current password must be 6 digits';
            }
            if (data.conNewPass !== '') {
                if (data.conNewPass !== data.newPass) {
                    errors.conNewPass = 'Passwords do not match';
                }
            } else if (data.newPass !== '' && data.conNewPass === '') {
                errors.conNewPass = 'Confirm passwords is required';
            }

            return errors;
        },
        onSubmit: (data) => {
            console.log('change password data', data)
            // handleLogin(data);
        },
    });
    const isLoginFormFieldValid = (name) =>
        !!(loginFormik.touched[name] && loginFormik.errors[name]);
    const getLoginFormErrorMessage = (name) => {
        return (
            isLoginFormFieldValid(name) && (
                <small className="p-error">{loginFormik.errors[name]}</small>
            )
        );
    };

    return (
        <>
            <Navbar />
            <section class="page-content--bge5">
                <div class="container">
                    <div class="login-wrap">
                        <div class="row bg-whitee">
                            <div class="col-md-5 left-container">
                                <img src={loginLeft} class="mtop" />
                            </div>
                            <div class="col-md-7">
                                <div class="login-content">
                                    <form onSubmit={loginFormik.handleSubmit}>
                                        <div class="form-group form-group-mb" >
                                            <label>Current Password</label>
                                            <input autocomplete="off" class="form-control" id='currPass' placeholder="Old Password" type="password" value={loginFormik.values.currPass} onChange={loginFormik.handleChange} />
                                            {getLoginFormErrorMessage('currPass')}

                                        </div>
                                        <div class="form-group form-group-mb">
                                            <label>New Password</label>
                                            <input autocomplete="off" class="form-control" id='newPass' placeholder="New Password" type="password" value={loginFormik.values.newPass} onChange={loginFormik.handleChange} />
                                            {getLoginFormErrorMessage('newPass')}

                                        </div>
                                        <div class="form-group form-group-mb">
                                            <label>Confirm New Password</label>
                                            <input autocomplete="off" class="form-control" id='conNewPass' placeholder="Confirm New Password" type="password" value={loginFormik.values.conNewPass} onChange={loginFormik.handleChange} />
                                            {getLoginFormErrorMessage('conNewPass')}
                                        </div>

                                        <button class="btn btn-primary btn-block form-group-mt" type="submit" id=""> ChangePassword</button>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
