import React from 'react'
import Navbar from '../../../common/Navbar'
import commingsoon from '../../../../assets/img/commingsoon.jpg'
export default function SchemeOffer() {
    return (
        <>
            <Navbar />
            <section className="page-content--bge55">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="head2">Schemes & Offers</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="figure">
                                    <img src={commingsoon} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </section >
        </>
    )
}
