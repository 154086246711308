import React, { useState, useEffect } from 'react'
import Navbar from '../../../common/Navbar'
import { Link } from 'react-router-dom';
import * as Constants from '../../../../Constants/index'
import axios from 'axios'
import Oval from '../../loader/CircleLoade';
import { useNavigate } from 'react-router-dom';
import ResponsivePagination from 'react-responsive-pagination';
import aadharfront from '../../../../assets/img/aadharfront.jpeg'
import aadharback from '../../../../assets/img/aadharback.png'
import gst from '../../../../assets/img/gst.png'
import pan from '../../../../assets/img/pan.jpeg'
import swal from 'sweetalert';
import moment from 'moment';
export default function PendingVerification() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [searchUser, setSearchUser] = useState('')
    const [limit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [results, setResults] = useState([])
    const [totalPages, setTotalPages] = useState()
    const [searchRecord, setSearchRecord] = useState()
    const [viewDetails, setViewDetails] = useState(false)
    const [regData, setRegData] = useState({})

    useEffect(() => {

        if (currentPage !== 1) {
            pendingVerificationReq(searchUser);
        } else {
            pendingVerificationReq('');
        }
    }, [currentPage]);

    const pendingVerificationReq = async (data) => {
        let token = localStorage.getItem('token');
        setLoading(true)
        if (!token) {
            navigate('/')
        } else {
            const searchUserVal = data === '' ? '' : `name=${data}&`

            try {
                await axios.get(Constants.baseUrl + `user/manage/image_pending_list?${searchUserVal}userType=MEMBER&status=APPROVED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    }
                }).then((res) => {
                    if (res.status === 201) {
                        if (res.data.code === 10001) {
                            setViewDetails(false)
                            setResults(res.data.output.results)
                            setTotalPages(res.data.output.totalPages)
                            setLoading(false)
                        } else if (res.data.code === 12002) {
                            setViewDetails(false)
                            setLoading(false);
                            setSearchRecord(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                        } else {
                            swal({
                                text: res.data.msg,
                                // icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }
    // view registraion form
    const handleViewReg = async (id) => {
        setLoading(true)
        let token = localStorage.getItem('token');
        try {
            await axios.get(Constants.baseUrl + `user/manage/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                },
            }).then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 10001) {
                        const data = res.data.output;
                        setRegData(data);
                        setLoading(false)
                        setViewDetails(true)
                        window.scrollTo({
                            top: 600,
                            behavior: 'smooth',
                        });

                    } else if (res.data.code === 12001) {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                } else {
                    swal({
                        text: res.data.msg,
                        // icon: 'error',
                        timer: 2000,
                        buttons: false,
                    })
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const handleSearch = (event) => {
        setSearchUser(event.target.value)
        if (event.target.value === '') {
            pendingVerificationReq('')
            setSearchRecord()
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            pendingVerificationReq(searchUser);
        }
    };
    return (
        <>
            {loading ? <Oval /> : null}
            <Navbar />
            <section className="page-content--bge55">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="head1">Pending For Verification </h3>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            {/* <div class="row">
                                <div class="col-md-3 col-xs-6">
                                    <div class="form-group">
                                        <input className="form-control reset" type="text" placeholder="Name/Mobile No." value={searchUser} onChange={handleSearch} onKeyDown={handleKeyDown} />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <button type="button" className="btn btn-primary btn-search" disabled={searchUser.trim() === ''}
                                            onClick={() => pendingVerificationReq(searchUser)}>Search</button>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row mt-20">
                                <div className="col-md-12">
                                    <div className="table-responsive table--no-card">
                                        <table className="table table-borderless table-striped table-earning">
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>View Details</th>
                                                    {/* <th> Update Profile </th> */}
                                                    <th> User ID </th>
                                                    <th> User Name </th>
                                                    <th> City </th>
                                                    <th>Date Of Reg.</th>
                                                </tr>
                                            </thead>
                                            {searchRecord === null ?
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="7" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                    </tr>
                                                </tbody> :
                                                <tbody>
                                                    {results && results.map((item, index) => {
                                                        const serialNumber = (currentPage - 1) * limit + index + 1;
                                                        const dateOfReg = moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{serialNumber}</td>
                                                                    <td><Link to="#" className="viewlink" onClick={() => handleViewReg(item.id)}>View Details</Link></td>
                                                                    {/* <td><Link to="#" className="selectlink">Select</Link></td> */}
                                                                    <td>{item.mobile}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.addressWork?.workCity}</td>
                                                                    <td>{dateOfReg}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>}
                                        </table>
                                        {totalPages > 1 && (
                                            <ResponsivePagination
                                                current={currentPage}
                                                total={totalPages}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {viewDetails === true ?
                                <div className="row mt-20 enrollmentform">
                                    <div className="col-md-12">
                                        <h4 className="headdetails">
                                            <strong className="card-title">Basic Details</strong>
                                        </h4>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>First Name*</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.name} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Middle Name</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.midleName} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.lastName} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Mobile Number*</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.mobile} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Email ID</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.email} />
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Address 1</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.addressWork?.workAddress1} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Address 2</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.addressWork?.workAddress2} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Address 3</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.addressWork?.workAddress3} />
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>State*</label>
                                            <input className="form-control" disabled="disabled" value={regData.addressWork?.workState} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>District*</label>
                                            <input className="form-control" disabled="disabled" value={regData.addressWork?.workDistrict} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>City*</label>
                                            <input className="form-control" disabled="disabled" value={regData.addressWork?.workCity} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Pincode*</label>
                                            <input className="form-control" disabled="disabled" value={regData.addressWork?.workPincode} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label><strong>Selected Profile</strong></label>
                                            <div className="profile">
                                                {regData.plumberProfile === 'plumber' ?
                                                    <label className="radio-inline">
                                                        <input type="radio" name="profile" checked={regData.plumberProfile === 'plumber'} /> Plumber
                                                    </label> :
                                                    <label className="radio-inline">
                                                        <input type="radio" name="profile" checked={regData.plumberProfile === 'contractor'} /> Plumbing Contractor
                                                    </label>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <h4 className="headdetails">
                                            <strong className="card-title">KYC Details</strong>
                                        </h4>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Mobile No. Linked with Aadhar</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.userModelKYC?.aadharMobile} />

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label> Aadhar No.</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.userModelKYC?.aadharNumber} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label> Aadhar Front Image</label>
                                            <Link to={regData.userModelKYC?.aadharFrontUrl} target="_blank">View <img src={aadharfront} className="docimage" alt="" /></Link>

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label> Aadhar Back Image</label>
                                            <Link to={regData.userModelKYC?.aadharBackUrl} target="_blank">View <img src={aadharback} className="docimage" alt="" /></Link>

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group ">
                                            <label>Pan No.</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.userModelKYC?.panNumber} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label> Pan Image</label>
                                            <Link to={regData.userModelKYC?.panFrontUrl} target="_blank">View <img src={pan} className="docimage" alt="" /></Link>
                                        </div>
                                    </div>
                                    {regData.plumberProfile === 'contractor' ?
                                        <div className="col-lg-3 col-md-3 col-xs-12">
                                            <div className="form-group ">
                                                <label>GST No.</label>
                                                <input className="form-control" disabled="disabled" type="text" value={regData.gst} />
                                            </div>
                                        </div> : ''}
                                    {regData.plumberProfile === 'contractor' ?
                                        <div className="col-lg-3 col-md-3 col-xs-12">
                                            <div className="form-group">
                                                <label> GST Image</label>
                                                <Link to={regData.gstImage} target="_blank"> View <img src={gst} className="docimage" alt="" /></Link>
                                            </div>
                                        </div> : ''}
                                </div> : ''}
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
