import React, { useEffect, useState } from 'react';
// import Oval from "../loader/CircleLoader";
import { useNavigate } from "react-router-dom";
import Navbar from "../../common/Navbar";
import * as Constants from "../../../Constants/index";
import axios from 'axios';
import ResponsivePagination from "react-responsive-pagination";

const LoginReportRetailer = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [getData, setGetData] = useState([]);
  const [limit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [phoneNum, setPhoneNum] = useState('');

  const fetchData = async (page) => {
    setLoading(true);
    try {
      if (!token) {
        navigate('/');
      } else {
        const phoneFilter = phoneNum ? `&mobile=${phoneNum}` : ''; // Add phone number filter only if it's provided
        const response = await axios.get(
          `${Constants.baseUrl}reports/retailerLogin/list?sortBy=createDateTime&sortAsc=true&limit=${limit}&page=${page}${phoneFilter}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        );
        setGetData(response.data.output.results);
        setTotalPages(response.data.output.totalPages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const download = async () => {
    try {
        const phoneFilter = phoneNum ? `&mobile=${phoneNum}` : '';
      const response = await axios.get(
        `${Constants.baseUrl}reports/download/retailerLogin?${phoneFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
          responseType: 'blob', // Important: This tells Axios to expect a blob response
        }
      );

      // Create a new Blob object using the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      
      // Create a link element to initiate download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'retailer_login_report.csv'; // Set the file name for download
      document.body.appendChild(a);
      a.click(); // Trigger the download
      a.remove(); // Clean up and remove the link

      // Release the URL object
      window.URL.revokeObjectURL(url);

    } catch (error) {
      if (error.response) {
        console.error("Error Response:", error.response);
      } else if (error.request) {
        console.error("Error Request:", error.request);
      } else {
        console.error("Error Message:", error.message);
      }
    }
  }

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleSearch = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setCurrentPage(1);  // Reset to the first page for a new search
    fetchData(1);       // Fetch data starting from page 1
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {/* {loadin : null} */}
      <Navbar />
      <section className="page-content--bge55">
        <div className="container_fluid">
          <div className="row">
            <div className="col-md-12">
              <h3 className="head1">Login Report Retailer</h3>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSearch}>
                <div className="row">
                  <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <div className="row">
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <input
                            className="form-control reset"
                            type="text"
                            placeholder="Mobile"
                            id="mobile"
                            value={phoneNum} // Controlled input
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(/\D/g, "").slice(0, 10); // Keep only numbers, max 10 digits
                              setPhoneNum(numericValue); // Directly update the state with the numeric value
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 flex">
                    <div className="d-flex flex-row justify-content-between" >
                        <button
                        type="submit"
                        className="btn btn-primary report-submit-button"
                        style={{ marginRight: "10px" }}
                        >
                        Search
                        </button>
                        <button
                        className="btn btn-primary report-submit-button"
                        onClick={download}
                        >
                        Download
                        </button>
                    </div>
                    </div>

                  {/* <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">

                  </div> */}
                </div>
              </form>
              <div className="row mt-20">
                <div className="col-md-12">
                  <div className="table-responsive table--no-card">
                    <table className="table table-borderless table-striped table-earning">
                      <thead>
                        <tr>
                          <th>User Type</th>
                          <th>User UniqueId</th>
                          <th>Merchant Id</th>
                          <th>User Name</th>
                          <th>User Mobile</th>
                          <th>Registration Date</th>
                          <th>User Zone</th>
                          <th>User Branch</th>
                          <th>Last Login Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getData.map((val, index) => (
                          <tr key={index}>
                            <td>{val[0]}</td>
                            <td>{val[1]}</td>
                            <td>{val[2]}</td>
                            <td>{val[3]}</td>
                            <td>{val[4]}</td>
                            <td>{val[5]}</td>
                            <td>{val[6]}</td>
                            <td>{val[7]}</td>
                            <td>{val[8]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <ResponsivePagination
                    current={currentPage}
                    total={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginReportRetailer;
