import React, { useState, useEffect } from "react";
import Navbar from "../../../common/Navbar";
import { Link } from "react-router-dom";
import Oval from "../../loader/CircleLoade";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";

export default function InfluencerDashboard() {
  const [loading, setLoading] = useState(false);
  const userType = localStorage.getItem("user_type");

  const [programData, setProgramData] = useState({});

  useEffect(() => {
    programChildReq();
  }, []);

  const programChildReq = async () => {
    setLoading(true);
    let token = localStorage.getItem("token");

    try {
      await axios
        .get(Constants.baseUrl + `program/child/1`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              setLoading(false);
              setProgramData(res.data.output);
            } else if (res.data.code === 12002) {
              setLoading(false);
            } else {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="head1">Dashboard</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/program_performance">
                      <span>
                        <i className="fa fa-pie-chart"></i>
                      </span>
                      <p>Program Performance</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/plumber_performance">
                      <span>
                        <i className="fa fa-pie-chart"></i>
                      </span>
                      <p>Plumber Performance</p>
                    </Link>
                  </div>
                </div>
                {userType === "HO" ? (
                  ""
                ) : (
                  <div className="col-md-3">
                    <div className="dashboard-item" tabindex="0">
                      <Link to="/plumber_enrollment">
                        <span>
                          <i className="fa fa-pencil"></i>
                        </span>
                        <p>Plumber Enrollment</p>
                      </Link>
                    </div>
                  </div>
                )}
                {/* <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/pending_for_verification">
                      <span>
                        <i className="fa fa-ellipsis-h"></i>
                      </span>
                      <p>Pending Verification</p>
                    </Link>
                  </div>
                </div> */}
                {/* <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/pending_for_approval">
                      <span>
                        <i className="fa fa-check"></i>
                      </span>
                      <p>Pending For Approval</p>
                    </Link>
                  </div>
                </div> */}
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/approved_plumber">
                      <span>
                        <i className="fa fa-check"></i>
                      </span>
                      <p>Verifed Plumber</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/rejected_plumbers">
                      <span>
                        <i className="fa fa-close"></i>
                      </span>
                      <p>Rejected Plumbers</p>
                    </Link>
                  </div>
                </div>
                {userType === "HO" ? (
                  ""
                ) : (
                  <div className="col-md-3">
                    <div className="dashboard-item" tabindex="0">
                      {/* <Link to="/scheme_offer">
                        <span>
                          <i className="fa fa-tags"></i>
                        </span>
                        <p>Scheme & Offers</p>
                      </Link> */}
                      <a href={programData.whatsNew} target="_blank" className="no-underline">
                        <span>
                          <i className="fa fa-tags"></i>
                        </span>
                        <p>Scheme & Offers</p>
                      </a>
                    </div>
                  </div>
                )}
                {userType === "HO" ? (
                  ""
                ) : (
                  <div className="col-md-3">
                    <div className="dashboard-item" tabindex="0">
                      <Link to="/influencer_infodesk">
                        <span>
                          <i className="fa fa-info-circle"></i>
                        </span>
                        <p>Info Desk</p>
                      </Link>
                    </div>
                  </div>
                )}
                {userType === "HO" ? (
                  ""
                ) : (
                  <div className="col-md-3">
                    <div className="dashboard-item" tabindex="0">
                      <Link to="/influencertnc">
                        <span>
                          <i className="fa fa-list"></i>
                        </span>
                        <p>Terms & Conditions</p>
                      </Link>
                    </div>
                  </div>
                )}
                {userType === "HO" ? (
                  ""
                ) : (
                  <div className="col-md-3">
                    <div className="dashboard-item" tabindex="0">
                      <Link to="/influencerfaq">
                        <span>
                          <i className="fa fa-question"></i>
                        </span>
                        <p>FAQ's</p>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="head1">Member Reports</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/enrollment_report">
                      <span>
                        <i className="fa fa-pie-chart"></i>
                      </span>
                      <p>Enrollment Report</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/qrtrans_report">
                      <span>
                        <i class="fa fa-qrcode"></i>
                      </span>
                      <p>QR Transaction Report</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/redemption_report">
                      <span>
                        <i class="fa fa-file"></i>
                      </span>
                      <p>Redemption Report</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/login_report">
                      <span>
                        <i class="fa fa-file"></i>
                      </span>
                      <p>Login Report</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/ticket_report">
                      <span>
                        <i class="fa fa-file"></i>
                      </span>
                      <p>Ticket Report</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/sms_report">
                      <span>
                        <i class="fa fa-file"></i>
                      </span>
                      <p>SMS Report</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/hierarchy_report">
                      <span>
                        <i class="fa fa-file"></i>
                      </span>
                      <p>Hierarchy Report</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/top_performer_report">
                      <span>
                        <i class="fa fa-file"></i>
                      </span>
                      <p>Top Performer Report</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="head1">Retailer Reports</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/retailer_wise_redeemption">
                      <span>
                        <i class="fa fa-qrcode"></i>
                      </span>
                      <p>Retailer Wise Redeemption Report</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/retailer_points">
                      <span>
                        <i class="fa fa-file"></i>
                      </span>
                      <p>Retailer Points</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/verify_retailer">
                      <span>
                        <i class="fa fa-file"></i>
                      </span>
                      <p>Verify Retailer</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/retailer_transaction">
                      <span>
                        <i class="fa fa-file"></i>
                      </span>
                      <p>Retailer Transaction</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/topten_retailer">
                      <span>
                        <i class="fa fa-file"></i>
                      </span>
                      <p>Top 10 Retailer</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/topten_retailer_branch_wise">
                      <span>
                        <i class="fa fa-file"></i>
                      </span>
                      <p>Top 10 Retailer Branch Wise</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
}
