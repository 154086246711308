import React, { useEffect, useState } from "react";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../loader/CircleLoade";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ReactImageMagnify from "react-image-magnify";

import Navbar from "../../common/Navbar";

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function UploadShopImage() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [uploadShopImage, setUploadShopImage] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});
  const [retailerData, setRetailerData] = useState([]);
  const [retailerModal, setRetailerModal] = useState(false);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    getUploadShopImage(
      {
        search: "",
      },
      1
    );
  }, []);

  const searchFormik = useFormik({
    initialValues: {
      search: "",
    },
    // validate: (data) => {
    //   let errors = {};
    //   if (data.search === "") {
    //     errors.search = "Please enter name";
    //   }
    //   return errors;
    // },
    onSubmit: (data) => {
      setCurrentPage(1);
      getUploadShopImage(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getUploadShopImage = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      let url =
        Constants.baseUrl +
        `user/manage/userByparentId?sortBy=name&status=APPROVED&userType=RETAILER&sortAsc=true&limit=${limit}&page=${pageNum}`;

      if (data.search !== "" && data?.search !== undefined) {
        url =
          Constants.baseUrl +
          `user/manage/userByparentId?name=${data.search}&sortBy=name&status=APPROVED&userType=RETAILER&sortAsc=true&limit=${limit}&page=${pageNum}`;
      }

      await axios
        .get(
          url,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program2,
            },
          }
        )
        .then((res) => {
          //console.log(res);
          const dt = res.data;
          if (dt.result === "success") {
            setUploadShopImage(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setUploadShopImage(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const areAllValuesBlank = () => {
    const { startDate, endDate } = searchFormik.values;
    return !startDate && !endDate;
  };

  const getRetailerData = async (retailerId) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      let url = Constants.baseUrl + `display/retailer/${retailerId}`;

      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            // appVersion: Constants.av,
            // platform: Constants.pt,
            // company: Constants.company,
            // program: Constants.program2,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setRetailerData(dt?.output);
            setLoading(false);
            setRetailerModal(true);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setRetailerData([]);
            setLoading(false);
            setRetailerModal(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          setRetailerData([]);
          setLoading(false);
          //setRetailerModal(false);
        });
    }
  };

  const closeRetailerModal = () => {
    setRetailerModal(false);
  };

  const handleImageClick = (url) => {
    setImageSrc(url);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section class="page-content--bge55">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="head2"> Display Incentive</h3>
                </div>
                {/* <div class="col-md-3 col-md-offset-6 col-xs-6 text-right">
                  <div class="form-group">
                    <a
                      class="btn btn-primary"
                      href="javascript:void(0)"
                      id="idRetailerExportExcel"
                    >
                      Export
                    </a>
                  </div>
                </div> */}
              </div>
              <form onSubmit={searchFormik.handleSubmit}>
                <div className="row">
                  <div className="col-md-3 col-xs-12 text-right">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search Retailer"
                        id="search"
                        name="search"
                        value={searchFormik.values.search}
                        onChange={(e) => {
                          searchFormik.handleChange({
                            target: {
                              name: "search",
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                      {/* {getSearchFormErrorMessage("search")} */}
                    </div>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary report-submit-button"
                        id="btnSearch"
                        // disabled={areAllValuesBlank()}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive table--no-card">
                    <table
                      class="table table-borderless table-striped table-earning"
                      id="datatable"
                    >
                      <thead>
                        <tr>
                          <th>SNo</th>
                          <th>Retailer Code</th>
                          <th>Name</th>
                          <th>Mobile</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {uploadShopImage === null ? (
                        <tbody>
                          <tr>
                            <td
                              colSpan="6"
                              style={{ textAlign: "center", fontSize: "16px" }}
                            >
                              No record found!
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {uploadShopImage &&
                            uploadShopImage.length > 0 &&
                            uploadShopImage.map((row, index) => {
                              const serialNumber =
                                (currentPage - 1) * limit + index + 1;

                              // const invoiceDate = purchase.invoiceDate
                              //   ? moment(purchase.invoiceDate).format(
                              //       "DD-MM-YYYY"
                              //     )
                              //   : "";
                              return (
                                <>
                                  <tr key={index}>
                                    <td>{serialNumber}</td>
                                    <td>{row?.merchantId}</td>
                                    <td>{row?.name}</td>
                                    <td>{row?.mobile}</td>
                                    <td>{row?.status}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-primary report-submit-button"
                                        id="btnSearch"
                                        onClick={() => getRetailerData(row?.id)}
                                      >
                                        <i className="fa fa-eye"> View</i>
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      )}
                    </table>
                    {totalPages > 1 && (
                      <ResponsivePagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={(page) => {
                          setCurrentPage(page);
                          getUploadShopImage(filterData, page);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade">
        <Modal
          isOpen={retailerModal}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => setRetailerModal(false)}
          style={customStyles}
          contentLabel="Retailer Modal"
          shouldCloseOnOverlayClick={false}
          className="pendingModal"
        >
          <div className="modal-dialog modal-lg modal-border">
            <div className="modal-content-retailer">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={closeRetailerModal}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body table-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive table--no-card">
                      <table
                        class="table table-borderless table-striped table-earning"
                        id="datatable"
                      >
                        <thead>
                          <tr>
                            <th>S No.</th>
                            <th>Shop Image</th>
                            <th>Status</th>
                            <th>Date Of Upload</th>
                            <th>Date Of Approval/Rejection</th>
                          </tr>
                        </thead>
                        {retailerData === null ? (
                          <tbody>
                            <tr>
                              <td
                                colSpan="5"
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                No record found!
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <>
                            <tbody>
                              {retailerData &&
                                retailerData.length > 0 &&
                                retailerData.map((row, index) => {
                                  const serialNumber = index + 1;

                                  // const invoiceDate = purchase.invoiceDate
                                  //   ? moment(purchase.invoiceDate).format(
                                  //       "DD-MM-YYYY"
                                  //     )
                                  //   : "";
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>{serialNumber}</td>
                                        <td>
                                          {row?.url && (
                                            <div
                                              onClick={() =>
                                                handleImageClick(row.url)
                                              }
                                            >
                                              <img
                                                src={row.url}
                                                alt="Shop Image"
                                                height={"50px"}
                                              />
                                            </div>
                                          )}
                                        </td>
                                        <td>{row?.status}</td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <Modal
        isOpen={isImageModalOpen}
        onRequestClose={() => setIsImageModalOpen(false)}
      >
        <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={closeImageModal}
                >
                 X
                </button>
              </div>
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: "Shop Image",
              isFluidWidth: true,
              src: imageSrc,
            },
            largeImage: {
              src: imageSrc,
              width: 600,
              height: 900,
            },
            lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
            isHintEnabled: true,
            shouldHideHintAfterFirstActivation: false,
            enlargedImagePosition: "over",
            hoverDelayInMs: 300,
          }}
        />
      </Modal>
    </>
  );
}
