import React, { useState } from 'react'
import Navbar from '../../common/Navbar'
import { Link } from 'react-router-dom';
export default function FSNPSReport() {
    const [activeTab, setActiveTab] = useState('1a');
    return (
        <>
            <Navbar />
            <section class="page-content--bge55">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tabbable-panel margin-tops4 ">
                                <div class="tabbable-line bggg33">
                                    <ul class="nav nav-tabs tabtop  tabsetting">
                                        <li className={activeTab === '1a' ? 'active' : ''}> <Link to="" onClick={() => setActiveTab('1a')} data-toggle="tab" aria-expanded={activeTab === '1a'}> NPS Dashboard </Link> </li>
                                        <li className={activeTab === '2a' ? 'active' : ''}> <Link to="" onClick={() => setActiveTab('2a')} data-toggle="tab" aria-expanded={activeTab === '2a'}> Sintex NPS Calculation </Link> </li>
                                        <li className={activeTab === '3a' ? 'active' : ''}> <Link to="" onClick={() => setActiveTab('3a')} data-toggle="tab" aria-expanded={activeTab === '3a'}> Weightage Report</Link> </li>
                                    </ul>
                                    <div class="card card-body mtop15">
                                        <div class="tab-content">
                                            <div className={`tab-pane ${activeTab === '1a' ? 'active' : ''}`} id="1a">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label>Select Branch</label>
                                                            <select class="form-control reset1 Branch">
                                                                <option selected="selected" value="">Select Branch (All India)</option>
                                                                <option value="1">PL-AHMEDABAD</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 hidden-xs">
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group text-right">
                                                            <label style={{ visibility: 'hidden', width: '100%', height: '22px' }}></label>
                                                            <label class="btn btn-default">All India Count= 0/0</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="card1">
                                                            <div class="header1">
                                                                <h4 style={{ textAlign: 'center' }}>NPS</h4>
                                                            </div>
                                                            <div class="body">
                                                                <div id="chartdivnps"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="card1">
                                                            <div class="header1">
                                                                <h4 style={{ textAlign: 'center' }}>NPS</h4>
                                                            </div>
                                                            <div class="body">
                                                                <div id="chartdivnpspie"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="card1">
                                                            <div class="header1">
                                                                <div class="row">
                                                                    <div class="col-md-12 col-xs-6">
                                                                        <h4>NPS SCORE - NUMBER OF RESPONDERS</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="body">
                                                                <div class="track">
                                                                    <div class="step active">
                                                                        <span class="count1">0</span>
                                                                        <span class="icon"></span>
                                                                        <span class="text">10</span>
                                                                    </div>
                                                                    <div class="step active">
                                                                        <span class="count1">0</span>
                                                                        <span class="icon"> </span>
                                                                        <span class="text"> 9</span>
                                                                    </div>
                                                                    <div class="step active">
                                                                        <span class="count1">0</span>
                                                                        <span class="icon"> </span>
                                                                        <span class="text"> 8</span>
                                                                    </div>
                                                                    <div class="step active">
                                                                        <span class="count1">0</span>
                                                                        <span class="icon">  </span>
                                                                        <span class="text"> 7</span>
                                                                    </div>
                                                                    <div class="step active">
                                                                        <span class="count1">0</span>
                                                                        <span class="icon">  </span>
                                                                        <span class="text"> 6</span>
                                                                    </div>
                                                                    <div class="step active">
                                                                        <span class="count1">0</span>
                                                                        <span class="icon">  </span>
                                                                        <span class="text"> 5</span>
                                                                    </div>
                                                                    <div class="step active">
                                                                        <span class="count1">0</span>
                                                                        <span class="icon"> </span>
                                                                        <span class="text">4</span>
                                                                    </div>
                                                                    <div class="step active">
                                                                        <span class="count1">0</span>
                                                                        <span class="icon">  </span>
                                                                        <span class="text"> 3</span>
                                                                    </div>
                                                                    <div class="step active">
                                                                        <span class="count1">0</span>
                                                                        <span class="icon">  </span>
                                                                        <span class="text">2</span>
                                                                    </div>
                                                                    <div class="step active">
                                                                        <span class="count1">0</span>
                                                                        <span class="icon"> </span>
                                                                        <span class="text"> 1</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`tab-pane ${activeTab === '2a' ? 'active' : ''}`} id="2a">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Select Branch</label>
                                                            <select class="form-control reset1 Branch">
                                                                <option selected="selected" value="">Select Branch (All India)</option>
                                                                <option value="27">PL-GOA</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 col-xs-6">
                                                        <div class="form-group">
                                                            <label style={{ visibility: 'hidden' }}>hidden</label>
                                                            <a class="btn btn-primary" href="#" id="">
                                                                Export
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive table--no-card">
                                                            <table class="table table-borderless table-striped table-earning" id="">
                                                                <thead>
                                                                    <tr>
                                                                        <th> Customer Code </th>
                                                                        <th> Retailer FirmName </th>
                                                                        <th> Branch </th>
                                                                        <th> Zone </th>
                                                                        <th> NPS Survey Result </th>
                                                                        <th>Survey EntryDate</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`tab-pane ${activeTab === '3a' ? 'active' : ''}`} id="3a">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label>Select Branch</label>
                                                            <select class="form-control reset1  Branch" id="BranchId1" name="BranchId1">
                                                                <option selected="selected" value="">Select Branch (All India)</option>

                                                                <option value="21">PL-LUCKNOW</option>
                                                                <option value="22">PL-KOLKATA</option>
                                                                <option value="23">PL-CHANDIGARH</option>
                                                                <option value="24">PL-BENGALURU</option>
                                                                <option value="25">PL-GUWAHATI</option>
                                                                <option value="26">PL-HUBLI</option>
                                                                <option value="27">PL-GOA</option>
                                                            </select>


                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 col-xs-6">
                                                        <div class="form-group">
                                                            <label style={{ visibility: 'hidden' }}>hidden</label>
                                                            <a class="btn btn-primary" href="#" id="">
                                                                Export
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive table--no-card">
                                                            <table class="table table-borderless table-striped table-earning">
                                                                <thead>
                                                                    <tr>
                                                                        <th> Customer Code </th>
                                                                        <th> Retailer FirmName </th>
                                                                        <th> Branch </th>
                                                                        <th> Zone </th>
                                                                        <th> NPS Score </th>
                                                                        <th> Status </th>
                                                                        <th>Survey EntryDate</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="tab-pane fade" id="tab_default_4">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label>Select Branch</label>
                                                            <select class="form-control reset1  Branch">
                                                                <option selected="selected" value="">Select Branch (All India)</option>
                                                                <option value="1">PL-AHMEDABAD</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 col-xs-6">
                                                        <div class="form-group">
                                                            <label style={{ visibility: 'hidden' }}>hidden</label>
                                                            <a class="btn btn-primary" href="#" id="">
                                                                Export
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive table--no-card">
                                                            <table class="table table-borderless table-striped table-earning">
                                                                <thead>
                                                                    <tr>
                                                                        <th> Customer Code </th>
                                                                        <th> Retailer FirmName </th>
                                                                        <th> Branch </th>
                                                                        <th> Zone </th>
                                                                        <th> SNo </th>
                                                                        <th> Question </th>
                                                                        <th> Scale </th>
                                                                        <th> Weigtage </th>
                                                                        <th>Score given </th>
                                                                        <th> Net score </th>
                                                                        <th>Survey EntryDate</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
