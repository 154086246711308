import React from 'react'
import Navbar from '../../common/Navbar'
import { Link } from 'react-router-dom'
export default function SignageBoardStatus() {
    return (
        <>
            <Navbar />
            <section class="page-content--bge55">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <strong class="card-title">Existing Retailers</strong>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3 col-xs-6 text-right">
                                            <div class="form-group">
                                                <input class="form-control" type="text" placeholder="Search Retailers" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="table-responsive table--no-card">
                                                <table class="table table-borderless table-striped table-earning">
                                                    <thead>
                                                        <tr>
                                                            <th> Retailer Id </th>
                                                            <th> Mobile No </th>
                                                            <th> Name </th>
                                                            <th> Firm Name </th>
                                                            <th> State </th>
                                                            <th> City </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>11</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mtop15">
                                        <div class="col-md-2 col-xs-6 text-left">
                                            <div class="form-group">
                                                <Link class="btn btn-primary" to="#">
                                                    Previous
                                                </Link>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-md-offset-8 col-xs-6 text-right">
                                            <div class="form-group">
                                                <Link class="btn btn-primary" to="#">
                                                    Next
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
