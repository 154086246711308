import React from 'react'
import Navbar from '../../common/Navbar'

export default function AddPlumber() {
    return (
        <>
            <Navbar />
            <section class="page-content--bge55">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <strong class="card-title">Verify Plumber</strong>
                                </div>
                                <div class="card-body">
                                    <label class="control-label">Documents size limit is 1 MB.</label>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>First Name*</label>
                                                <input class="form-control" placeholder="Enter First Name" type="text" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Last Name</label>
                                                <input class="form-control" placeholder="Enter Last Name" type="text" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Mobile Number*</label>
                                                <input class="form-control" placeholder="Enter Mobile Number*" type="text" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Alternate Number</label>
                                                <input class="form-control" placeholder="Enter Alternate Number" type="text" />
                                            </div>
                                        </div>


                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>DOB</label>
                                                <input class="form-control" placeholder="mm/dd/yyyy" type="text" />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input class="form-control" placeholder="Enter Email" type="text" />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Address 1*</label>
                                                <input class="form-control" placeholder="Enter Address 1*" type="text" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Address 2</label>
                                                <input class="form-control" placeholder="Enter Address 2" type="text" />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>State*</label>
                                                <select class="form-control">
                                                    <option value="0" selected>Select State</option>
                                                    <option value="1"></option>
                                                    <option value="2"></option>
                                                    <option value="3"></option>
                                                    <option value="4"></option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>City*</label>
                                                <select class="form-control">
                                                    <option value="0" selected>Select City*</option>
                                                    <option value="1"></option>
                                                    <option value="2"></option>
                                                    <option value="3"></option>
                                                    <option value="4"></option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Pincode*</label>
                                                <input class="form-control" placeholder="Pincode*" />
                                            </div>
                                        </div>



                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Select District*</label>
                                                <input class="form-control" placeholder="Enter  District*" type="text" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Aadhar No*</label>
                                                <div class="well">
                                                    <input class="form-control" placeholder="Enter  Aadhar No.*" type="text" />
                                                    <br />
                                                    <input type="file" class="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group ">
                                                <label>Pan Card*</label>
                                                <div class="well">
                                                    <input class="form-control" placeholder="Enter  Pan Card No.*" type="text" />
                                                    <br />
                                                    <input type="file" class="form-control" />
                                                </div>
                                            </div>
                                        </div>



                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Bank Name*</label>
                                                <input class="form-control" placeholder="Enter  Bank Name*" type="text" />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Bank A/c No*</label>
                                                <input class="form-control" placeholder="Enter Bank A/C No.*" type="text" />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>IFSC Code</label>
                                                <input class="form-control" placeholder="Enter IFSC Code*" type="text" />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Cancelled Cheque Pic*</label>
                                                <input class="form-control" type="file" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>Choose Any One</label>
                                                <div class="well">
                                                    <select class="form-control">
                                                        <option value="" selected>Select Any One</option>
                                                    </select>
                                                    <br />
                                                    <input type="file" class="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <input type="text" class="btn btn-primary btn-green" value="Submit" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
