import React, { useState, useEffect } from 'react'
import Navbar from '../../../common/Navbar'
import { Link } from 'react-router-dom';
import * as Constants from '../../../../Constants/index'
import axios from 'axios'
import Oval from '../../loader/CircleLoade';
import { useNavigate } from 'react-router-dom';
import ResponsivePagination from 'react-responsive-pagination';
import Modal from 'react-modal';
import aadharfront from '../../../../assets/img/aadharfront.jpeg'
import aadharback from '../../../../assets/img/aadharback.png'
import gst from '../../../../assets/img/gst.png'
import pan from '../../../../assets/img/pan.jpeg'
import swal from 'sweetalert';
import moment from 'moment';
const customStyles = {
    content: {
        top: '45%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
};
export default function PendingApproval() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [searchUser, setSearchUser] = useState('')
    const [limit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [results, setResults] = useState([])
    const [totalPages, setTotalPages] = useState()
    const [searchRecord, setSearchRecord] = useState()
    const [isVisible, setIsVisible] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [status, setStatus] = useState('')
    const [reason, setReason] = useState('')
    const [remarks, setRemarks] = useState('')
    const [viewDetails, setViewDetails] = useState(false)
    const [plumberId, setPlumberId] = useState()
    const [regData, setRegData] = useState({})


    useEffect(() => {

        if (currentPage !== 1) {
            pendingApprovalReq(searchUser);
        } else {
            pendingApprovalReq('');
        }

    }, [currentPage]);
    const pendingApprovalReq = async (data) => {
        let token = localStorage.getItem('token');
        setLoading(true)
        if (!token) {
            navigate('/')
        } else {
            const searchUserVal = data === '' ? '' : `name=${data}&`

            try {
                await axios.get(Constants.baseUrl + `user/manage/userBycityId?${searchUserVal}userType=MEMBER&status=NEW&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        // company: Constants.company,
                        program: Constants.program,
                    }
                }).then((res) => {
                    if (res.status === 201) {
                        if (res.data.code === 10001) {
                            setViewDetails(false)
                            setResults(res.data.output.results)
                            setTotalPages(res.data.output.totalPages)
                            setLoading(false)
                        } else if (res.data.code === 12002) {
                            setViewDetails(false)
                            setLoading(false);
                            setSearchRecord(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                        } else {
                            swal({
                                text: res.data.msg,
                                // icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }
    // update registration by approved or reject
    const updateRegistration = async (id) => {
        let token = localStorage.getItem('token');
        if (status === 'REJECT' && reason === '') {
            swal({
                // text: 'Please select a reason',
                // icon: 'error',
                timer: 2000,
                buttons: false,
            });
            return; // Return early if reason is not selected when status is 'reject'
        }

        if (reason === 'OTHER' && remarks === '') {
            swal({
                text: 'Please enter remarks',
                // icon: 'error',
                timer: 2000,
                buttons: false,
            });
            return; // Return early if reason is 'other' but remarks are not entered
        }
        try {
            await axios.post(Constants.baseUrl + `user/manage/updatestatus/${id}`, {
                status: status,
                reason: reason,
                remarks: remarks
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            setModalShow(false)
                            swal({
                                text: res.data.output.status,
                                icon: 'success',
                                timer: 2000,
                                buttons: false,
                            });
                            setStatus('')
                            setReason('')
                            setRemarks('')
                            setTimeout(() => {
                                window.location.reload()
                            }, 2000)
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            });
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        });
                    }
                });
        } catch (error) {
            console.log(error)
        }
    }

    // view registraion form
    const handleViewReg = async (id) => {
        setLoading(true)
        let token = localStorage.getItem('token');
        try {
            await axios.get(Constants.baseUrl + `user/manage/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            const data = res.data.output;
                            setRegData(data);
                            setLoading(false)
                            setViewDetails(true)
                            window.scrollTo({
                                top: 600,
                                behavior: 'smooth',
                            });

                        } else if (res.data.code === 12001) {
                            swal({
                                text: res.data.msg,
                                // icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                });
        } catch (error) {
            console.log(error)
        }
    }


    const handleSearch = (event) => {
        setSearchUser(event.target.value)
        if (event.target.value === '') {
            pendingApprovalReq('')
            setSearchRecord()
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            pendingApprovalReq(searchUser);
        }
    };
    const closeModal = () => {
        setModalShow(false)
        setStatus('')
    }

    const handleSelection = (event) => {
        setStatus(event.target.value)
        setReason('')
        setRemarks('');
    }
    return (
        <>
            {loading ? <Oval /> : null}
            <Navbar />
            <section className="page-content--bge55">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="head1">Pending For Approval </h3>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group">
                                        <input className="form-control reset" type="text" placeholder="Name/Mobile No." value={searchUser} onChange={handleSearch} onKeyDown={handleKeyDown} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <button type="button" className="btn btn-primary btn-search" disabled={searchUser.trim() === ''}
                                            onClick={() => pendingApprovalReq(searchUser)}>Search</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-20">
                                <div className="col-md-12">
                                    <div className="table-responsive table--no-card">
                                        <table className="table table-borderless table-striped table-earning">
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>View Details</th>
                                                    <th>Approval</th>
                                                    <th>User ID </th>
                                                    <th>User Name </th>
                                                    <th>City </th>
                                                    <th>Date Of Reg.</th>
                                                </tr>
                                            </thead>
                                            {searchRecord === null ?
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="7" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                    </tr>
                                                </tbody> :
                                                <tbody>
                                                    {results && results.map((item, index) => {
                                                        const serialNumber = (currentPage - 1) * limit + index + 1;
                                                        const dateOfReg = moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');

                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{serialNumber}</td>
                                                                    <td><Link to="#" className="viewlink" onClick={() => handleViewReg(item.id)}>View Details</Link></td>
                                                                    <td><Link to="#" className="selectlink" onClick={() => {
                                                                        setPlumberId(item.id)
                                                                        setModalShow(true)
                                                                    }}
                                                                    >Select</Link></td>
                                                                    <td>{item.mobile}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.addressWork?.workCity}</td>
                                                                    <td>{dateOfReg}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>}
                                        </table>
                                        {totalPages > 1 && (
                                            <ResponsivePagination
                                                current={currentPage}
                                                total={totalPages}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {viewDetails === true ?
                                <div className="row mt-20 enrollmentform">
                                    <div className="col-md-12">
                                        <h4 className="headdetails">
                                            <strong className="card-title">Basic Details</strong>
                                        </h4>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>First Name*</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.name} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Middle Name</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.midleName} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.lastName} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Mobile Number*</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.mobile} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Email ID</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.email} />
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Address 1</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.addressWork?.workAddress1} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Address 2</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.addressWork?.workAddress2} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Address 3</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.addressWork?.workAddress3} />
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>State*</label>
                                            <input className="form-control" disabled="disabled" value={regData.addressWork?.workState} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>District*</label>
                                            <input className="form-control" disabled="disabled" value={regData.addressWork?.workdistrict} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>City*</label>
                                            <input className="form-control" disabled="disabled" value={regData.addressWork?.workCity} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Pincode*</label>
                                            <input className="form-control" disabled="disabled" value={regData.addressWork?.workPincode} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label><strong>Selected Profile</strong></label>
                                            <div className="profile">
                                                {regData.plumberProfile === 'plumber' ?
                                                    <label className="radio-inline">
                                                        <input type="radio" name="profile" checked={regData.plumberProfile === 'plumber'} /> Plumber
                                                    </label> :
                                                    <label className="radio-inline">
                                                        <input type="radio" name="profile" checked={regData.plumberProfile === 'contractor'} /> Plumbing Contractor
                                                    </label>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <h4 className="headdetails">
                                            <strong className="card-title">KYC Details</strong>
                                        </h4>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label>Mobile No. Linked with Aadhar</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.userModelKYC?.aadharMobile} />

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label> Aadhar No.</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.userModelKYC?.aadharNumber} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label> Aadhar Front Image</label>
                                            <Link to={regData.userModelKYC?.aadharFrontUrl} target="_blank">View <img src={aadharfront} className="docimage" alt="" /></Link>

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label> Aadhar Back Image</label>
                                            <Link to={regData.userModelKYC?.aadharBackUrl} target="_blank">View <img src={aadharback} className="docimage" alt="" /></Link>

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group ">
                                            <label>Pan No.</label>
                                            <input className="form-control" disabled="disabled" type="text" value={regData.userModelKYC?.panNumber} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label> Pan Image</label>
                                            <Link to={regData.userModelKYC?.panFrontUrl} target="_blank">View <img src={pan} className="docimage" alt="" /></Link>
                                        </div>
                                    </div>
                                    {regData.plumberProfile === 'contractor' ?
                                        <div className="col-lg-3 col-md-3 col-xs-12">
                                            <div className="form-group ">
                                                <label>GST No.</label>
                                                <input className="form-control" disabled="disabled" type="text" value={regData.gst} />
                                            </div>
                                        </div> : ''}
                                    {regData.plumberProfile === 'contractor' ?
                                        <div className="col-lg-3 col-md-3 col-xs-12">
                                            <div className="form-group">
                                                <label> GST Image</label>
                                                <Link to={regData.gstImage} target="_blank"> View <img src={gst} className="docimage" alt="" /></Link>
                                            </div>
                                        </div> : ''}
                                </div> : ''}
                        </div>
                    </div>
                </div>
            </section>
            <div className="modal fade modal-blue">
                <Modal
                    isOpen={modalShow}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={() => setModalShow(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                    shouldCloseOnOverlayClick={false}
                    className='pendingModal'
                >

                    <div className="modal-dialog modal-sm modal-border">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" onClick={closeModal} >&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-details">
                                    <div className="row">
                                        <div className="form-group col-lg-12  col-md-12">
                                            <label>Select Status<span style={{ color: 'red' }}></span></label>
                                            <select className="form-control" id="status" value={status} onChange={handleSelection}>
                                                <option value="">Select Status</option>
                                                <option value="APPROVED">Approve</option>
                                                <option value="REJECT">Reject</option>
                                            </select>
                                        </div>
                                        {status === 'REJECT' ?
                                            <div className="form-group col-lg-12 col-md-12 reason">
                                                <label>Select Reason<span style={{ color: 'red' }}>*</span></label>
                                                <select className="form-control" id="reason" value={reason} onChange={(event) => setReason(event.target.value)}>
                                                    <option value="">Select Reason</option>
                                                    <option value="sas">KYC Incomplete</option>
                                                    <option value="OTHER">Other</option>
                                                </select>
                                            </div> : ''}
                                        {reason === 'OTHER' && status === 'REJECT' ?
                                            <div className="form-group col-lg-12  col-md-12 remarkreason">
                                                <label>Enter rejection remark here<span style={{ color: 'red' }} >*</span></label>
                                                <textarea className="form-control txtreason" cols="50" rows="4" placeholder="Enter Reason" value={remarks} onChange={(e) => setRemarks(e.target.value)}></textarea>
                                            </div> : ''}


                                        <div className="form-group col-lg-12 col-md-12 text-center">
                                            {status === 'APPROVED' ?
                                                <input type="submit" className="btn btn-primary btn-sbmit btn-search" value="Approve" onClick={() => updateRegistration(plumberId)} /> : ''}
                                            {status === 'REJECT' ?
                                                <input type="submit" className="btn btn-reject btn-search" value="Reject" onClick={() => updateRegistration(plumberId)} /> : ''}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}
