import React from 'react'
import { Oval } from 'react-loader-spinner'
import './loaderStyle.css';


const CircleLoade = () => {
    return (
        <div className="loader">
            <Oval
                height={100}
                width={100}
                color="#6cc"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#6cc"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </div>
    )
}

export default CircleLoade