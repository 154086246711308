import React, { useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../../loader/CircleLoade";
import Navbar from "../../../common/Navbar";
export default function FeedbackSupport() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [issueType, setIssueType] = useState([]);
  const [pointsData, setPointsData] = useState([]);
  const [monthName, setMonthName] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getIssueType();
  }, []);

  const getIssueType = async () => {
    if (!token) {
      navigate("/");
    } else {
      await axios
        .get(
          `${Constants.baseUrl}program/issueType?sortBy=name&sortAsc=true&limit=100&page=1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program2,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setIssueType(dt?.output?.results);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setIssueType([]);
          }
        })
        .catch((err) => {});
    }
  };

  const formik = useFormik({
    initialValues: {
      issue_type: "",
      query: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.issue_type === "") {
        errors.issue_type = "Please select issue type";
      }
      if (data.query === "") {
        errors.query = "Please enter query";
      }
      return errors;
    },
    onSubmit: (data) => {
			handleQuerySubmit(data);
		},
  });
  const isSearchFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">{formik.errors[name]}</small>
      )
    );
  };

  const handleQuerySubmit = async (data) => {
    let token = localStorage.getItem("token");
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      const payload = {
        type: data.issue_type,
				description: data.query,
      };

      try {
        await axios
          .post(
            `${Constants.baseUrl}program/child/ticket`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                company: Constants.company,
                program: Constants.program,
              },
            }
          )
          .then((res) => {
            setLoading(false);
            if (res.data.code === 10001) {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });

              navigate("/retailer_Dashboard");
            } else if (res.data.code === 12001) {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            } else {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const areAllValuesBlank = () => {
    const { month_year } = formik.values;
    return !month_year;
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="card minh11">
                <div className="card-header">
                  <h4 className="text-blue1">Support</h4>
                </div>
                <div className="card-body support">
                  <p>
                    <Link to="mailto:info@sintexhamesha.com" target="_top">
                      <i className="fa fa-envelope m-r-5"></i>
                      info@sintexhamesha.com
                    </Link>{" "} / 1800-121-2764
                  </p>
                  <p>Monday to Friday ( Between 9:30 am to 5:30 pm )</p>
                  <p>Sintex Retailer Loyalty Program Center </p>
                  <p>
                    Phone <i className="fa fa-phone m-r-5"></i>1800-121-2764 |
                    Website <i className="fa fa-globe m-r-5"></i>
                    <Link to="www.sintexhamesha.com">
                      www.sintexhamesha.com
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="col-md-6 col-xs-12">
                <div className="card minh11">
                  <div className="card-header">
                    <h4 className="text-blue1">Send us your query</h4>
                  </div>
                  <div className="card-body support">
                    <div className="form-group row">
                      <label className="col-md-3">Type</label>
                      <div className="col-md-9">
                        <select
                          className="form-control"
                          id="issue_type"
                          name="issue_type"
                          value={formik.values.issue_type}
                          onChange={formik.handleChange}
                        >
                          <option value="">Select any one</option>
                          {issueType.length > 0 &&
                            issueType.map((row, index) => (
                              <option key={index} value={row?.name}>
                                {row?.name}
                              </option>
                            ))}
                          {/* <option value="Product Enquiry">Product Enquiry</option>
													<option value="Program Enquiry">Program Enquiry</option>
													<option value="Change of Contact Details">
														Change of Contact Details
													</option>
													<option value="Point Balance Enquiry">
														Point Balance Enquiry
													</option>
													<option value="Program Rating">Program Rating</option>
													<option value="Order Related Query">
														Order Related Query
													</option>
													<option value="Any Other Query">Any Other Query</option> */}
                        </select>
                        {getSearchFormErrorMessage("issue_type")}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-md-3">Query</label>
                      <div className="col-md-9">
                        <textarea
                          className="form-control mb-4"
                          placeholder="Enter your query"
                          rows="6"
                          id="query"
                          name="query"
                          value={formik.values.query}
                          onChange={formik.handleChange}
                        ></textarea>
                        {getSearchFormErrorMessage("query")}
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-footer text-right"
                    style={{ background: "#fff" }}
                  >
                    <button type="submit" className="btn btn-primary sbutton">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
