import React from "react";
import Navbar from "../../common/Navbar";
import { Link } from "react-router-dom";
export default function ContactUs() {
  return (
    <>
      <Navbar />
      <section className="">
        <div className="container-fluid">
          <div className="inner-sec">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="cntct">
                  <p>
                    <Link to="mailto:info@sintexhamesha.com" target="_top">
                      <i className="fa fa-envelope m-r-5"></i>
                      info@sintexhamesha.com
                    </Link>{" "}
                    / 1800-121-2764
                  </p>
                  <p>Monday to Friday ( Between 9:30 am to 5:30 pm )</p>
                  <p>Sintex Retailer Loyalty Program Center </p>
                  <p>
                    Phone <i className="fa fa-phone m-r-5"></i>1800-121-2764 |
                    Website <i className="fa fa-globe m-r-5"></i>
                    <Link to="www.sintexhamesha.com">
                      www.sintexhamesha.com
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </section>
    </>
  );
}
