import React from 'react'

export default function English() {
    return (
        <>
            <section className="page-content--bge55">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card" style={{ padding: '14px' }}>
                                <ol type='1'>
                                    <li>This “Sintex Pride” Plumber Loyalty Program belongs to Sintex BAPL Limited, a part of the Welspun World, for its Water Storage Tanks business.</li>
                                    <li>This program will be effective from <strong>01 January 2024.</strong></li>
                                    <li>The program is open for enrolment for Plumbers/Plumbing Contractors who buy directly from an authorised Retailer or Distributor of Sintex Water Storage Tanks.</li>
                                    <li>Individuals below the age of 18 years cannot participate in this program. We reserve the right to cancel your registration if you are found to be a minor.</li>
                                    <li>To qualify for rewards accrual and redemption, the plumber/plumbing contractor must be registered and verified in our system.</li>
                                    <li>Enrolling for this Program is mandatory and will be deemed as acceptance of these terms and conditions</li>
                                    <li>This Plumber Loyalty Program is only applicable for sales of water storage tanks up to 5,000 liters. Please contact your Sintex representative for a detailed clarification.</li>
                                    <li>Only purchases made from authorised retailers and distributors would be considered for benefits under this program.</li>
                                    <li>Any related person or employee of a retailer / distributor cannot be part of this scheme. The assessment of Sintex Management in this regard will be final and binding to all channel partners.</li>
                                    <li>CWUG (FRP tanks), HD, LIDs or any other products which are not mentioned will not be considered in the loyalty program.</li>
                                    <li>Sintex may assign targets from time to time for eligible plumbers to qualify and earn rewards.</li>
                                    <li>Misrepresentation and wrong information will result in permanent disqualification, from the program and schemes run by Sintex.</li>
                                    <li>The company shall deduct TDS on the equivalent value of all rewards in accordance with Section 194R of the Income Tax Act 1961. Plumber is responsible to provide Permanent Account Number (PAN) details.</li>
                                    <li>Sintex reserves the right to disqualify any Plumber from the program and any other Company or agency run program, without assigning any reason / intimation / justification thereof.</li>
                                    <li>Sintex reserves the right to revoke the benefits under this Program in the event of the nonproduction of any of the necessary documents and/or submission of the false, incorrect, misleading, or fraudulent document(s)/ information furnished by enrolling/registered members.</li>
                                    <li>Sintex reserves the right for the computation of the reward that shall be final, conclusive and binding on the Plumber and will not be liable to be disputed or challenged.</li>
                                    <li>Benefits under the program are non-transferable.</li>
                                    <li>There is no upper limit on the accumulation of the reward points and the accumulation is correlated to the purchase of Sintex WST products.</li>
                                    <li>Validity of Rewards: All rewards accrued to plumber account would be valid for three years from the date of accrual.</li>
                                    <li>The Reward Points can be redeemed in full or in part. Plumber can redeem up to 80% of the accumulated reward points at any stage of the program.</li>
                                    <li>Taxes and Insurance, along with documents and other expenses, will have to be borne by the plumber.</li>
                                    <li>All issues, concerns and feedback pertaining to invoice entries, rewards earned, rewards balance or delivery of rewards should be raised to helpline (18001212764).</li>
                                    <li>All registered members irrevocably acknowledge that all the terms and conditions have been fully read and understood by them.</li>
                                    <li>Sintex holds all rights to change and amend the scheme without prior information.</li>
                                    <li>In case of any conflict, the company's decision will be final and binding. All disputes related to the same will be resolved as per the jurisdiction of Kalol Court (North Gujarat).</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
